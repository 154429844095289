import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConstantService } from '../../constant/constant.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public data: any = null
  constructor(
    private http: HttpClient,
    private constantService: ConstantService,
  ) {
  }

  _getUserData() {
    return this.data;
  }

  getUser() {
    return this.http.get(this.constantService.getUrl(this.constantService.USER));
  }

  getLoggedInUserProfile() {
    return this.http.get(this.constantService.getUrl(`${this.constantService.USER}/me`));
  }

  getRelevantUsers(params?: any) {
    return this.http.get(this.constantService.getUrl(this.constantService.USER + `/allUsers`), {
      params: params,
      observe: 'response'
    }).map((resp: any) => {
      let count = resp.headers.get('X-Total-Count');
      return { count: parseInt(count), data: resp.body.data };
    });
  }

  getById(id) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.USER}/id/${id}`), {})
  }

  userDataTransfer(body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.USER}/userDataTransfer`), body)
  }

  reAssignLeads(body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.USER}/reassignLeads`), body)
  }

  reAssignEnquiry(body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.USER}/reassignEnquiry`), body)
  }

  downloadExcel(params?: any): Observable<HttpResponse<Blob>> {
    return this.http.get(this.constantService.getUrl(this.constantService.USERS + '/download/excel'), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    })
  }
}
