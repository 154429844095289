import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'getNumeral'
})
export class getNumeralPipe implements PipeTransform {

    transform(number: any): any {
        if (isNaN(number)) return 0;
        if (!number) return number;
        let digit = number % 10;

        switch(digit){
            case 1:
                return number + "st";
            case 2:
                return number + "nd";
            case 3:
                return number + "rd";
            default:
                return number + "th";
            
        }
    }

}
