import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'listFilter'
})
export class ListFilterPipe implements PipeTransform {

  transform(list: any[], filter={}): any {
    if (!list) return false;
    let keys = Object.keys(filter);
    return list.filter( o =>{
      let valid = true;
      for (let key of keys) {
        valid = valid && filter[key] == o[key];
      }
      return valid;
    });
  }

}
