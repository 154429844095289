import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/map';

import {ConstantService} from '../../constant/constant.service';

/**
 * Description of Notification Service
 *
 * @author Hirpara Ravi
 */

@Injectable({
  providedIn: 'root'
})   
export class NotificationService {

  constructor(
    private constantService: ConstantService,
    private http: HttpClient
  ) {
  }

  private _items: any = {};
  
  setItem(item: any) {
    this._items = item;
  }

  getItems() {
    return this._items;
  }
    
  get(params){
    return this.http.get(this.constantService.getUrl(this.constantService.NOTIFICATION), {
      params: params
    });
  }

  schedule() {
    let params : any = { type : 5 };
    return this.http.get(this.constantService.getUrl(`${this.constantService.NOTIFICATION}/schedule`), {
      params: params
    });
  }

  getNotifications(params?: any) {
    return this.http.get(this.constantService.getUrl(this.constantService.NOTIFICATION));
  }
  
  getById(id: string) {
    return this.http.get(this.constantService.getUrl(this.constantService.NOTIFICATION, [id]));
  }

  post(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.NOTIFICATION), body);
  }

  put(id: string, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.NOTIFICATION, [id]), body);
  }

  deleteNotification(id: number) {
    return this.http.delete(this.constantService.getUrl(`${this.constantService.NOTIFICATION}/${id}`));
  }

}