import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { updateIframeWithHTML } from '../../helper/common';

@Component({
  selector: 'app-rte',
  templateUrl: './rte.component.html',
  styleUrls: ['./rte.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RTEComponent),
      multi: true
    }
  ]
})
export class RTEComponent implements OnInit, ControlValueAccessor {

  @Input() lable: any = "Content";
  @Input() placeholder: any;
  @Input() defaultStyleClass: any = 'mb-4';
  
  public _inputvalue: any = "";
  public disabled = false;
  public toggleRTESwitch = true;
  
  value: any = "";
  
  constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  retCommand(command,value = null){
    let iframe : any = document.getElementById("cotentIframe");
    iframe.contentDocument.execCommand(command, false, value);    
  }

  toggleRTE(){
    let oThat = this;
    if(this.toggleRTESwitch){
      let iframe : any = document.getElementById("cotentIframe");
      let content = iframe.contentDocument.body.innerHTML;
      this.value = content;
      this._inputvalue = content;
      this.onChange(this.value);
    }else{
      this.loadIFrame(oThat.value);
    }
    this.toggleRTESwitch = !this.toggleRTESwitch;
  }

  clearInput() {
    this.value = "";
    this._inputvalue = ""; 
  }

  updateConent(){
    let iframe : any = document.getElementById("cotentIframe");
    let content = iframe.contentDocument.body.innerHTML;
    this.value = content;  
    this._inputvalue = content;  
    this.onChange(this.value);
  }

  _close(close) {
    this.onChange(this.value || "");
    close();
  }

  onBlur(value: any): void {
  }

  updateCallback($event) {
    this.onChange(this._inputvalue || "");
  }

  loadIFrame(value){
    let oThat = this;
    setTimeout(function(){
      let iframe : any = document.getElementById("cotentIframe");
      iframe.contentDocument.designMode="on";
      iframe.contentWindow.document.addEventListener('keyup', () => { oThat.updateConent(); });
      updateIframeWithHTML(iframe,value);
      oThat.onChange(oThat.value);
    },200);
  }

  writeValue(value: any): void {
    this.value = value == undefined ? "" : value;
    let oThat = this;
    try{
      if(value){
        oThat.disabled = true;
        oThat._inputvalue = value;
      }
    }catch(e){
      oThat.disabled = false;
      oThat._inputvalue = value;
    }
    this.loadIFrame(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
