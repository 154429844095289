import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-list-pagination',
  templateUrl: './list-pagination.component.html',
  styleUrls: ['./list-pagination.component.css']
})
export class ListPaginationComponent implements OnInit, OnChanges {

  @Output() setPageChange = new EventEmitter();
  
  @Input() dataTpl: TemplateRef<any> = null;
  @Input() dataList: any = [];
  @Input() itemsPerPage: any = environment.itemsPerPage;

  public currentList : any = [];
  public itemsCount = 0;
  public currentPage = 1;
  public offsetTime = environment.OFFSET;

  constructor() { }

  ngOnInit() {
    this.processPageList();
  }

  pageChange(objEvent) {
    let { page } = objEvent;
    this.currentPage = page; 
    this.processPageList();  
  }

  ngOnChanges(changes: SimpleChanges) {
    let oThat = this;
    if(changes.dataList){
      this.currentPage = 1; 
      setTimeout( function(){ oThat.processPageList(); },500);
    }
  }

  processPageList(){
    let start = (this.currentPage - 1)  * this.itemsPerPage;
    let end = start + this.itemsPerPage;

    if (start > this.dataList.length){
      this.currentList = [];
      return;  
    } 
    if (end > this.dataList.length){
      end = this.dataList.length;
    } 
    this.currentList = this.dataList.slice( start , end);
    
  }
}
