import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http/src/response';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs';

import {ConstantService} from '../../constant/constant.service';

@Injectable({
  providedIn: 'root'
})   
export class EmployeeService {

  constructor(
    private constantService: ConstantService,
    private http: HttpClient
  ) {
  }

  private _items: any = {};
  private profilePic: any = null;
  
  setItem(item: any) {
    this._items = item;
  }

  getItems() {
    return this._items;
  }

  setProfilePic(item: any) {
    this.profilePic = item;
  }

  getProfilePic() {
    return this.profilePic;
  }

  get(params) {
    return this.http.get(this.constantService.getUrlByQuery(this.constantService.EMPLOYEE, params));
  }
  
  profile() {
    return this.http.post(this.constantService.getUrl(`${this.constantService.EMPLOYEE}/me`),{});
  }
  
  getEmployees(params?: any) {
    return this.http.get(this.constantService.getUrl(this.constantService.EMPLOYEE), {
      params: params,
      observe: 'response'
    }).map((resp: any) => {
      let count = resp.headers.get('X-Total-Count');
      return {count: parseInt(count), data: resp.body.data};
    });
  }
  
  changePassword(empId, body) {
    return this.http.put(this.constantService.getUrl(`${this.constantService.EMPLOYEE}/${empId}/changepassword`), body);
  }

  changePicture(empId, body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.EMPLOYEE}/${empId}/changepicture`), body);
  }
  
  loadProfilePic() {
    return this.http.post(this.constantService.getUrl(`${this.constantService.EMPLOYEE}/profilepicture`), {});
  }
  
  updatePermission(empId, body) {
    return this.http.put(this.constantService.getUrl(`${this.constantService.EMPLOYEE}/${empId}/updatepermission`), body);
  }
  
  updateDataAccess(empId, body) {
    return this.http.put(this.constantService.getUrl(`${this.constantService.EMPLOYEE}/${empId}/dataaccess`), body);
  }

  getDataAccess(empId, body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.EMPLOYEE}/${empId}/dataaccess`), body);
  }
  
  employeeAction(empId, body) {
    return this.http.put(this.constantService.getUrl(`${this.constantService.EMPLOYEE}/${empId}/employeeaction`), body);
  }
                                              
  switchProfile(empId) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.EMPLOYEE}/${empId}/switch`), {});
  }
                                              
  enableDebugMode() {
    return this.http.post(this.constantService.getUrl(`${this.constantService.EMPLOYEE}/debugmode`), {});
  }
                                              
  getCustomerDetails(id: number) {
    return this.http.get(this.constantService.getUrl(`${this.constantService.EMPLOYEE}/${id}`));
  }
  
  getById(id: string) {
    return this.http.get(this.constantService.getUrl(this.constantService.EMPLOYEE, [id]));
  }

  post(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.EMPLOYEE), body);
  }

  put(id: string, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.EMPLOYEE, [id]), body);
  }

  deleteEmployee(id: number) {
    return this.http.delete(this.constantService.getUrl(`${this.constantService.EMPLOYEE}/${id}`));
  }

  downloadExcel(params?: any): Observable<HttpResponse<Blob>> {
    params.export = true;
    return this.http.get(this.constantService.getUrl(this.constantService.EMPLOYEE), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }

}