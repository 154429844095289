import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { ReportsRoutingModule } from './reports.routing';
import { RouterModule } from '@angular/router';
import { ReportsRoutes } from './reports.routing'
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedComponentModule } from '../shared-component/shared-component.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CdkTableModule } from '@angular/cdk/table';
import { MomentModule } from 'angular2-moment';
import { DueReportsComponent } from './due-reports/due-reports.component';
import {PapaParseModule} from 'ngx-papaparse';
import { PaymentsReportComponent } from './payments-report/payments-report.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DisbursmentReportComponent } from './disbursment-report/disbursment-report.component';
import { CollectionReportComponent } from './collection-report/collection-report.component';
import { PosReportComponent } from './pos-report/pos-report.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ReportsRoutes),
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentModule,
    InfiniteScrollModule,
    NgbModule,
    CdkTableModule,
    PapaParseModule,
    MomentModule,
    NgbPopoverModule,
    NgMultiSelectDropDownModule.forRoot()

  ],
  declarations: [
    DueReportsComponent,
    PaymentsReportComponent,
    DisbursmentReportComponent,
    CollectionReportComponent,
    PosReportComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG

    }
  ]
})
export class ReportsModule {

 }
