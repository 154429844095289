import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ConstantService {

  //Authentication
  //API_URL: string = 'https://54.84.250.223/api/v1/';
  API_URL: string = environment.API_URL;
  LOGIN: string = 'auth/login';
  VALIDATEOTP: string = 'auth/validateotp';
  FORGETPASSWORD = 'auth/forgetpassword';
  RESETPASSWORD = 'auth';
  BITLY = 'anonymous';
  SECTORS = 'sectors';
  SERVICES = 'services';
  PARTNERS = 'partners';
  BRANCHES = 'branches';
  CUSTOMERS = 'customers';
  LOANS = 'loans';
  COLLECTIONS = 'collections';
  CAMPAIGNS = 'campaigns';
  CLASSIFICATION = 'settings/classifications/customer';
  MASTERCLASSIFICATION = 'settings/classifications';
  SUBVENTION = 'settings/subvention';
  SYSTEM = 'settings/system';
  PINCODE = 'settings/locations';
  SCORE = 'settings/proprietaryScore';
  LOG = 'logs';
  SEARCH = 'search';
  ACTION = 'actions';
  APPLICATIONS = 'loanApplication';
  APPLICATION = 'application';
  SUPPORT = 'feedbacks';
  USER = 'users';
  BUREAU = 'bureaus';
  POS = 'pos';
  RBACROLE = 'rbacroles';
  RBACMODULE = 'rbacmodules';
  RBACPERMISSION = 'rbacpermissions';
  EMPLOYEE = 'employees';
  USERS = 'users';
  ORGANIZATION = 'organizations';
  WORKFLOW = 'workflow';
  CHATBOT = 'chatbot'; 
  BANKERLOAN = 'bankerloan';
  PAYMENTLINK = 'paymentlink';
  REPORTSCHEMAS = 'reportschema';
  REFUNDDISBURSE = 'refunddisburse';
  NOTIFICATION = 'notifications';
  SYNCBCPARTNER = 'syncbcpartner';
  MANUALACTIVITY = 'manualactivity';
  LEAD = 'lead';
  ENQUIRY = 'enquiry';
  APIUSER = 'apiuser';
  INSURANCE = 'insurance';
  CRONJOB = 'cronjob';
  COURSE = 'masterdata/courses';
  TRANCHDISBURSMENT = 'tranchdisbursment';
  PINCODES = 'masterdata/pincodes';
  IFSCCODES = 'masterdata/ifsccodes';
  TINYURL = 'tinyurl';
  TEMPLATE = 'templates';
  LODMASTER = 'lodmaster';
  REGION = 'region';
  COLENDERSCHEMA = 'colenderschema';
  LOANLOD = 'document';
  DASHBOARD = 'dashboard';
  NUMBERSERIALIZER = 'numberserializer';
  VERIFYMOBILE = 'verifymobile';
  
  CHARGESMASTER = "chargesmaster";
  CHECKLISTMASTER = "checklistmaster";
  APPROVALMATRIX = "approvalmatrix";
  DEVIATIONMATRIX = "deviationmatrix";
  LEADCONNECTOR = 'leadconnector';
  VENDOR = 'vendor';
  COLDENDER = 'colender';
  

  getUrl(path: string, params: Array<any> = []) {
    return !params.length ? [this.API_URL, path].join('') : [[this.API_URL, path].join(''), params.join('/')].join('/');
  }

  getUrlByQuery(path: string, query: Array<Object>) {
    let url = [this.API_URL, path].join('');
    let queryString = query.map((o: any) => {
          return `${o.name}=${o.value}`;
        }).join('&'); 
        
    if(queryString)
        return [url, queryString].join('?');
    else
        return url;
  }
  
  getQueryMap(query: any){
    if(query && query.map){
        let queryString = query.map((o: any) => {
          return `${o.name}=${o.value}`; 
        }).join('&');
        return queryString; 
    }else if(query && query.name){
        return `${query.name}=${query.value}`;
    }
  }
}
