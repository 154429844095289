import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ConstantService} from '../../constant/constant.service';

@Injectable({
  providedIn: 'root'
})
export class PinCodeService {

  constructor(
    private http: HttpClient,
    private constantService: ConstantService
  ) {
  }

  get(param: Array<object> = []) {
    if (!param.length) return this.http.get(this.constantService.getUrl(`${this.constantService.PINCODE}/blocked`));
    else return this.http.get(this.constantService.getUrlByQuery(`${this.constantService.PINCODE}/blocked`, param));
  }

  getByPartnerId(param: Array<object> = []) {
    let partnerId = param[2]['value'];
    if (!param.length) return this.http.get(this.constantService.getUrl(`${this.constantService.PINCODE}/${partnerId}/blocked`));
    else return this.http.get(this.constantService.getUrlByQuery(`${this.constantService.PINCODE}/${partnerId}/blocked`,param));
    }

  post(body) {
    return this.http.post(this.constantService.getUrl(this.constantService.PINCODE, ['blocked']), body);
  }

  postByPartnerId(body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.PINCODE}/${body.partnerId}/blocked`), body);
  }

  delete(pincodeId) {
    return this.http.delete(this.constantService.getUrl(`${this.constantService.PINCODE}/blocked/${pincodeId}`));
  }

  deleteByPartnerId(pincodeId,partnerId) {
    return this.http.delete(this.constantService.getUrl(`${this.constantService.PINCODE}/${partnerId}/blocked/${pincodeId}`));
  }

  getStates() {
    return this.http.get(this.constantService.getUrl(this.constantService.PINCODE, ['states']));
  }

  getCites(stateId) {
    return this.http.get(this.constantService.getUrl(this.constantService.PINCODE, ['cities', stateId]));
  }

  getPincodes(cityId) {
    return this.http.get(this.constantService.getUrl(this.constantService.PINCODE, ['pincodes', cityId]));
  }

  getPincodesPartner(cityId,partnerId) {
    return this.http.get(this.constantService.getUrl(`${this.constantService.PINCODE}/${partnerId}/pincodes/${cityId}`));
  }

  bulkUpload(body) {
    return this.http.post(this.constantService.getUrl(this.constantService.PINCODE, ['blocked', 'bulk']), body)
  }

  bulkUploadPartner(body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.PINCODE}/${body.partnerId}/blocked/bulk`), body)
  }

}
