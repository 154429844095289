import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {UserIdleService} from 'angular-user-idle';

@Injectable({
  providedIn: 'root'
})
export class SessionExpiryService {

  public tokenExpireEvent = new Subject();
  public isStarted: boolean = false;

  constructor(
    private userIdle: UserIdleService
  ) {
    this.userIdle.onTimeout().subscribe(() => {
      this.tokenExpireEvent.next();
    });

    this.userIdle.onTimerStart().subscribe();
  }

  register() {
    if (this.isStarted) {
      return true;
    }

    this.userIdle.startWatching();
    this.isStarted = true;
  }

  clear() {
    this.userIdle.stopWatching();
    this.isStarted = false;
  }

}
