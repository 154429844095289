import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'getNumFormat'
})
export class getNumFormatPipe implements PipeTransform {

    transform(number: any, precision: number = 0): any {
        if (isNaN(number)) return 0;
        if (!number) return number;
        let part1 = number.toLocaleString('hi-IN');
        part1 = part1.split(".");
        if(precision){
            let tmp =  number.toFixed?number.toFixed(precision):number;
            tmp = tmp.split(".");
            if(tmp.length == 2)
                return part1[0]+"."+tmp[1];
            else
                return part1[0]
        }
        return part1[0];
    }

}
