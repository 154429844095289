import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DueReportsComponent } from './due-reports/due-reports.component';
import { PaymentsReportComponent } from './payments-report/payments-report.component';
import { DisbursmentReportComponent } from './disbursment-report/disbursment-report.component';
import { PosReportComponent} from './pos-report/pos-report.component';
import { CollectionReportComponent } from './collection-report/collection-report.component';

export const ReportsRoutes: Routes = [
  {
    path: 'due',
    component: DueReportsComponent
  },
  {
    path: 'paymentsdue',
    component: PaymentsReportComponent
  },
  {
    path: 'disbursementdue',
    component: DisbursmentReportComponent
  },
  {
    path: 'pos',
    component:PosReportComponent 
  },
  {
    path: 'collection',
    component: CollectionReportComponent
  }
];

