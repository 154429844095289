import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'listItem'
})
export class ListItemPipe implements PipeTransform {

  transform(item: string, list: any[], defaultVal = null, id = "", fields = []): any {
    if(id){
      for (let i of list) {
        if(i[id] == item){
          if( typeof(fields) != "string" && fields.length)
            return fields.map( o => i[o] ).join(" ");
          else if (typeof(fields) == "string"){
            return i[fields] === undefined? defaultVal : i[fields];
          }else{
            return i;
          }
        }
      }
    } else {
      for (let i in list) {
        if(i == item){
          return list[i];
        }
      }
    }
    return defaultVal;
  }

}
