import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {animate, style, transition, trigger} from '@angular/animations';
import {ToasterService} from 'angular2-toaster';
import {Papa} from 'ngx-papaparse';
import {AccessCodeService} from '../../../shared-component/constant/accessCode.service';
import {PinCodeService} from '../../../shared-component/service/pin-code/pin-code.service';
import { ActivatedRoute } from '@angular/router';
import { ToasterAlertService } from '../../notification/toastAlert.service';


@Component({
  selector: 'app-add-pincode',
  templateUrl: './add-pincode.component.html',
  styleUrls: ['./add-pincode.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(+100%)'}),
        animate('250ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        style({transform: 'translateY(0%)'}),
        animate('0ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class AddPincodeComponent implements OnInit {

  public accessCodeList = AccessCodeService;
  public states: any = [];
  public cities: any = [];
  public pincodes: any = [];
  public addPinCodeForm: FormGroup;
  
  @Output() closeSideBar = new EventEmitter();

  @Input() level;

  constructor(
    private fb: FormBuilder,
    private toasterService: ToasterAlertService,
    private papa: Papa,
    private pincodeProvider: PinCodeService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.addPinCodeForm = this.fb.group({
      state: ['', Validators.compose([Validators.required])],
      city: ['', Validators.compose([Validators.required])],
      pincode: ['', Validators.compose([Validators.required])],
    });

    this.pincodeProvider.getStates().subscribe((res: any) => {
      this.states = res.data;
    });

    this.state.valueChanges.subscribe((res: any) => {
      this.cities = [];
      this.pincodes = [];
      if (res) {
        this.pincodeProvider.getCites(res).subscribe((res: any) => {
          this.cities = res.data;
          this.city.setValue('');
        });
      }
    });

    this.city.valueChanges.subscribe((res: any) => {
      this.pincodes = [];
      if (res) {
        if(this.level === "GLOBAL"){
        this.pincodeProvider.getPincodes(res).subscribe((res: any) => {
          this.pincodes = res.data;
          this.pinCode.setValue('');
        });
      }
      else if(this.level === "PARTNER"){
        let partnerId = this.activatedRoute.snapshot.paramMap.get('id');
        this.pincodeProvider.getPincodesPartner(res,partnerId).subscribe((res: any) => {
          this.pincodes = res.data;
          this.pinCode.setValue('');
        });
      }
      }
    });
  }

  get state() {
    return this.addPinCodeForm.get('state');
  }

  get city() {
    return this.addPinCodeForm.get('city');
  }

  get pinCode() {
    return this.addPinCodeForm.get('pincode');
  }

  sideBar(param) {
    this.closeSideBar.emit(param);
  }

  addPincode() {
    let partnerId = this.activatedRoute.snapshot.paramMap.get('id');
    if(this.level === "GLOBAL"){
    return this.pincodeProvider.post({
      pincode: this.pinCode.value
    }).subscribe((res: any) => {
      this.sideBar({
        reload: true
      });
      this.toasterService.pop('success', '', res.message);
    }, (err) => {
      this.toasterService.pop('error',
        '',
        `
                    Choose a file                
            `);
    });
  }
  else if(this.level === "PARTNER"){
    return this.pincodeProvider.postByPartnerId({
      pincode: this.pinCode.value,
      partnerId:partnerId
    }).subscribe((res: any) => {
      this.sideBar({
        reload: true
      });
      this.toasterService.pop('success', '', res.message);
    }, (err) => {
      this.toasterService.pop('error',
        '',
        `
                    Choose a file                
            `);
    });
  }
  }

  bulkUpload(file) {
    let partnerId = this.activatedRoute.snapshot.paramMap.get('id');
    if (file.files && file.files.length) {
      if(this.level === "GLOBAL"){
      this.papa.parse(file.files[0], {
        complete: (result) => {
          if (result.data && result.data.length) {
            this.pincodeProvider.bulkUpload({
              pincodes: result.data.map(data => data[0]).filter((data) => data !== '')
            }).subscribe((res: any) => {
              this.sideBar({
                reload: true
              });
              this.toasterService.pop('success', '', res.message);
            });
          }
        }
      });
    } else if(this.level === "PARTNER"){
      this.papa.parse(file.files[0], {
        complete: (result) => {
          if (result.data && result.data.length) {
            this.pincodeProvider.bulkUploadPartner({
              partnerId:partnerId,
              pincodes: result.data.map(data => data[0]).filter((data) => data !== '')
            }).subscribe((res: any) => {
              this.sideBar({
                reload: true
              });
              this.toasterService.pop('success', '', res.message);
            });
          }
        }
      });
    }
   } else {
      this.toasterService.pop('error', '', 'Choose a file');
    }
  }

}
