import { Component, ElementRef, HostListener, OnInit, ViewChild, TemplateRef } from '@angular/core';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToasterService } from 'angular2-toaster';

import { NotificationService } from '../../shared-component/service/notification/notification.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToasterAlertService } from '../../shared-component/notification/toastAlert.service';

@Component({
  selector: 'full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {

  color = 'blue';
  showDarktheme = false;
  public msgList: any = [];
  public modelMsg: any = {};

  @ViewChild('mainWrapper') mainWrapper: ElementRef;
  @ViewChild('modelPopup')
  private modelPopup: TemplateRef<any>;


  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    if (this.mainWrapper) {
      let mainWrapper = this.mainWrapper.nativeElement.classList;
      if (window.innerWidth <= 767) {
        mainWrapper.add('mini-sidebar');
      }
    }
  }

  public config: PerfectScrollbarConfigInterface = {};

  constructor(
    private modalService: NgbModal,
    private notificationProvider: NotificationService,
    private toasterService: ToasterAlertService,
    public sanitizer: DomSanitizer
  ) {
    this.getScreenSize();
    //this.getScheduleNotifications();
  }

  ngOnInit() {
    if (window.innerWidth <= 767 && this.mainWrapper) {
      this.mainWrapper.nativeElement.classList.add('mini-sidebar');
    }
  }

  triggerMenu() {
    let mainWrapper = this.mainWrapper.nativeElement.classList;
    if (mainWrapper.contains('mini-sidebar')) {
      mainWrapper.remove('mini-sidebar');
    } else {
      mainWrapper.add('mini-sidebar');
    }
  }

  getScheduleNotifications() {
    this.msgList = [];
    this.notificationProvider.schedule()
      .subscribe((res: any) => {
        res.data.map((item) => {
          switch (item.behaviour) {
            case 1: // Flash
              let msgFlash = item.longMsg ? item.longMsg : item.shortMsg;
              let className = 'msg' + new Date().getTime();
              this.msgList.push({ ...JSON.parse(msgFlash.replace(/[\n]/g, "")), className: className });
              setTimeout(function () { Array.from(document.querySelectorAll("." + className)).forEach(el => el.remove()); }, 10000);
              break;
            case 2: // Fixed
              let msg = item.longMsg ? item.longMsg : item.shortMsg;
              this.msgList.push(JSON.parse(msg.replace(/[\n]/g, "")));
              break;
            case 3: //model
              this.modelMsg = JSON.parse(item.longMsg.replace(/[\n]/g, ""));
              this.modalService.open(this.modelPopup, { backdrop: 'static', centered: true });
              break;
            case 4: // alert
              let msgAlert = JSON.parse(item.shortMsg.replace(/[\n]/g, ""));
              this.toasterService.pop(msgAlert.type, '', msgAlert.message);
              break;
            case 5: // script
              this.loadScripts(item.dynScript);
              break;
          }
        });
      });
  }

  loadScripts(dynamicScripts) {
    dynamicScripts = eval('(' + dynamicScripts.replace(/[\n]/g, "") + ')');
    window["asset-" + dynamicScripts.name] = {};
    for (let i = 0; i < dynamicScripts["sources"].length; i++) {
      let item = dynamicScripts["sources"][i];
      let node: any;
      switch (item.type) {
        case "css":
          if (item.source == "path") {
            node = document.createElement('link');
            node.href = item.value;
            node.classList.add('dynScript-' + dynamicScripts.name);
            node.type = 'text/css';
            node.async = false;
          } else {
            node = document.createElement('style');
            node.classList.add('dynScript-' + dynamicScripts.name);
            node.innerHTML = item.value;
          }
          document.getElementsByTagName('head')[0].appendChild(node);
          break;
        case "img":
          window["asset-" + dynamicScripts.name][item.callBack] = item.value;
          break;
      }
    }

    for (let i = 0; i < dynamicScripts["sources"].length; i++) {
      let item = dynamicScripts["sources"][i];
      let node: any;

      switch (item.type) {
        case "js":
          if (item.source == "path") {
            node = document.createElement('script');
            node.src = item.value;
            node.classList.add('dynScript-' + dynamicScripts.name);
            node.type = 'text/javascript';
            node.async = false;
            if (item.callBack) {
              node.addEventListener("load", () => {
                window[item.callBack + ""] ? window[item.callBack + ""].call() : "";
              });
            }
            document.getElementsByTagName('head')[0].appendChild(node);
          } else {
            try {
              eval(item.value);
              if (item.callBack) {
                window[item.callBack + ""] ? window[item.callBack + ""].call() : "";
              }
            } catch (e) {
              console.log(e);
            }
          }
          break;
      }
    }
    window[dynamicScripts.call + ""] ? window[dynamicScripts.call + ""].call() : "";
  }
}
