import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'getDate'
})
export class getDatePipe implements PipeTransform {

  transform(dateData: any, time: any = false, shortTime: boolean = false, age : any = false): any {
    if(!dateData) return "";
    if(dateData == "0000-00-00") return "";
    let dt = null;
    const getAge = function(dob) {
      let today = new Date();
      let birthDate = new Date(dob);
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return isNaN(age)?"--":age;
    }
    if(age){
      return getAge(dateData);
    }

    let dateIST : any = new Date(dateData).getTime()  + ( ( (5 * 60) + 30 ) * 60 );
    dateIST = new Date(dateIST); 
    
    if(shortTime)
      dt = moment(dateIST).format("DD MMM, YYYY HH:mm");
    else if(time && typeof time == "boolean")
      dt = moment(dateIST).format("DD MMM, YYYY hh:mm:ss A");
    else if(time && typeof time == "string")
      dt = moment(dateIST).format(time);
    else 
      dt = moment(dateIST).format("DD MMM, YYYY"); 
    return dt;
  }

}
