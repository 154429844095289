import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterGuardService implements CanActivate {

  constructor(
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let tokenExist = sessionStorage.getItem('token'),
      activate: boolean = true;

    if (tokenExist === null || tokenExist === undefined) {
      this.router.navigate(['authentication']).then();
      activate = true;
    }
    return activate;
  }

}
