import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http/src/response';
import { BehaviorSubject, Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';

import { ConstantService } from '../../constant/constant.service';
import { getExtention } from '../../helper/common';

@Injectable({
  providedIn: 'root'
})
export class LoanService {
  loanState;
  formState;

  private changeLoanCount = new BehaviorSubject({});
  currentCount = this.changeLoanCount.asObservable();

  private changeCount = new BehaviorSubject(0);
  currentOrg = this.changeCount.asObservable();

  constructor(
    private http: HttpClient,
    private constantService: ConstantService
  ) {
  }

  getPosReport(params?: any) {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + "/posReport"),{
      params: params,
      observe: 'response'
    }).map((resp: any) => {
       return { data: resp.body.data};
      });
  }

  getCollectionReport(params?: any) {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + "/collectionReport"),{
      params: params,
      observe: 'response'
    }).map((resp: any) => {
        return { data: resp.body.data};
      });
  }

  getDueLoans(params?: any) {
    console.log("___inside LaonService");
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + "/dueloans"),{
      params: params,
      observe: 'response'
    }).map((resp: any) => {
      return { data: resp.body.data };
    });

  }

  updateCount(params) {
    this.changeLoanCount.next(params);
  }

  updateOrgCollectionCount(orgiId: number) {
    this.changeCount.next(orgiId || 0);
  }

  get(param: Array<object>) {
    if (!param.length) return this.http.get(this.constantService.getUrl(this.constantService.LOANS));
    else return this.http.get(this.constantService.getUrlByQuery(this.constantService.LOANS, param));
  }

  setLoanState(data) {
    this.loanState = data;
  }

  saveFormState(data) {
    this.formState = data;
  }

  getFormState(): any {
    return this.formState;
  }

  getLoanState() {
    return this.loanState;
  }

  getLoans(params?: any) {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS), {
      params: params,
      observe: 'response'
    }).map((resp: any) => {
      return { data: resp.body.data };
    });
  }

  downloadPosReportExcel(params?: any): Observable<HttpResponse<Blob>> {
    params.export = true;
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + '/download/posReport/excel'), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadCollectionReportExcel(params?: any): Observable<HttpResponse<Blob>> {
    params.export = true;
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + '/download/collectionReport/excel'), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadDueLoansExcel(params?: any): Observable<HttpResponse<Blob>> {
    params.export = true;
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + '/download/dueLoans/excel'), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadPosExcel(params?: any): Observable<HttpResponse<Blob>> {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + '/pos/download/excel'), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadTcplCreditExcel(params?: any): Observable<HttpResponse<Blob>> {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + '/download/credit/excel'), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadTcplApprovedExcel(params?: any): Observable<HttpResponse<Blob>> {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + '/download/approved/excel'), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadExcel(params?: any): Observable<HttpResponse<Blob>> {
    params.export = true;
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + '/download/excel'), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadDRFExcel(params?: any): Observable<HttpResponse<Blob>> {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + '/download/exportDRFToExcel'), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadEmiExcel(id): Observable<HttpResponse<Blob>> {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + `/${id}` + '/downloadEmi/excel'), {
      observe: 'response',
      responseType: 'blob'
    });
  }

  getById(id) {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + `/${id}`));
  }

  getLoanById(id) {
    return this.http.post(this.constantService.getUrl(this.constantService.LOANS + `/${id}`), {});
  }
  getLoanCount(body = {}) {
    return this.http.post(this.constantService.getUrl(this.constantService.LOANS + `/counts`), body);
  }

  uploadPropertyDoc(id, body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.APPLICATION}/upload`), body);
  }

  approveReject(id, body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.APPLICATION}/${id}/approveReject`), body);
  }

  getSignedUrl(body) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + '/signedurl'), body);
  }

  createSearchReport(body) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + '/createCersaiReport'), body);
  }

  downloadSearchReport(body) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + '/downloadcersaiReport'), body);
  }

  deleteDoc(id) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/deleteDoc`), {})
  }

  repaymentSchedule(id) {
    return this.http.post(this.constantService.getUrl(this.constantService.LOANS + `/${id}/repayment`), {});
  }

  updateDisbursment(id, params) {
    return this.http.post(this.constantService.getUrl(this.constantService.LOANS + `/${id}/disbursment`), params);
  }

  favouringDisbursment(id, params) {
    return this.http.post(this.constantService.getUrl(this.constantService.LOANS + `/${id}/favrouingdisbursment`), params);
  }

  downloadSbiBankUpload(id): Observable<HttpResponse<Blob>> {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + `/${id}` + '/sbiupload/download/excel'), {
      observe: 'response',
      responseType: 'blob'
    });
  }

  regenerateSchedule(id, body) {
    return this.http.post(this.constantService.getUrl(this.constantService.LOANS + `/${id}/regenerate`), body);
  }

  getLod(id) {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + `/${id}/lod`));
  }

  getLedger(id) {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + `/${id}/ledger`));
  }

  modifyLod(id, body) {
    return this.http.post(this.constantService.getUrl(this.constantService.LOANS + `/${id}/lod`), body);
  }

  profile() {
    return this.http.get(this.constantService.getUrl(`${this.constantService.USERS}/me`));
  }


  refuseApplication(token, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION + `/${token}/creditReject`), body);
  }

  reviewRejectApplication(token, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION + `/${token}/reviewReject`), body);
  }

  sendBitly(id) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.APPLICATION}/${id}/sendCustomerLink`), null);
  }

  cancelApplication(token, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION + `/${token}/cancel`), body);
  }

  getCKycDetails(customerId) {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + `/${customerId}/cKycDetails`));
  }

  getVideoTCPLKyc(applicationId) {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + `/${applicationId}/getTcplVideoKyc`));
  }

  getAllIncomeDocuments(id) {
    return this.http.get(this.constantService.getUrl(`${this.constantService.APPLICATION}/${id}/incomeDocs/signed`));
  }

  verifyLoan(id: number, body) {
    return this.http.put(this.constantService.getUrl(`${this.constantService.LOANS}/${id}/verified`), body);
  }

  extendExpiryDate(appllicationId) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.LOANS}/${appllicationId}/extendexpirydate`), {});
  }

  uploadPNachDoc(id, type, body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.LOANS}/${id}/${type}/upload`), body);
  }

  loanCounts() {
    return this.http.post(this.constantService.getUrl(this.constantService.LOANS + `/loancount`), {});
  }

  cancelLoan(id, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.LOANS +`/${id}/cancelLoan`), body)
  }

  getCloseLoanStates(id, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.LOANS +`/${id}/getCloseLoanStates`), body)
  }

  createLedger(id, body) {
    return this.http.post(this.constantService.getUrl(this.constantService.LOANS + `/${id}/ledger`), body);
  }
}
