import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {ToasterService} from 'angular2-toaster';

import {PinCodeService} from '../../../shared-component/service/pin-code/pin-code.service';
import {AccessCodeService} from '../../../shared-component/constant/accessCode.service';
import { ToasterAlertService } from '../../notification/toastAlert.service';

@Component({
  selector: 'app-n-pin',
  templateUrl: './n-pin.component.html',
  styleUrls: ['./n-pin.component.css']
})
export class NPinComponent implements OnInit {

  @Input() level;
  public accessCodeList = AccessCodeService;
  public showAddPincode = false;

  public nPinData: any = [];
  public states: any = [];
  public cities: any = [];

  public pinCodeFilter: FormGroup;

  constructor(
    private fb: FormBuilder,
    private toasterProvider: ToasterAlertService,
    private pinCodeProvider: PinCodeService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.pinCodeFilter = this.fb.group({
      state: [''],
      city: [''],
    });

    this.loadFilter();

    this.pinCodeProvider.getStates().subscribe((res: any) => {
      this.states = res.data;
    });

    this.state.valueChanges.subscribe((res: any) => {
      this.cities = [];
      if (res) {
        this.pinCodeProvider.getCites(res).subscribe((res: any) => {
          this.cities = res.data;
          this.city.setValue('');
        });
      }
    });
  }

  get state() {
    return this.pinCodeFilter.get('state');
  }

  get city() {
    return this.pinCodeFilter.get('city');
  }

  loadFilter() {
    let partnerId = this.activatedRoute.snapshot.paramMap.get('id');
    if(this.level === "GLOBAL"){
    this.pinCodeProvider.get([{
      name: 'stateId',
      value: this.state.value,
    }, {
      name: 'cityId',
      value: this.city.value
    }]
    ).subscribe((res: any) => {
      this.nPinData = res.data.map((data) => {
        data.active = true;
        return data;
      });
    });
  }
  else if(this.level === "PARTNER"){
    this.pinCodeProvider.getByPartnerId([{
      name: 'stateId',
      value: this.state.value,
    }, {
      name: 'cityId',
      value: this.city.value
    }, {
      name: 'partnerId',
      value: partnerId
    }]
    ).subscribe((res: any) => {
      this.nPinData = res.data.map((data) => {
        data.active = true;
        return data;
      });
    });
  }
  }

  deletePincode(data: any) {
    let partnerId = this.activatedRoute.snapshot.paramMap.get('id');
    if(this.level === "GLOBAL"){
    this.pinCodeProvider.delete(data.pincode).subscribe((res: any) => {
      data.active = false;
      this.toasterProvider.pop('success', '', res.message)
    })
  }
  else if(this.level === "PARTNER"){
    this.pinCodeProvider.deleteByPartnerId(data.pincode,partnerId).subscribe((res: any) => {
      data.active = false;
      this.toasterProvider.pop('success', '', res.message)
    })
  }
  }

  closeSideBar(event: any) {
    this.showAddPincode = false;
    if (event.reload) {
      this.resetFilter();
    }
  }

  resetFilter() {
    this.pinCodeFilter.get('state').setValue('', {emitEvent: false});
    this.pinCodeFilter.get('city').setValue('', {emitEvent: false});
    this.loadFilter();
  }
}
