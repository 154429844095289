export const uuidv4 = function () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
}

export const convertToCSV = function (arr) {
  const array = [Object.keys(arr[0])].concat(arr);

  return array.map(it => {
    return Object.values(it).toString();
  }).join('\n');
}


export const decrypt = (salt, encoded) => {
  if (!encoded) return null;
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join("");
};


export const crypt = (salt, text) => {
  console.log(text);
  if (!text) return null;
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

  return text
    .split("")
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join("");
};

export const numInWords = (num) => {
  var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
  var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  let word = "";
  let len = num.toString().length;
  if (len > 9) {
    let postnum = (num + "").substr(0, len - 7);
    word = numInWords(postnum);
    num = (num + "").substr(-7);
  }
  let wordNum = "";
  let n: any = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (n) {
    var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'And ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : '';

    wordNum = str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
      return letter.toUpperCase();
    }).trim();
  }
  if (word && wordNum)
    return [word, wordNum].join(" Crore And ");
  if (word)
    return word + " Crore";
  return wordNum;
}

export const encodeQueryData = (url, data) => {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return (url ? (url + "?") : "") + ret.join('&');
}

export const updateIframeWithHTML = function (iframe, data) {
  if (!iframe) return;
  if (data) {
    iframe = iframe.contentWindow || (iframe.contentDocument.document || iframe.contentDocument);
    iframe.document.open();
    iframe.document.write(data.replaceAll("%%", "%"));
    iframe.document.close();
  }
}

export const openModalPopup = (modalService, content, options) => {
  let dragable = false;
  if (options.dragable) {
    dragable = true;
    delete options.dragable;
  }
  modalService.open(content, options);
  if (dragable)
    window["dragElement"].call(this);
}

export const getKeyByValue = function (object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export const getNameValuePair = function (object) {
  return Object.keys(object).reduce((a, o) => {
    a.push({ name: o, value: object[o] });
    return a;
  }, []);
}

export const createCaptcha = (size = 6) => {
  //clear the contents of captcha div first 
  let code = "";
  let dom = document.getElementById("captchaImage");
  dom.innerHTML = "";
  var charsArray =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var lengthOtp = size;
  var captcha = [];
  for (var i = 0; i < lengthOtp; i++) {
    //below code will not allow Repetition of Characters
    var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
    if (captcha.indexOf(charsArray[index]) == -1)
      captcha.push(charsArray[index]);
    else i--;
  }
  var canv = document.createElement("canvas");
  canv.id = "captcha";
  canv.width = 150;
  canv.height = 35;
  var ctx = canv.getContext("2d");
  ctx.font = "25px Georgia";
  ctx.strokeText(captcha.join(""), 0, 30);

  const drawLines = function (context) {
    //random lines
    context.moveTo(Math.random() * 5 + 2, Math.random() * 35 + 5);
    context.lineTo(Math.random() * 10 + 140, Math.random() * 35 + 5);
    context.stroke();

    context.moveTo(50, 0);
    context.lineTo(20, 60);
    context.stroke();

    context.moveTo(58, 60);
    context.lineTo(100, 0);
    context.stroke();

    context.moveTo(Math.random() * 5 + 2, Math.random() * 35 + 5);
    context.lineTo(Math.random() * 10 + 140, Math.random() * 5 + 5);
    context.stroke();

  }

  drawLines(ctx)
  //storing captcha so that can validate you can save it somewhere else according to your specific requirements
  code = captcha.join("");
  dom.appendChild(canv); // adds the canvas to the body element
  return code;
}

export const getDateObject = function (oDate = null) {
  let dt = oDate?new Date(oDate):new Date();
  return { "year": dt.getFullYear(), "month": dt.getMonth() + 1, "day": dt.getDate() };
}

export const getAge = function (dob) {
  let today = new Date();
  let birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export const getLocationByPin = async function (self, value, entity = null) {
  let requestOptions: any = {
    method: 'GET',
    redirect: 'follow'
  };
  value = value && value.target ? value.target.value : value;
  fetch("https://api.postalpincode.in/pincode/" + value, requestOptions)
    .then(response => response.json())
    .then((result: any) => {
      let oData = {};
      if (result[0].Status == "Error") {
        self.toasterService.pop('error', '', `Please recheck the PIN code :${value}`);
      } else {
        oData = {
          district: result[0].PostOffice[0].District,
          city: result[0].PostOffice[0].Division,
          state: result[0].PostOffice[0].State
        }
        for (let field in oData) {
          if (entity.controls[field]) {
            let val: any = {};
            val[field] = oData[field]
            entity.patchValue(val);
          }
        }
        return oData;
      }
    })
    .catch(error => console.log('error', error));
}


export const getBankDetail = async function (self, value, entity) {
  let requestOptions: any = {
    method: 'GET',
    redirect: 'follow'
  };

  fetch("https://ifsc.razorpay.com/" + value, requestOptions)
    .then(response => response.json())
    .then((result: any) => {
      let oData = {};
      if (result == "Not Found") {
        self.toasterService.pop('error', '', `Please recheck the Bank IFSC code :${value}`);
      } else {
        oData = {
          bankName: result.BANK,
          branchName: result.BRANCH + " : " + result.MICR,
        }
        if (entity.patchValue) {
          entity.patchValue(oData);
        }
        return oData;
      }
    })
    .catch(error => console.log('error', error));
}

export const getBankIfscCodeDetail = async function (self, value, entity) {

  {
    let requestOptions: any = {
    method: 'GET',
    redirect: 'follow'
  };

  fetch("https://ifsc.razorpay.com/" + value, requestOptions)
    .then(response => response.json())
    .then((result: any) => {
      let oData = {};
      if (result == "Not Found") {
        self.toasterService.pop('error', '', `Please recheck the Bank IFSC code :${value}`);
      } else {
        oData = {
          bankName: result.BANK,
          branchName: result.BRANCH + " : " + result.MICR,
          ifscCode: result.IFSC,
          address: result.ADDRESS,
          contactNumber: result.CONTACT,
          cityName1: result.DISTRICT,
          cityName2: result.CITY,
          stateName: result.STATE
        }
        if (entity.patchValue) {
          entity.patchValue(oData);
        }
        return oData;
      }
    })
    .catch(error => console.log('RAZORPAY ERROR', error));
  }
}


export const getExtention = function (file) {
  if (!file) return "";
  let list = file.split(".");
  if (list.lenght == 1) return "";
  return list.reduce((a, o) => {
    a = "." + o;
    return a;
  }, "");
}

export const tree = function (items: any = [], id = null, link = 'parent_id') {
  return items
    .filter(item => item[link] === id)
    .map(item => ({ ...item, children: tree(items, item.id, link) }));
}

export const emiSchedule = function (loanDetails, repaymentDetail) {
  let moratoriumTenure = loanDetails.moratoriumTenure || 0;
  let loanAmount = loanDetails.loanAmount;
  // let principal = this.loanDetails.loanAmount;
  // let loanTenure = this.loanDetails.loanTenure;
  // let interest = this.loanDetails.roi;

  loanAmount = repaymentDetail.principalOutstanding;
  let preyyyy = 0;
  let yyyy = 0;

  return repaymentDetail.emiSchedule.map(o => {
    let payableEmi = {
      payablePrincipal: 0,
      payableInterest: 0,  
      payableDue: o.detlaAmount
    };
    let principal = o.principal;
    if(o.LoanEmiTranch){
      principal = 0;
      o.LoanEmiTranch.map( a => {
        payableEmi.payablePrincipal += parseInt(a.principal);
        payableEmi.payableInterest += parseInt(a.interest);
        // payableEmi.payableDue += parseInt(a);
      });
      principal = payableEmi.payablePrincipal;
    }
    let opningBalance = o.opningBalance || loanAmount;
    loanAmount = loanAmount - principal;
    let balance = o.balance;
    preyyyy = yyyy;
    yyyy = new Date(o.dueDate).getFullYear();
    
    
    return {
      serialNo: o.emiNumber,
      preyyyy: preyyyy,
      yyyy: yyyy,
      paymentDate: o.dueDate,
      status: o.status || 1,
      emiAmount: o.emiAmount,
      principal: o.principal,
      interest: o.intAmount || o.interest,
      opningBalance: (opningBalance),
      balance: (balance),
      paid: o.paidAmount || 0,
      due: o.emiAmount,
      totalPaid: (Math.round(loanDetails.loanAmount - balance) * 100 / loanDetails.loanAmount).toFixed(2),
      tranches : o.LoanEmiTranch || [],
      ...payableEmi
    };
  });
}

export const simpleEncrypt = function (text, key) {
  let result = '';
  for (let i = 0; i < text.length; i++) {
    result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(0));
  }
  let base64Result = btoa(result);
  base64Result = base64Result.replace(/\//g, '_').replace(/\\/g, '-');
  return base64Result;
}