import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sort",
})
export class SortPipe implements PipeTransform {
  transform(values : any = [], order: "asc" | "desc" = "asc", field = ""): any {

    const getVal = function(o){
        if(field) return isNaN(o[field])?o[field]: parseInt(o[field]);
        return isNaN(o)?o: parseInt(o);
    }
    return values.sort((a, b) => {
        if (order === "asc") {
            return getVal(a) - getVal(b)
        } else if (order === "desc") {
            return getVal(b) - getVal(a);
          }
          return 0;
      });
  }
}