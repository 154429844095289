import { Component,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewContainerRef,
  ViewChild,
  ElementRef } from '@angular/core';
import * as moment from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessCodeService } from '../../shared-component/constant/accessCode.service';
import { CanAccessPipe } from '../../shared-component/pipe/pipe/canAccess.pipe';
import { LoanService } from '../../shared-component/service/loan/loan.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ColumnDragDrop } from '../../../app/shared-component/helper/columnDragDrop';
import { Location } from '@angular/common';
import { encodeQueryData } from '../../shared-component/helper/common';
import { AdvanceInputComponent } from '../../shared-component/common-components/advanceInput/advanceInput.component';
import { ToasterAlertService } from '../../shared-component/notification/toastAlert.service';
import * as downloadjs from 'downloadjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-due-reports',
  templateUrl: './due-reports.component.html',
  styleUrls: ['./due-reports.component.css'],
  providers: [ CanAccessPipe ]
})
export class DueReportsComponent implements OnInit {

  readonly TABLE_IDENTIFIER = 'REPORT_DUE';
  readonly STORAGE_KEY = 'REPORT_DUE_TABLE';
  
  public filterForm: FormGroup;
  public excelStatementForm: FormGroup;
  public accessCodeList = AccessCodeService;

  public searchData: any = {};
  public dataSource = new BehaviorSubject<any[]>([]);

  public itemsCount = 0;
  public itemsPerPage = environment.itemsPerPage;
  public currentPage = 1;

  public todayDate: any;
  public search = '';
  public queryField: string = "";
  public branches = [];
  public byloanId = '';
  public byfullName = '';
  public bymobile = '';
  public byemail = '';
  public sortKey = '';
  public sortDirection = 'asc';

  public oColumnDragDrop;
  public columnUserPreference = [
    { field: 'loanNumber', label: 'Loan Number', visible: true },
    { field: 'loanAmount', label: 'Loan Amount', visible: true },
    { field: 'paidEmiCount', label: 'No. of Paid Emis', visible: true },
    { field: 'totalEmiCount', label: 'Total Emis', visible: true },
    { field: 'principalPaidAmount', label: 'Paid Principal Amount', visible: true },
    { field: 'branch', label: 'Branch', visible: true },
    { field: 'applicantFullName', label: 'Applicant Name', visible: true },
    { field: 'mobile', label: 'Mobile', visible: true },
    { field: 'email', label: 'Email', visible: true },
    { field: 'emiStartDate', label: 'EMI Start Date', visible: true },
    { field: 'emiEndDate', label: 'EMI End Date', visible: true },
    { field: 'createdAt', label: 'Created Date', visible: true },
    { field: 'emiAmount', label: 'EMI Amount', visible: true },
    { field: 'currentEmiDueCount', label: 'Current Emi Due Count', visible: true},
    { field: 'currentEmiDueAmount', label: 'Current Emi Due Amount', visible: true},
    { field: 'delinquentEmiDueCount', label: 'Delinquent Emi Due Count', visible: true},
    { field: 'delinquentEmiDueAmount', label: 'Delinquent Emi Due Amount', visible: true},
    { field: 'totalBounceCharge', label: 'Total Bounce', visible: true},
    
    
  ];

  @ViewChildren(AdvanceInputComponent) advanceInputComponent: QueryList<AdvanceInputComponent>;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private canAccessPipe: CanAccessPipe,
    private loanService: LoanService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private toasterService: ToasterAlertService,
    private modalService: NgbModal

  ) {
    window.scrollTo(0, 0);
    if (!this.canAccessPipe.transform(this.accessCodeList.REPORTS_DUE)) {
      this.router.navigate(['/accessdenied']);
      return;
    }

    this.excelStatementForm = fb.group({
      excelStartDate: [null],
      excelEndDate: [null]
    });

    this.filterForm = fb.group({
      startDate: [null],
      endDate: [null],
      branch: [null]
    });

  }


  ngOnInit() {
    if (localStorage.getItem(this.STORAGE_KEY + this.TABLE_IDENTIFIER)) {
      try{
        if (localStorage.getItem(this.STORAGE_KEY + this.TABLE_IDENTIFIER )) {
          let preferences = JSON.parse(localStorage.getItem(this.STORAGE_KEY + this.TABLE_IDENTIFIER));
          this.columnUserPreference = this.columnUserPreference.map(o => {
            let preference = preferences.find(p => p.field === o.field);
            if (preference) {
            o.visible = preference.visible;
            }
            return o;
          });
      }
      }catch(e){}
    }
    this.getDueLoans();

    this.todayDate = {
      year: parseInt(moment().format('YYYY')),
      month: parseInt(moment().format('MM')),
      day: parseInt(moment().format('DD'))
    };
  }
  get filterStartDate() {
    return this.filterForm && this.filterForm.get('startDate');
  }

  get filterEndDate() {
    return this.filterForm && this.filterForm.get('endDate');
  }

  get filterBranchId() {
    return this.filterForm && this.filterForm.get('branch');
  }

  get filterAmount() {
    return this.filterForm && this.filterForm.get('loanAmount');
  }

  get filterStatus() {
    return this.filterForm && this.filterForm.get('status');
  }


  get excelStartDate() {
    return this.excelStatementForm && this.excelStatementForm.get('excelStartDate');
  }

  get excelEndDate() {
    return this.excelStatementForm && this.excelStatementForm.get('excelEndDate');
  }

  

  getDueLoans(flag=false){
    this.currentPage = flag ? 1 : !this.currentPage ? 1 : this.currentPage;
    let params: any = {
      limit: this.itemsPerPage,
      page: this.currentPage,
      qf: this.queryField,
      q: this.search
    };
    if (this.search) params.q = this.search;
    if (this.search) params.qf = this.queryField;
    if (this.byfullName) params.fullName = this.byfullName;
    if (this.bymobile) params.mobile = this.bymobile;
    if (this.byemail) params.email = this.byemail;
    if (this.filterStartDate.value && Object.keys(this.filterStartDate.value).length) params.startDate
      = this.filterStartDate.value.year + '-' + this.filterStartDate.value.month + '-' + this.filterStartDate.value.day + ' 00:00:00';
    if (this.filterEndDate.value && Object.keys(this.filterEndDate.value).length) params.endDate
      = this.filterEndDate.value.year + '-' + this.filterEndDate.value.month + '-' + this.filterEndDate.value.day + ' 23:59:59';
    if (this.filterAmount && this.filterAmount.value) params.loanAmount = this.filterAmount.value;
    if (this.filterBranchId.value && this.filterBranchId.value.id) params.branch = this.filterBranchId.value.id;
    this.searchData = params;
    
    this._getDueLoans()

  }
  _getDueLoans() {
    console.log("------inside_getDueLaons_component");

    this.loanService.getDueLoans(this.searchData).subscribe((res: any) => {
      this.dataSource.next(res.data);
      console.log("--get--", res);
      console.log("---get--", res.data);
    })
  }

  doSort(field: string) {
    if (field === this.sortKey) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortKey = field;
      this.sortDirection = 'asc';
    }
  }


  onEnterKey(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.getDueLoans();
    }
  }

  get displayedColumns() {
    this.oColumnDragDrop = new ColumnDragDrop(this.columnUserPreference , this.STORAGE_KEY + this.TABLE_IDENTIFIER );
    return this.oColumnDragDrop.columnList.filter((o: any) => o.visible).map(o => o.field);
  }

  onLoanClick() {
    const formData = {
      filterForm: this.filterForm.value,
      search: this.search,
      byloanId: this.byloanId,
      byfullName: this.byfullName,
      bymobile: this.bymobile,
      byemail: this.byemail
    }
    this.loanService.saveFormState(formData);
    this.loanService.setLoanState(this.dataSource);
  }
  pageChange(objEvent) {
    let { page, flag, params } = objEvent;
    this.searchData.page = page;
    this._getDueLoans();  
  }

  openExcelStatementPopUp(content: any,d) {
    const searchInput = this.search && this.search.trim();
    if (searchInput) {
      this.downloadExcel(d);
    } else {
      this.modalService.open(content, { backdrop: 'static', centered: true });
    }
  }
  reset(e: Event) {
    e.preventDefault();
    this.filterForm.reset();
    this.advanceInputComponent.forEach((inputComponent: AdvanceInputComponent) => {
      inputComponent.clearInput();
    });
    this.search = '';
    this.queryField = '';
    this.getDueLoans();
  }

  persistColumnPreference() {
    localStorage.setItem(this.STORAGE_KEY + this.TABLE_IDENTIFIER, JSON.stringify(this.columnUserPreference));
  }
  resetExcelStatementForm(e: Event) {
    e.preventDefault();
    this.excelStatementForm.setValue({
      excelStartDate: null,
      excelEndDate: null
    });
  }

  closeFix(event, datePicker) {
    if (event.target.offsetParent == null)
      datePicker.close();
    else if (event.target.offsetParent.nodeName != 'NGB-DATEPICKER')
      datePicker.close();
  }

  downloadExcel(dismiss) {
    let params: any = {};

    if (this.search) params.q = this.search;
    if (this.search) params.qf = this.queryField;
    if (this.byfullName) params.fullName = this.byfullName;
    if (this.bymobile) params.mobile = this.bymobile;
    if (this.byemail) params.email = this.byemail;
    if (this.filterStartDate.value && Object.keys(this.filterStartDate.value).length) params.startDate
      = this.filterStartDate.value.year + '-' + this.filterStartDate.value.month + '-' + this.filterStartDate.value.day + ' 00:00:00';
    if (this.filterEndDate.value && Object.keys(this.filterEndDate.value).length) params.endDate
      = this.filterEndDate.value.year + '-' + this.filterEndDate.value.month + '-' + this.filterEndDate.value.day + ' 23:59:59';
    if (this.filterAmount && this.filterAmount.value) params.loanAmount = this.filterAmount.value;
    if (this.filterBranchId.value && this.filterBranchId.value.id) params.branch = this.filterBranchId.value.id;

    let diffDays: any = 0;
    if (this.excelStartDate && this.excelStartDate.value && this.excelEndDate && this.excelEndDate.value) {
      const excelStartDate = moment([this.excelStartDate.value.year, this.excelStartDate.value.month, this.excelStartDate.value.day]),
        excelEndDate = moment([this.excelEndDate.value.year, this.excelEndDate.value.month, this.excelEndDate.value.day]);
      params.startDate = this.excelStartDate.value.year + '-' + this.excelStartDate.value.month + '-' + this.excelStartDate.value.day + ' 00:00:00';
      params.endDate = this.excelEndDate.value.year + '-' + this.excelEndDate.value.month + '-' + this.excelEndDate.value.day + ' 23:59:59';
      diffDays = excelEndDate.diff(excelStartDate, 'days');
    }

    if (diffDays >= 93) {
      this.toasterService.pop('error', '','Start Date & End Date should not be more than 90 days');
    }
    else {
      this.loanService.downloadDueLoansExcel(params).subscribe(res => {
        let fileName = "DueReport.xlsx";
        downloadjs(res.body, fileName, res.body.type);
        this.toasterService.pop('success','', 'Data exported successfully.')
        this.excelStatementForm.reset();
        dismiss();
      });
    }
  }

}
