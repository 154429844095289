import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http/src/response';
import {Observable} from 'rxjs';

import { ConstantService } from '../../constant/constant.service';

@Injectable({
  providedIn: 'root'
})
export class LoanLODService {
  
  constructor(
    private http: HttpClient,
    private constantService: ConstantService
  ) {
  }


  getById(id: string) {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANLOD, [id]));
  }

  welcomeLetter(id) {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANLOD+`/${id}/welcomeletter`));
  }

  lodLetter(id) {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANLOD+`/${id}/lodletter`),{
        observe: 'response',
        responseType: 'blob'
    });
  }

  camLetter(id) {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANLOD+`/${id}/cams`),{
        observe: 'response',
        responseType: 'blob'
    });
  }
  sanctionLetter(id) {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANLOD+`/${id}/sanctionletter`),{
        observe: 'response',
        responseType: 'blob'
    });
  }

  uploadDoc(body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.LOANLOD}/upload`), body);
  }
  
  reUploadDoc(body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.LOANLOD}/reupload`), body);
  }

  signedurl(body) {
    return this.http.post(this.constantService.getUrl(this.constantService.LOANLOD+`/signedurl`),body);
  }

  maskAadhar(body) {
    return this.http.post(this.constantService.getUrl(this.constantService.LOANLOD+`/maskedaadhar`),body);
  }

  getFileData(body) {
    return this.http.post(this.constantService.getUrl(this.constantService.LOANLOD + '/filedata'), body);
  }
}
