import {Component, OnInit, EventEmitter, Output, Input, HostListener} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {animate, style, transition, trigger} from '@angular/animations';
import {ToasterService} from 'angular2-toaster';
import {Papa} from 'ngx-papaparse';
import {AccessCodeService} from '../../constant/accessCode.service';
import {PinCodeService} from '../../service/pin-code/pin-code.service';
import { ActivatedRoute } from '@angular/router';
import { ToasterAlertService } from '../../notification/toastAlert.service';
import { LoanService } from '../../service/loan/loan.service';
import { ApplicationService } from '../../service/application/application.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {

  @Input() public initial: any = 0;
  @Input() public header: any = null;
  @Input() public imageList: any = [];
  @Input() public downloadable: any = false;
  
  @Output() closeSideBarEvent = new EventEmitter();

  public accessCodeList = AccessCodeService;

  public currentImage: any = { index: 0, url: '', docPath: '', rotation: 0 };
  public s3Path: any;
  public s3PathSecure: any;
  public showFileLoader: any = false;
  
  constructor(
    private toasterService: ToasterAlertService,
    private papa: Papa,
    private sanitizer: DomSanitizer,
    private loanService: LoanService,
    private applicationService: ApplicationService
  ) {
  }

  ngOnInit() {
    let oThat = this;

    this.imageList = this.imageList.map( o =>{
      if(!o.url && o.docPath){
          this.loanService.getSignedUrl({ filename: o.docPath }).subscribe((res: any) => {
            o.url = res.data.url;
          });
      }
      o.rotation = o.rotation || 0;
      return o;
    });

    this.plusSlides(this.initial, true);

    document.onkeydown = function(evt : any) {
      evt = evt || window.event;
      var isEscape = false;
      if ("key" in evt) {
          isEscape = (evt.key === "Escape" || evt.key === "Esc");
      } else {
          isEscape = (evt.keyCode === 27);
      }
      if (isEscape) {
        oThat.closeSideBarEvent.emit();
      }
    };
  }

  plusSlides(index, init = false) {
    if (window.innerWidth < 600) {
      return false;
    }
    let newindex = this.currentImage.index || 0;
    if (init) {
      newindex = index;
    } else {
        newindex += index;
    }
    if (newindex < 0) {
        newindex = 0;
    } else if (newindex >= this.imageList.length) {
        newindex = this.imageList.length - 1;
    }

    this.currentImage = { index: newindex, ...this.imageList[newindex] };
    if(!this.currentImage.url && this.currentImage.docPath){
        this.loanService.getSignedUrl({ filename: this.currentImage.docPath }).subscribe((res: any) => {
          this.currentImage.url = res.data.url;
        });
    }
  }

  resizeImage(event, image) {
    image.style.width = event.target.value + "%";
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowRight') {
      this.plusSlides(1);
    } else if (event.key === 'ArrowLeft') {
      this.plusSlides(-1);
    }
  }

  rotateImage(direction: 'left' | 'right') {
    const rotationIncrement = direction === 'left' ? -90 : 90;
    this.currentImage.rotation = (this.currentImage.rotation + rotationIncrement) % 360;
  }


  openDocument() {
    // this.showFileLoader = true;
    // this.loanService.getSignedUrl({ filename: this.filePath }).subscribe((res: any) => {
    //   this.s3Path = res.data.url;
    //   if (['jpg', 'jpeg', 'png', 'wav', 'mp3', 'mp4', 'mid', 'mpeg', 'mpga', 'webm'].indexOf(this.getExtension) !== -1) {
      
    //   } else if (['pdf', 'doc', 'docs', 'docx','wav'].indexOf(this.getExtension) !== -1) {
    //     this.s3PathSecure = this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/viewer?url=${encodeURIComponent(this.s3Path)}&embedded=true`);
    //   }
    //   this.showFileLoader = false;
    // });
  }

  downloadDocument(s3Path) {
    // if(s3Path){
    //   this.loanService.getSignedUrl({ filename: s3Path }).subscribe((res: any) => {
    //     window.location = res.data.url;
    //   });
    // } else if(this.docUrlSource){
    //   const link = document.createElement('a');
    //   link.href = this.docUrlSource;
    //   link.target = '_blank';
    //   link.download = this.fileName + '.' + this.fileType.toLowerCase();
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // }
  }


  closeSideBar(){
    this.closeSideBarEvent.emit();
  }

}
