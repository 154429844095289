import { RouteInfo } from './sidebar.metadata';
import { AccessCodeService } from '../../shared-component/constant/accessCode.service';

export const ROUTES: RouteInfo[] = [
  {
    id: 'dashboardHeading',
    path: '',
    heading: true,
    title: 'Home',
    icon: '',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    count: null,
    countClass: {},
    accessCode: AccessCodeService.DASHBOARD,
    submenu: [{
      id: 'dashboard',
      path: '/dashboard',
      heading: false,
      title: 'Dashboard',
      icon: 'fa-home',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      count: null,
      countClass: {},
      accessCode: AccessCodeService.DASHBOARD_ALL,
      submenu: [{
        id: 'Enquiry',
        path: '/dashboard/enquiry',
        heading: false,
        title: 'Enquiry',
        icon: 'fa-tasks',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.DASHBOARD_ENQUIRY,
        count: null,
        countClass: {},
        submenu: []
      }]
    }]
  }, {
    id: 'leadHeading',
    path: '',
    heading: true,
    title: 'Lead',
    icon: 'fa-bullseye',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    accessCode: AccessCodeService.LEADS,
    count: null,
    countClass: {},
    submenu: [{
      id: 'Connector',
      path: '/leadconnectors',
      heading: false,
      title: 'Connectors',
      icon: 'fa-user-secret',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.LEADS_CONNECTOR,
      count: null,
      countClass: {},
      submenu: []
    }, {
      id: 'Enquiry',
      path: '/lead/enquiry',
      heading: false,
      title: 'Enquiries',
      icon: 'fa-info-circle',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.LEADS_ENQUIRY,
      count: null,
      countClass: {},
      submenu: []
    }, {
      id: 'Leads',
      path: '/lead',
      heading: false,
      title: 'Leads',
      icon: 'fa-bullseye',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.LEADS_VIEW_LIST,
      count: null,
      countClass: {},
      submenu: []
    }, {
      id: 'Prospect',
      path: '/lead/prospect',
      heading: false,
      title: 'Prospects',
      icon: 'fa-address-book',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.LEADS_PROSPECT,
      count: null,
      countClass: {},
      submenu: []
    }]
  }, {
    id: 'applicationHeading',
    path: '',
    heading: true,
    title: 'Application',
    icon: 'ti-layers',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    accessCode: AccessCodeService.APPLICATION,
    count: null,
    countClass: {},
    submenu: [
      {
        id: 'applicationNew',
        path: '/application/new',
        heading: false,
        title: 'New',
        icon: 'fa-plus-circle',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        count: null,
        countClass: {},
        accessCode: AccessCodeService.APP_NEW,
        submenu: []
      }, {
        id: 'applicationnAll',
        path: '/application',
        heading: false,
        title: 'All Applications',
        icon: 'fa-file-text',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.APP_ALL,
        count: null,
        countClass: { 'label-primary' : true },
        submenu: [{
          id: 'applicationStageBooking',
          path: '/application?status=new',
          heading: false,
          title: 'Draft Application',
          icon: 'fa-chevron-right',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          accessCode: AccessCodeService.APPLICATION_STAGE,
          count: 0,
          countClass: {'label-default' : true},
          submenu: []
        }, {
          id: 'applicationStageNewApplication',
          path: '/application?status=newapplication',
          heading: false,
          title: 'New Application',
          icon: 'fa-chevron-right',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          accessCode: AccessCodeService.APPLICATION_STAGE,
          count: 0,
          countClass: { 'label-primary' : true},
          submenu: []
        }, {
          id: 'applicationStageCreditReview',
          path: '/application?status=creditreview',
          heading: false,
          title: 'For Credit Review',
          icon: 'fa-chevron-right',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          accessCode: AccessCodeService.APPLICATION_STAGE,
          count: 0,
          countClass: { 'label-warning' : true},
          submenu: []
        }, {
          id: 'applicationStageSoftApproval',
          path: '/application?status=insoftapproval',
          heading: false,
          title: 'For Soft Approval',
          icon: 'fa-chevron-right',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          accessCode: AccessCodeService.APPLICATION_STAGE,
          count: 0,
          countClass: { 'label-success' : true},
          submenu: []
        }, {
          id: 'applicationStageFinApproval',
          path: '/application?status=finapproval',
          heading: false,
          title: 'For Fin. Approval',
          icon: 'fa-chevron-right',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          accessCode: AccessCodeService.APPLICATION_STAGE,
          count: 0,
          countClass: { 'label-success' : true},
          submenu: []
        }, {
          id: 'applicationStageInApproval',
          path: '/application?status=inapproval',
          heading: false,
          title: 'For Other Approvals',
          icon: 'fa-file-text',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          accessCode: AccessCodeService.APPLICATION_STAGE,
          count: 0,
          countClass: {'label-success' : true},
          submenu: [{
            id: 'applicationStageInApprovalRCU',
            path: '/application?status=rcuapproval',
            heading: false,
            title: 'For RCU',
            icon: 'fa-chevron-right',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.APPLICATION_STAGE,
            count: 0,
            countClass: {'label-success' : true},
            submenu: []
          },{
            id: 'applicationStageInApprovalTech',
            path: '/application?status=techapproval',
            heading: false,
            title: 'For Technical',
            icon: 'fa-chevron-right',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.APPLICATION_STAGE,
            count: 0,
            countClass: {'label-success' : true},
            submenu: []
          },{
            id: 'applicationStageInApprovalLegal',
            path: '/application?status=legalapproval',
            heading: false,
            title: 'For Legal',
            icon: 'fa-chevron-right',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.APPLICATION_STAGE,
            count: 0,
            countClass: {'label-success' : true},
            submenu: []
          },{
            id: 'applicationStageInApprovalComm',
            path: '/application?status=commercialapproval',
            heading: false,
            title: 'For Commercial',
            icon: 'fa-chevron-right',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.APPLICATION_STAGE,
            count: 0,
            countClass: {'label-success' : true},
            submenu: []
          }]
        }, {
          id: 'applicationStageFinalApproval',
          path: '/application?status=finalpproval',
          heading: false,
          title: 'For Final Approval',
          icon: 'fa-chevron-right',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          accessCode: AccessCodeService.APPLICATION_STAGE,
          count: 0,
          countClass: {'label-success' : true},
          submenu: []
        }, {
          id: 'applicationStageVerification',
          path: '/application?status=verification',
          heading: false,
          title: 'In Verification',
          icon: 'fa-chevron-right',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          accessCode: AccessCodeService.APPLICATION_STAGE,
          count: 0,
          countClass: { 'label-info' : true},
          submenu: []
        }, {
          id: 'applicationStageHold',
          path: '/application?status=hold',
          heading: false,
          title: 'On Hold',
          icon: 'fa-chevron-right',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          accessCode: [],
          count: 0,
          countClass: { 'label-danger' : true },
          submenu: []
        }, {
          id: 'applicationStageCancelled',
          path: '/application?status=cancelled',
          heading: false,
          title: 'Cancelled',
          icon: 'fa-chevron-right',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          accessCode: AccessCodeService.APPLICATION_STAGE,
          count: 0,
          countClass: { 'label-danger' : true },
          submenu: []
        }, {
          id: 'applicationStageRefused',
          path: '/application?status=refused',
          heading: false,
          title: 'Sales Refused',
          icon: 'fa-chevron-right',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          accessCode: AccessCodeService.APPLICATION_STAGE,
          count: 0,
          countClass: { 'label-danger' : true },
          submenu: []
        }, {
          id: 'applicationStageRejected',
          path: '/application?status=reject',
          heading: false,
          title: 'Credit Rejected',
          icon: 'fa-chevron-right',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          accessCode: AccessCodeService.APPLICATION_STAGE,
          count: 0,
          countClass: { 'label-danger' : true },
          submenu: []
        }, {
          id: 'applicationStageWithdraw',
          path: '/application?status=withdraw',
          heading: false,
          title: 'Withdraw',
          icon: 'fa-chevron-right',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          accessCode: AccessCodeService.APPLICATION_STAGE,
          count: 0,
          countClass: { 'label-danger' : true },
          submenu: []
        }]
      }
    ]
  }, {
    id: 'loanHeading',
    path: '',
    heading: true,
    title: 'Loans',
    icon: 'ti-layers',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    count: null,
    accessCode: AccessCodeService.LOAN,
    countClass: {},
    submenu: [
      {
        id: 'loanAll',
        path: '/loan',
        heading: false,
        title: 'All Loan',
        icon: 'fa-money',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.LOAN_ALL,
        count: null,
        countClass: { 'label-success' : true},
        submenu: [{
            id: 'loanVerificationBucket',
            path: '/loan?status=inprocess',
            heading: false,
            title: 'In Verification',
            icon: 'ti-layers',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.LOAN_BUCKET,
            count: 0,
            countClass: {'label-success' : true },
            submenu: []
          }, {
            id: 'loanonholdBucket',
            path: '/loan?status=onhold',
            heading: false,
            title: 'On Hold',
            icon: 'ti-layers',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.LOAN_BUCKET,
            count: 0,
            countClass: { 'label-warning' : true},
            submenu: []
          }, {
            id: 'loanVerifiedBucket',
            path: '/loan?status=verified',
            heading: false,
            title: 'Verified',
            icon: 'ti-layers',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.LOAN_BUCKET,
            count: 0,
            countClass: { 'label-info' : true},
            submenu: []
          }, {
            id: 'loanActiveBucket',
            path: '/loan?status=active',
            heading: false,
            title: 'Active',
            icon: 'ti-layers',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.LOAN_BUCKET,
            count: 0,
            countClass: { 'label-success' : true},
            submenu: []
          }, {
            id: 'loanCloseBucket',
            path: '/loan?status=closed',
            heading: false,
            title: 'Closed',
            icon: 'ti-layers',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.LOAN_BUCKET,
            count: 0,
            countClass: { 'label-danger' : true},
            submenu: []
          }, {
            id: 'loanRejectBucket',
            path: '/loan?status=rejected',
            heading: false,
            title: 'Cancel/Rejected',
            icon: 'ti-layers',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.LOAN_BUCKET,
            count: 0,
            countClass: { 'label-danger' : true},
            submenu: []
          }
        ]
      }
    ]
  }, {
    id: 'collectionHeading',
    path: '',
    heading: true,
    title: 'Collection',
    icon: 'ti-layers',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    count: null,
    countClass: {},
    accessCode: AccessCodeService.COLLECTION,
    submenu: [
       {
        id: 'duelist',
        path: '/collection/duelist',
        heading: false,
        title: 'Emi Due',
        icon: 'fa-money',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.COLLECT_DUELIST,
        count: null,
        countClass: {},
        submenu: []
      }, {
        id: 'emiPresentation',
        path: '/collection/presentatation',
        heading: false,
        title: 'Emi Presentation',
        icon: 'fa-inr',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.COLLECT_PRESENTATION,
        count: null,
        countClass: {},
        submenu: []
      }, {
        id: 'delinquent',
        path: '',
        heading: false,
        title: 'Delinquent',
        icon: 'fa-share-square-o',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        count: null,
        countClass: { 'label-danger' : true },
        accessCode: AccessCodeService.COLLECT_DELINQUENT,
        submenu: [
          {
            id: 'paymentFemi',
            path: '/collection?delinquent=femi',
            heading: false,
            title: 'Femi',
            icon: 'fa-users',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            count: 0,
            accessCode: AccessCodeService.COLLECT_DELINQUENT,
            countClass: { 'label-success' : true},
            submenu: []
          }, {
            id: 'paymentSemi',
            path: '/collection?delinquent=semi',
            heading: false,
            title: 'Semi',
            icon: 'ti-layers',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.COLLECT_DELINQUENT,

            count: 0,
            countClass: { 'label-primary' : true},
            submenu: []
          }, {
            id: 'delinquent30only',
            path: '/collection?delinquent=30days',
            heading: false,
            title: '< 30 Days',
            icon: 'ti-layers',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.COLLECT_DELINQUENT,
            count: 0,
            countClass: {'label-warning' : true},
            submenu: []
          }, {
            id: 'delinquent30',
            path: '/collection?delinquent=30plus',
            heading: false,
            title: '30 >=< 60 Days',
            icon: 'ti-layers',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.COLLECT_DELINQUENT,
            count: 0,
            countClass: {'label-warning' : true},
            submenu: []
          }, {
            id: 'delinquent60',
            path: '/collection?delinquent=60plus',
            heading: false,
            title: '60 >=< 90 Days',
            icon: 'ti-layers',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.COLLECT_DELINQUENT,
            count: 0,
            countClass: {'label-warning' : true},
            submenu: []
          }, {
            id: 'delinquent90',
            path: '/collection?delinquent=90plus',
            heading: false,
            title: '90 >=< 120 Days',
            icon: 'ti-layers',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.COLLECT_DELINQUENT,
            count: 0,
            countClass: {'label-danger' : true},
            submenu: []
          }, {
            id: 'delinquent120',
            path: '/collection?delinquent=120plus',
            heading: false,
            title: '120 >=< 150 Days',
            icon: 'ti-layers',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.COLLECT_DELINQUENT,
            count: 0,
            countClass: {'label-danger' : true},
            submenu: []
          }, {
            id: 'delinquent150',
            path: '/collection?delinquent=150plus',
            heading: false,
            title: '150 >=< 180 Days',
            icon: 'ti-layers',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.COLLECT_DELINQUENT,
            count: 0,
            countClass: {'label-danger' : true},
            submenu: []
          }, {
            id: 'delinquent180',
            path: '/collection?delinquent=180plus',
            heading: false,
            title: '180+ Days',
            icon: 'ti-layers',
            class: '',
            label: '',
            labelClass: '',
            extralink: false,
            accessCode: AccessCodeService.COLLECT_DELINQUENT,
            count: 0,
            countClass: {'label-danger' : true},
            submenu: []
          }
        ]
      }
    ]
  }, {
    id: 'reportHeading',
    path: '',
    heading: true,
    title: 'Reports',
    icon: 'ti-layers',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    accessCode: AccessCodeService.REPORTS,
    count: null,
    countClass: {},
    submenu: [
      {
        id: 'dueReport',
        path: '/reports/due',
        heading: false,
        title: 'Due Report',
        icon: 'fa-book',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.REPORTS_DUE,
        count: null,
        countClass: {},
        submenu: []
      },{
        id: 'paymentsdue',
        path: '/reports/paymentsdue',
        heading: false,
        title: 'Payment Report',
        icon: 'fa-book',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.REPORTS_DUE,
        count: null,
        countClass: {},
        submenu: []
      },
      {
        id: 'disbursementdue',
        path: '/reports/disbursementdue',
        heading: false,
        title: 'Disbursement Report',
        icon: 'fa-book',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.REPORTS_DUE,
        count: null,
        countClass: {},
        submenu: []
      },
       {
        id: 'collectionReport',
        path: '/reports/collection',
        heading: false,
        title: 'Collection Report',
        icon: 'fa-book',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.REPORTS_COLLECTION,
        count: null,
        countClass: {},
        submenu: []
      },
      {
        id: 'posReport',
        path: '/reports/pos',
        heading: false,
        title: 'POS Report',
        icon: 'fa-book',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.REPORTS_COLLECTION,
        count: null,
        countClass: {},
        submenu: []
      }
    ]
  }, {
    id: 'leadConnectors',
    path: '',
    heading: false,
    title: '',
    icon: 'ti-layers',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    accessCode: AccessCodeService.LEAD_CONNECTORS,
    count: null,
    countClass: {},
    submenu: [{
      id: ' ',
      path: '/customers',
      heading: false,
      title: 'Customers',
      icon: 'fa-user-circle',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.CUSTOMERS,
      count: null,
      countClass: {},
      submenu: []
    }, {
      id: 'leadconnectors',
      path: '/leadconnectors',
      heading: false,
      title: 'Lead Connectors',
      icon: 'fa-chain',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.LEAD_CONNECTORS,
      count: null,
      countClass: {},
      submenu: []
    }, {
      id: 'vendors',
      path: '/vendors',
      heading: false,
      title: 'Vendors',
      icon: 'fa-handshake-o',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.VENDORS,
      count: null,
      countClass: {},
      submenu: []
    }]
  }, {
    id: 'adminMenu',
    path: '',
    heading: true,
    title: 'Admin',
    icon: 'ti-layers',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    accessCode: AccessCodeService.ADMIN,
    count: null,
    countClass: {},
    submenu: [{
      id: 'masterData',
      path: '/masterdata/paymentmaster',
      heading: false,
      title: 'Master Data',
      icon: 'fa-th-large',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.ADM_MASTERDATA,
      count: null,
      countClass: {},
      submenu: [{
        id: 'paymentMaster',
        path: '/masterdata/paymentmaster',
        heading: false,
        title: 'Payment Master',
        icon: 'fa-money',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.ADM_MASTERDATA_PAYMENTMASTER,
        count: null,
        countClass: {},
        submenu: []
      }, {
        id: 'checklistMaster',
        path: '/masterdata/checklist',
        heading: false,
        title: 'Check List',
        icon: 'fa-th-list',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.ADM_CHECKLIST,
        count: null,
        countClass: {},
        submenu: []
      }, {
        id: 'approvalmatrix',
        path: '/masterdata/approvalmatrix',
        heading: false,
        title: 'Approval Matrix',
        icon: 'fa-thumbs-up',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.ADM_APPROVAL_MATRIX,
        count: null,
        countClass: {},
        submenu: []
      }, {
        id: 'deviationmatrix',
        path: '/masterdata/deviationmatrix',
        heading: false,
        title: 'Deviation Matrix',
        icon: 'fa-deviantart',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.ADM_DEVIATION_MATRIX,
        count: null,
        countClass: {},
        submenu: []
      }, {
        id: 'templates',
        path: '/masterdata/templates',
        heading: false,
        title: 'Templates',
        icon: 'fa-file-text-o',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.ADM_TEMPLATE,
        count: null,
        countClass: {},
        submenu: []
      }, {
        id: 'lodmaster',
        path: '/masterdata/lodmaster',
        heading: false,
        title: 'LOD Master',
        icon: 'fa-solid fa-file',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.ADM_LOD_MASTER,
        count: null,
        countClass: {},
        submenu: []
      }, {
        id: 'colendingschema',
        path: '/masterdata/colendingschema',
        heading: false,
        title: 'Colending Schema',
        icon: 'fa-handshake-o',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.ADM_COLENDING_SCHEMA,
        count: null,
        countClass: {},
        submenu: []
      }, {
        id: 'pincodes',
        path: '/masterdata/pincodes',
        heading: false,
        title: 'Pincode',
        icon: 'fa-location-arrow',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.ADM_PINCODE,
        count: null,
        countClass: {},
        submenu: []
      },{
        id: 'ifsccodes',
        path: '/masterdata/ifsccodes',
        heading: false,
        title: 'IFSC',
        icon: 'fa-university',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.ADM_PINCODE,
        count: null,
        countClass: {},
        submenu: []
      },{
        id: 'regions',
        path: '/masterdata/region',
        heading: false,
        title: 'Region',
        icon: 'fa-map',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.ADM_PINCODE,
        count: null,
        countClass: {},
        submenu: []
      },{
        id: 'slno',
        path: '/masterdata/slno',
        heading: false,
        title: 'Num. Serializer',
        icon: 'fa-list-ol',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        accessCode: AccessCodeService.ADM_PINCODE,
        count: null,
        countClass: {},
        submenu: []
      }]
    }, {
      id: 'branches',
      path: '/branches',
      heading: false,
      title: 'Branches',
      icon: 'fa-cubes',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.ADM_BRANCHES,
      count: null,
      countClass: {},
      submenu: []
    }, {
      id: 'settings',
      path: '/settings',
      heading: false,
      title: 'Settings',
      icon: 'fa-gear',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.ADM_SETTINGS,
      count: null,
      countClass: {},
      submenu: []
    }, {
      id: 'notitications',
      path: '/notifications',
      heading: false,
      title: 'Notifications',
      icon: 'fa-bullhorn',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.ADM_NOTIFICATION,
      count: null,
      countClass: {},
      submenu: []
    }, {
      id: 'apiUsers',
      path: '/apiuser',
      heading: false,
      title: 'API Users',
      icon: 'fa-users',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.ADM_API_USERS,
      count: null,
      countClass: {},
      submenu: []
    }, {
      id: 'colender',
      path: '/colender',
      heading: false,
      title: 'Co-Lender',
      icon: 'fa-handshake-o',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.ADM_CO_LENDER,
      count: null,
      countClass: {},
      submenu: []
    }]
  }, {
    id: 'employeeMenu',
    path: '',
    heading: true,
    title: 'Employee',
    icon: 'fa-users',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    accessCode: AccessCodeService.EMPLOYEE,
    count: null,
    countClass: {},
    submenu: [{
      id: 'employees',
      path: '/employees',
      heading: false,
      title: 'Employee',
      icon: 'fa-users',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.EMPLOYEE_ALL,
      count: null,
      countClass: {},
      submenu: []
    }, {
      id: 'profile',
      path: '/profile',
      heading: false,
      title: 'Profile',
      icon: 'fa-id-badge',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.EMPLOYEE_PROFILE,
      count: null,
      countClass: {},
      submenu: []
    }]
  }, {
    id: 'rbacMenu',
    path: '',
    heading: true,
    title: 'RBAC',
    icon: 'ti-layers',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    accessCode: AccessCodeService.RBAC,
    count: null,
    countClass: {},
    submenu: [{
      id: 'modules',
      path: '/rbac/modules',
      heading: false,
      title: 'Modules',
      icon: 'fa-sitemap',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.RBAC_MODULE,
      count: null,
      countClass: {},
      submenu: []
    }, {
      id: 'roles',
      path: '/rbac/roles',
      heading: false,
      title: 'Roles',
      icon: 'fa-cubes',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.RBAC_ROLE,
      count: null,
      countClass: {},
      submenu: []
    }, {
      id: 'access',
      path: '/rbac/permissions',
      heading: false,
      title: 'Access',
      icon: 'fa-shield',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.RBAC_ACCESS,
      count: null,
      countClass: {},
      submenu: []
    }]
  }, {
    id: 'manualActivityMenu',
    path: '',
    heading: false,
    title: 'Manual Activity1',
    icon: 'ti-layers',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    accessCode: AccessCodeService.MANUAL_ACTIVITY,
    count: null,
    countClass: {},
    submenu: [{
      id: 'manualActivity',
      path: '/manual-activity',
      heading: false,
      title: 'Manual Activity',
      icon: 'fa-exclamation-triangle',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.MANUAL_ACTIVITY,
      count: null,
      countClass: {},
      submenu: []
    }]
  }, {
    id: 'ticketMenu',
    path: '',
    heading: false,
    title: '',
    icon: 'ti-layers',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    accessCode: AccessCodeService.TICKETS,
    count: null,
    countClass: {},
    submenu: [{
      id: 'ticket',
      path: '/customer-ticket',
      heading: false,
      title: 'Customer Ticket',
      icon: 'fa-ticket',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.TICKETS_CUSTOMER,
      count: null,
      countClass: {},
      submenu: []
    }]
  }, {
    id: 'adminMenu',
    path: '',
    heading: true,
    title: '',
    icon: '',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    accessCode: AccessCodeService.LEADS,
    count: null,
    countClass: {},
    submenu: [{
      id: "",
      path: '',
      heading: false,
      title: '',
      icon: '',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      accessCode: AccessCodeService.LEADS,
      count: null,
      countClass: {},
      submenu: []
    }]
  },
];