export const environment = {
  production: false,
  API_URL: 'https://uatmsmeapi.techfino.in/api/v1/',
  //API_URL: 'http://localhost:8000/api/v1/',
  OI_path: 'https://s3.ap-south-1.amazonaws.com/techfino/coi/',
  TERMS_AND_CONDITIONS_PATH: 'https://s3.ap-south-1.amazonaws.com/techfino/TnC/',
  WELCOME_LETTER_PATH: 'https://s3.ap-south-1.amazonaws.com/techfino/Welcome_schedule/',
  COMPANY_NAME: 'Techfino Capital Private Limited',
  itemsPerPage: 25,
  minTicketSize: 5000,
  gMapKey : 'AIzaSyD6dGFOGQ4F4FfnVBLcgAlo8fSjlmwvJtc',
  GET_PUBLIC_IP_URL : 'https://api.ipify.org?format=json',
  BITLY_HOST_URL : "https://bitly.techfino.in?",
  OFFSET : "660"
};