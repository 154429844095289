import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';

import {ConstantService} from '../../constant/constant.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentlinkService {

  constructor(
    private http: HttpClient,
    private constantService: ConstantService
  ) {
  }

  private _items: any = {};
  
  setItem(item: any) {
    this._items = item;
  }

  getItems() {
    return this._items;
  }
    
  get(params) {
    return this.http.get(this.constantService.getUrl(this.constantService.PAYMENTLINK), {
      params: params,
      observe: 'response'
    }).map((resp: any) => {
      let count = resp.headers.get('X-Total-Count');
      return { count: parseInt(count), data: resp.body.data };
    });
  }
  getDisbursement(params) {
    return this.http.get(this.constantService.getUrl(this.constantService.PAYMENTLINK+"/disbursementlinks"), {
      params: params,
      observe: 'response'
    }).map((resp: any) => {
      let count = resp.headers.get('X-Total-Count');
      return { count: parseInt(count), data: resp.body.data };
    });
  }

  getById(id: string) {
    return this.http.get(this.constantService.getUrl(this.constantService.PAYMENTLINK, [id]));
  }

  bulk(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.PAYMENTLINK+"/generatebulk"), { links : body } );
  }

  post(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.PAYMENTLINK), body );
  }

  put(id: string, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.PAYMENTLINK, [id]), body);
  }

  deleteLink(id: number) {
    return this.http.delete(this.constantService.getUrl(`${this.constantService.PAYMENTLINK}/${id}`));
  }

  capturePaymentStatus(params : any ) {
    return this.http.post(this.constantService.getUrl(this.constantService.PAYMENTLINK+"/capture"), params);
  }

  captureAllPayment( ) {
    return this.http.post(this.constantService.getUrl(this.constantService.PAYMENTLINK+"/captureall"), {});
  }

  cancel(params : any ) {
    return this.http.post(this.constantService.getUrl(this.constantService.PAYMENTLINK+"/cancel"), params);
  }

  capturePaymentIds(params : any ) {
    return this.http.post(this.constantService.getUrl(this.constantService.PAYMENTLINK+"/capturepaymetnids"), params);
  }

  collectedBy(params : any ) {
    return this.http.post(this.constantService.getUrl(this.constantService.PAYMENTLINK+"/collectedby"), params);
  }

  downloadExcel(params?: any): Observable<HttpResponse<Blob>> {
    return this.http.get(this.constantService.getUrl(this.constantService.PAYMENTLINK + '/download/payCollection/excel'), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }
  downloadDisExcel(params?: any): Observable<HttpResponse<Blob>> {
    return this.http.get(this.constantService.getUrl(this.constantService.PAYMENTLINK + '/download/disbursementlinks/excel'), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }

  markPaid(body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.PAYMENTLINK}/markpaid`), body);
  }

  uploadDoc(body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.PAYMENTLINK}/upload`), body);
  }

  getSignedUrl(body) {
    return this.http.post(this.constantService.getUrl(this.constantService.PAYMENTLINK + '/signedurl'), body);
  }



}
