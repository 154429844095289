import { Action } from "rxjs/internal/scheduler/Action";

export const LOAN_TENURES = [12, 15, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132, 144, 156, 168, 180, 192, 204, 216, 228, 240, 252, 264, 276, 288, 300]

export const CREDIT_REFUSES_TAGS = {
    OO_AGE_LIMIT: "Age (< 21 and > 59)",
    LOW_INCOME: "Low Income",
    NEGATIVE_LOCATION: "Negative Location",
    WEAK_PROFILE: "Weak Profile",
    LOW_BUREAU: "Low Bureau",
    DEFAULTER: "Defaulter Profile"
};

export const SALES_REFUSES_TAGS = {
    DROP_BY_PARTNER: "Dropped by partner",
    DROP_BY_CUSTOMER: "Dropped by customer",
    WEAK_PROFILE: "Weak Profile"
}

export const APPLICATION_STATUS = {
    DRAF: 0,
    NEW_APPLICATION: 1,
    CREDIT_REVIEW: 2,
    SUBMIT_FOR_SOFT_APPROVAL: 3,
    SOFT_APPROVED: 4,
    FINANCIAL_APPROVAL: 5,
    APPROVED: 6,
    UNDER_VERIFICATION: 7,
    PROCESSED: 8,
    REJECTED: 9,
    REFUSED: 10,
    CANCELED: 11 
}

export const LOAN_STATUS = {
    INPROCESS : 0,
    VERIFIED : 1,
    ACTIVE : 2,
    CLOSED : 3,
    PRECLOSED : 4,
    CANCELLED : 5,
    REJECTED : 6,
    ONHOLD : 7  
}

export const APPLICATION_APPROVAL_ACTION = {
    Upgraded: 1,
    Accept: 2,
    Approved: 3,
    FinApproved: 4,
    TechApproved: 5,
    LegalApproved: 6,
    FinalApproved: 7,
    Verified: 8,
    Rejected: 11,
    SalesRefused: 12,
    CreditRefused: 13,
    CanceledRefused: 14,
    CancelInitiated: 15,
    CancelApproved: 16,
    CloserInitiated: 17,
    CloserApproved: 18,
    RCUApproved: 19,
    CommercialApproved: 20,
    CloserRefused: 21
}

export const ACTION_STATUS = {
    Initiated: 1,
    Approved: 2,
    Refused: 3,
    Processed: 4
}

export const APPLICATION_STATUS_LIST = {
    0 : "DRAF",
    1 : "NEW APPLICATION",
    2 : "CREDIT REVIEW",
    3 : "SUBMIT FOR APPROVAL",
    4 : "FINANACIAL APPROVAL",
    5 : "IN APPROVAL",
    6 : "FINAL APPROVAL",
    7 : "UNDER VERIFICATION",
    8 : "PROCESSED",
    9 : "REJECTED",
    10: "REFUSED",
    11: "CANCELED",
    12: "WITHDRAW",
    13: "ONHOLD"
}

export const LOAN_STATUS_LIST = {
    0: "IN PROCESS",
    1: "VERIFIED",
    2: "ACTIVE",
    3: "CLOSED",
    4: "PRECLOSED",
    5: "CANCELLED",
    6: "REJECTED",
    7: "ON HOLD"    
}


export const LEAD_STATUS_LIST = {
    0: "IN PROCESS",
    1: "VERIFIED",
    2: "ACTIVE",
    3: "CLOSED",
    4: "PRECLOSED",
    5: "CANCELLED",
    6: "REJECTED",
    7: "ON HOLD",    
    8: "COMPLETED"    
}
export const LOAN_SUBSTATUS_LIST = {
    0: "YET TO VERIFY",
    1: "YET TO DISBURSE",
    2: "DISBURSED",
    3: "DELINQUENT",
    4: "PRE CLSOED",
    5: "POST CANCELLED",
    6: "INITIATED"
}

export const LOAN_TYPE = {
    1: "Loan Against Property",
    2: "LAP Balance Transfer",
    3: "Home Loan",
    4: "HL Balance Transfer",
    5: "Unsecured Business Loan",
    6: "Unsecured Personal Loan",
    7: "Topup Loan"
}

export const LOAN_USAGE = {
    1: "Builder Purchase",
    2: "Resale",
    3: "Plot Purchase",
    4: "Plot Purchase + Self Construction",
    5: "Self-Construction",
    6: "Business Expansion",
    7: "Working Capital Requirement",
    8: "Renovation/House Improvement",
    9: "Purchase of Office/Shop/Factory Premises",
    10: "Debt Consolidation",
    11: "Balance Transfer + Top Up",
    12: "Top Up",
    13: "Other"
}

export const ZONE_TYPE = {
    1 : "North",
    2 : "South",
    3 : "East",
    4 : "West"
}

export const YESNO_STATUS_TYPE = {
    1: "Yes",
    0: "No"
}
export const STATUS_TYPE = {
    1: "Active",
    0: "Inactive"
}

export const TRANSACTION_TYPE = {
    1: "Debit",
    0: "Credit"
}

export const DOC_COPY_TYPE = {
    1: "Original Doc",
    2: "Photo Copy",
    3: "True Copy",
    4: "Certificate",
    5: "Cabon Copy",
    99: "Other"
}

export const LOD_DOC_STATUS_TYPE = {
    1: "Yet To Received",
    2: "Received",
    3: "Requested to Release",
    4: "Relesed",
    99: "Other"
}

export const SANCTION_COND_STATUS_TYPE = {
    1: "New",
    2: "Verified",
    3: "Confirmed",
    4: "Not Required",
    5: "Not Completed"
}

export const SANCTION_COND_TYPE = {
    1: "OTC",
    2: "PDD"
}

export const TRANSACTION_CAT_LIST = {
    1: "PF Due",
    2: "Disbursement Pay",
    3: "Part Payment",
    4: "Repayment Emi Due",
    5: "Foreclosure Charge",
    6: "Foreclosure Payable",
    7: "Charges Due",
    8: "Interest Due",
    9: "MOF",
    10: "Document Charge"
}

export const VENDOR_CLASSIFICATION_TYPE = {
    1: "RCU",
    2: "Legal",
    3: "Technical",
    4: "Finance",
    99: "Other",
}

export const SPOC_COMMUNICATION_TYPE = {
    1: "Process",
    2: "Legal",
    3: "Technical",
    4: "Finance",
    99: "Other",
}

export const COMMUNICATION_MODE_TYPE = {
    1: "Email",
    2: "SMS",
    3: "Both",
    99: "Other",
}

export const ENABLED_TYPE = {
    1: "Enabled",
    0: "Disabled"
}

export const ROI_TYPE = {
    1: "Fixed",
    2: "Flexi"
}

export const DOCUMENT_TYPE = {
    "00": "Select Document",
    "01": "Sale Deed",
    "02": "Rental Aggrement",
    "03": "HR Letter",
    "04": "Form 16",
    "05": "ITR",
    "06": "Latest Utility Bill",
    "07": "Latest 7/12",
    "08": "Udhyam Aadhar",
    "10": "Bank Statement",
    "11": "GST Certificate",
    "12": "Properitory Certificate",
    "13": "Patnership Deed",
    "14": "Copy of Plan",
    "15": "Salary Slips",
    "99": "Other"
}

export const DOCUMENT_CATEGORY = {
    1: "Application Documents",
    2: "Colleteral Documents",
    3: "Identity Documents",
    4: "KYC Documents",
    5: "OSV Documents",
    6: "Financy Approval Documents",
    7: "Technical Approval Documents",
    8: "Legal Approval Documents",
    9: "Final Approval Documents",
    10: "Disbursal Documments",
    11: "Reccomendation Documments",
    12: "Obligation Documents",
    13: "PD Photos",
    14: "PD Videos",
    15: "PD Audios",
    16: "Agreement Signing Photo",
    17: "RCUApproved Documents",
    18: "Applicant Picuture"
}

export const BANK_HOLDER_TYPE = {
    1: "Applicant",
    2: "Co-applicant",
    3: "Guarantor",
    4: "BT Bank",
    5: "BT Third Party"
}

export const BANK_ACCOUNT_TYPE = {
    1: "Saving",
    2: "Current",
    3: "Over Draft",
    4: "Cash Credit"
}

export const COLENDER_RATE_ENTITY_TYPE = {
    1 : "Processing Fees",
    2 : "Login Fee",
    3 : "CERSAI Charges",
    4 : "DSRA",
    5 : "IMD Charges",
    6 : "Pre-Emi Charges",
    7 : "Documentation Charges",
    8 : "Valuation Charges",
    9 : "Title Opinion Charges",
    10 : "Life Insurance",
    11 : "Property Insurance",
    12 : "Health Insurance",
    13 : "General Insurance",
    14 : "Other Insurance",
    99 : "Other"
}

export const PROPERTY_OWNED = {
    0 : "None",
    1 : "Residence",
    2 : "Commercial",
    3 : "Both Residence & Commercial"
}

export const PROFILE_CATEGORY = {
    100 : "NA",
    101 : "Milk / Dairy with Less than 10 Cows",
    102 : "Transporter Bus or Truck", 
    103 : "Police / Home Guard / Security Firm Staff", 
    104 : "Astrologer/ Marriage Consultant / Pandit", 
    105 : "Carpenter / Maison / Plumber", 
    106 : "Unani Practitioner", 
    107 : "Placement / Immigration Consultant", 
    108 : "Tour Guide",
    109 : "Labour Contractors",
    201 : "Spa / Massage Parlour", 
    202 : "Money Lender / Pawn Brokers",
    203 : "Bar", 
    204 : "Journalist", 
    205 : "Advocate", 
    206 : "Brick Kiln manufacturer", 
    207 : "Private Taxi / Rickshaw Drivers", 
    208 : "Stockbrokers", 
    209 : "Property Brokers", 
    210 : "Builders / Developers", 
    211 : "Direct Politically Exposed Persons", 
    212 : "Small Diamond Trader without Formal Office Setup", 
    213 : "Butchers", 
    214 : "Animal Traders",
    999 : "Other"
}

export const CONSTITUTION_TYPE = {
    // 0: "Not Applicable",
    // 1: "Student",
    // 2: "Home Maker",
    // 3: "Salaried",
    4: "Individual",
    5: "Proprietorship",
    6: "Partnership",
    // 7: "Retired",
    // 9: "Other",
    10: "Pvt. Ltd company"
}

export const BUSNESS_TYPE = {
    1: "Services",
    2: "Manufacturing",
    3: "Trading",
    4: "Retail",
    99: "Others"
}

export const DISCREPANCY_TYPE = {
    1: "INITIATED",
    2: "ACT",
    3: "VERIFIED",
    4: "DEACTIVATE",
    99: "Others"
}

export const ORGANIZATION_TYPE = {
    0: "Individual",
    1: "Properitor",
    2: "Partnership",
    3: "LLP"
}

export const PROPERTY_TYPE = {
    0: "Residential",
    1: "Commercial",
    2: "Industrial",
    3: "Plot",
    4: "Mixed Usage"
}

export const PROPERTY_STATUS_TYPE = {
    0: "Rented",
    1: "Self-Occupied",
    2: "Vacant",
    99: "Other"
}

export const EMPLOYMENT_TYPE = {
    1: "Full-Time",
    2: "Part-Time",
    3: "Casual",
    4: "Contract Base",
    5: "Apprenticeship",
    6: "Traineeship/Internship",
    7: "Employment on Commission",
    8: "Self Employed"
}
export const RELIGIOUS_TYPE = {
    0: "NA",
    1: "Hindu",
    2: "Muslim",
    3: "Christian",
    4: "Contract Base",
    5: "Sikh",
    6: "Buddhist",
    7: "Jain",
    8: "Other Religion"
}
export const CAST_TYPE = {
    0: "NA",
    1: "SC",
    2: "ST",
    3: "GENERAL",
    4: "Others"
}

export const GENDER = {
    1: "Male",
    0: "Female",
    2: "Other",
    9: "Not Disclosed"
}

export const MARITAL_STATUS = {
    0: "Single",
    1: "Married",
    2: "Divorce",
    3: "Widow",
    9: "Not Disclosed"
}

export const PROPERTY_OWNERS = {
    1: "Self",
    2: "Spouse",
    3: "Mother",
    4: "Father",
    5: "Son",
    6: "Brother and Staying/Business togather",
    99: "Not Disclosed"
}

export const EDUCATION_QUALIFICATION = {
    0: "No formal education",
    1: "Primary education",
    2: "Secondary education or high school",
    3: "Vocational qualification ",
    4: "Bachelor's degree",
    5: "Master's degree",
    6: "Doctorate or higher",
    7: "Not Disclosed",
    99: "Other"
}

export const IDPROOF_TYPES = {
    0: "None",
    1: "PAN Card",
    2: "Election Commission ID Card",
    3: "Aadhar Card",
    4: "Driving License",
    5: "Passport",
    6: "Ration Card",
    99: "Other",
}

export const OSV_DOC_TYPE = {
    0: "None",
    "01": "Applicant Pic",
    "02": "Property Pic",
    "03": "Applicant Documents",
    "04": "Property Documents",
    "05": "Aadhar",
    "06": "Voter ID",
    "07": "Passport",
    "08": "Ration Card",
    "09": "Driving License",
    "10": "PAN Card",
    "99": "Other",
}

export const DOC_STATUS_TYPE = {
    0 : "New",
    1 : "Approved",
    2 : "Rejected",
    3 : "Expired",
    4 : "Cancelled",
    5 : "Not Required"
}

export const PROP_PIC_TYPE = {
    1: "House Frontage with Address Board if any",
    2: "Kitchen Photo",
    3: "Street View of Residence",
    4: "Selfie with Any Applicant in Drawing Room/Living Room/House Frontage",
    99: "Other"
}
export const BUSINESS_PHOTO_TYPE = {
    1: "Busienss/Shope Frontage with Address Board if any",
    2: "Office Photo",
    3: "Street View of Business/Shop",
    4: "Selfie with Any Applicant in Business/Shop Frontage",
    99: "Other"
}

export const PAYMENTS_TYPE = {
    1: "Processing Fees",
    2: "Login Fee",
    3: "CERSAI Charges",
    4: "DSRA",
    5: "IMD Charges",
    6: "Pre-Emi Charges",
    7: "Documentation Charges",
    8: "Valuation Charges",
    9: "Title Opinion Charges",
    99: "Other"
}

export const PAYMENT_MODE_TYPE = {
    1: "Online",
    2: "Cheque",
    3: "DD",
    4: "Cash"
}

export const PAYMENT_STATUS_TYPE = {
    0: "New",
    1: "Paid",
    2: "Failed",
    3: "Cancel",
    4: "Refunded",
    5: "Wave Off"
}

export const EMI_PAYMENT_STATUS = {
    1 : "Pending",
    2 : "Due",
    3 : "Overdue",
    4 : "Partial Paid",
    5 : "Paid",
    6 : "Foreclose Adjusted",
    7 : "Cancelation Adjusted",
    8 : "Reschedule Adjusted",
    9 : "Cancelled",
    10 : "Refunded"
}

export const LENDING_PARTNERS = {
    1: "TechFino Capital Pvt. Ltd.",
    2: "Godrej Finance Limited",
    3: "Bajaj Finance Limited",
    99: "Others"
}

export const DISPOSITION = {
    1: "Hot (Within 1 month)",
    2: "Warm (Within 2 month)",
    3: "Cold (Within 3 month)",
    4: "Frozen (Above 3 months)"
}

export const LEAD_STATUS = {
    1: "New",
    2: "In Progress",
    3: "Processed",
    4: "Rejected",
    5: "Frozen"
}

export const LEAD_SUBSTATUS = {
    1 : { 
        value :"New", 
        substatus : {
            11 : "No Action"
        }
    },
    2 : { 
        value : "In Progress",
        substatus : {
            21 : "Follow Up",
            22 : "Document Pending",
            23 : "Call Ringing No Response",
            24 : "Not Reachable"
          }
    },
    3 : { 
        value : "Push to LOS",
        substatus : {
            31 : "Created",
            32 : "Logged In Complete",
            33 : "Credit Review Inprogress",
            34 : "Sanction",
            35 : "Disbursed",
            36 : "Closed",
            37 : "Disbursal On Hold",
            38 : "Loan Cancelled"
        }
    },
    4 : {
        value : "Rejected",
        substatus : {
            41 : "Cust Not Eligible",
            42 : "Cust Not Interested",
            43 : "Duplicate",
            44 : "Beyond Approved Region",
            45 : "Wrong Number",
            46 : "LOS Rejected" 
        }
    },
    5 : {
        value : "Frozen",
        substatus : {
            51 : "Follow Up",
            52 : "Cust Not Interested Now"
        }
    }
}

export const STATUS = {
    1: "Doable",
    2: "Not-Doable",
    3: "Not Interested for Loan",
    // 4: "Fake",
    // 5: "Wrong Number",
    // 6: "RM Not met",
    // 7: "Not Eligible"
}

export const APPLICANT_RELATIONSHIP_TYPE = {
    "00": "None",
    "01": "Husband",
    "02": "Wife",
    "03": "Father",
    "04": "Mother",
    "05": "Brother",
    "06": "Sister",
    "07": "Son",
    "08": "Daughter",
    "09": "Cousin-Brother",
    "10": "Cousin-Sister",
    "11": "Father-in-law",
    "12": "Mother-in-law",
    "13": "Brother-in-law",
    "14": "Sister-in-law",
    "15": "Business Patner",
    "16": "Spouse",
    "17": "Son-in-law",
    "18": "Daughter-in-law",
    "19": "Uncle",
    "20": "Aunty",
    "21": "Others"
}

export const CHARGE_TYPES = {
    0: "Fixed",
    1: "Variable"
}

export const CREDIT_TYPES = {
    0: "Payable",
    1: "Receivable"
};

export const CREDIT_FROM_TYPES = {
    0: "Customer",
    1: "Lender"
};

export const MANDATORY_TYPES = {
    0: "No",
    1: "Yes"
};

export const DEVIATION_GROUPS = {
    1: "Collateral Deviations Technical",
    2: "Collateral Deviations Legal",
    3: "Collateral Deviations Paper Quality",
    4: "Income/Financial Deviation",
    5: "Balance Transfer Deviation",
    6: "Other"
};

export const APPROVAL_GROUPS = {
    1: "Basic",
    2: "Credit",
    3: "Higher"
};

export const APPROVAL_LEVELS = {
    "L1": "Level 1",
    "L2": "Level 2",
    "L3": "Level 3",
    "L4": "Level 4",
    "L5": "Level 5",
    "L6": "Level 6",
    "L7": "Level 7",
    "L8": "Level 8",
    "L9": "Level 9",
    "L10": "Level 10"
};

export const EMP_ROLE_TYPE = {
    1: "Sales",
    2: "Credit Basic",
    3: "Credit Manager",
    4: "Operation",
    5: "Operation Manager",
    6: "Branch Manager",
    7: "Regional Office Head",
    9: "Higher Authority",
    10: "Admin",
    11: "Super Admin",
}

export const DATA_ACCESS_LEVEL_TYPE = {
    0: "All Data",
    1: "Own Data",
    2: "Team Data",
    3: "Branch Data",
    4: "Cluster Data"
}

export const INSURANCE_TYPE = {
    1: "Health Insurance",
    2: "Property Insurance",
    3: "Life Insurance",
    4: "Term Insurance"
}

export const COVERAGE_TYPE = {
    1: "CI+PA",
    2: "CI+PA+EMI"
}

export const TENURE_LIST = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5
}

export const TRANCH_DISBURSMENT_TYPE = {
    1: "Fixed",
    2: "On Request",
}


export const DISBURSMENT_STATUS_TYPE = {
    0: "Yet To Disburse",
    1: "Requested",
    2: "Disbursed",
    3: "On Hold",
    4: "Cancelled",
    5: "Rejected",
    6: "Not Relevant"
}

export const DISBURSAL_ACCOUNT_TYPE = {
    1: "Applicant",
    2: "Co-Applicant",
    3: "BT Account",
    4: "Third Party",
    9: "Other"
}

export const CREDITRECOMMENDATION_TYPE = {
    1: "Approved",
    2: "Rejected",
    3: "Review"
}


export const MANDATE_PARTNER_TYPE = {
    1: "RAZORPAY",
    2: "DIGIO API",
    3: "Nupay",
    4: "UPI"
}

export const MANDATE_TYPE = {
    1: "Enach",
    2: "Paper Nach",
    3: "UPI"
}

export const LEAD_SOURCE_TYPE = {
    1: "Customer Reference",
    2: "Direct Visit",
    3: "Existing Data",
    4: "Internal Reference",
    9: "Other"
}

export const PD_TYPE = {
    "familyPD": 1,
    "employementPD": 2,
    "businessPD": 3,
    "incomePD": 4,
    "collateralPD": 5,
    "bureauReportPD": 6,
    "bankingPD": 7,
    "riskPD": 8,
    "endUse": 9,
    "policyDeviation": 10,
    "recommendation": 11,
    "sanctionCondition": 12,
    "otherPD": 99
}

export const COMPANY_TYPE_TYPES = {
    1 : "Banking",
    2 : "Non Banking",
    3 : "Finance Company",
    4 : "Hosing Finance Company",
    9 : "Other"
}

export const FINANCIAL_ENTITY_TYPES = {
    1 : "Banking",
    2 : "Non Banking",
    9 : "Other"
}

export const COLLECTION_MODE_TYPE = {
    1 : "Emandate",
    2 : "Gateway",
    3 : "Payment Link",
    4 : "Bank Transfer",
    5 : "Cheque Payment",
    6 : "Cash Payment",
    9 : "Other"
}

export const AGRI_LAND_STATUS_TYPE = {
    1: "Not Available",
    2: "Available- without Attachment",
    3: "Available- with Attachment"
}

export const UDYAM_STATUS_TYPE = {
    1: "Not Applicable",
    2: "Applied",
    3: "Available- with Attachment"
}

    
export const EMI_PAYMENT_MODE = {
    1: "ENACH_MODE",
    2: "PAYMENT_LINK_MODE",
    3: "GATEWAY_MODE",
    4: "CHEQUE",
    5: "CASH",
    6: "FUND_TRANSFER",
    9: "OTHERS"
}
export const LANDING_PAGES = {
    "dashboard"     : "Dashboard",
    "application"   : "Application",
    "loan"          : "Loan",
    "enquiry"       : "Enquiry",
    "lead/enquiry"  : "Lead",
    "employees"     : "Employees",
    "collection/payments" : "Collection"
}

export const CONSTRUCTION_TYPE = {
    1 : "RCC",
    2 : "Managore",
    3 : "Other"
}
export const CONSTRUCTION_QUALTY = {
    1 : "Poor",
    2 : "Average",
    3 : "Good"
}

export const LOAN_AMOUNT_LIST = {
    1 : "5 to 10 Lakh",
    2 : "11 to 20 Lakh",
    3 : "21 to 50 Lakh",
    4 : "More than 50 Lakh"
}
