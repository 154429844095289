import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
  
import { AutoFocusDirective} from './directive/auto-focus.directive';
import {RangePipe} from './pipe/pipe/range.pipe';
import {ReplacePipe} from './pipe/pipe/replace.pipe';
import {CanAccessPipe} from './pipe/pipe/canAccess.pipe';
import {CanEditPipe} from './pipe/pipe/canEdit.pipe';
import { getDatePipe } from './pipe/pipe/getDate.pipe';
import { getNumFormatPipe } from './pipe/pipe/getNumFormat.pipe';
import { PivotDataOpPipe } from './pipe/pipe/pivotDataOp.pipe';
import { ListItemPipe } from './pipe/pipe/listItem.pipe';
import { LPadPipe } from './pipe/pipe/lpad.pipe';
import { SortPipe } from './pipe/pipe/sort.pipe';
import { ListFilterPipe } from './pipe/pipe/listfilter.pipe';
import { SafePipe } from './pipe/pipe/safe.pipe';
import { MaskedPipe } from './pipe/pipe/masked.pipe';
import { NumInWords } from './pipe/pipe/numInWord.pipe';
import { StatusBadge } from './pipe/pipe/statusBadge.pipe';
import { getNumeralPipe } from './pipe/pipe/getNumeral.pipe';

import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from '../shared-component/helper/date-formatter';

import { SpinnerComponent } from './spinner/spinner.component';
import { LogOutComponent } from './common-components/log-out/log-out.component';
import { CustomerDetailComponent } from './common-components/customer-details/customer-detail.component';
import { NPinComponent } from './common-components/n-pin/n-pin.component';
import { RTEComponent } from './common-components/rte/rte.component';
import { AddPincodeComponent} from './common-components/add-pincode/add-pincode.component';
import { PaginationComponent } from './common-components/pagination/pagination.component';
import { AdvanceInputComponent } from './common-components/advanceInput/advanceInput.component';
import { AuthorizationComponent } from './common-components/authorization/authorization.component';
import { MultiSelectDorpdownComponent } from './common-components/multiselectdorpdown/multiselectdorpdown.component';
import { NoRecordFoundComponent } from './common-components/no-record-found/no-record-found.component';
import { GMapComponent } from './common-components/gmap/gmap.component';
import { DocumentViewerComponent } from './common-components/documentViewer/document-viewer.component';
import { ImageGalleryComponent } from './common-components/image-gallery/image-gallery.component';
import { AdministratorPophoverComponent } from './common-components/administrator-pophover/administrator-pophover.component';
import { CalendarComponent } from './common-components/calendar/calendar.component';
import { DatepickerComponent } from './common-components/datepicker/datepicker.component';
import { SearchUserComponent } from './common-components/search-user/search-user.component';
import { ImageCropperComponent } from './common-components/image-cropper/image-cropper.component';
import { CustomeSelectComponent } from './common-components/custome-select/custome-select.component';
import { ListPaginationComponent } from './common-components/list-pagination/list-pagination.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  imports: [
    CommonModule,
    NgbModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule
  ],
  declarations: [
    SpinnerComponent,
    RangePipe,
    ReplacePipe,
    CanAccessPipe,
    CanEditPipe,
    getDatePipe,
    getNumFormatPipe,
    PivotDataOpPipe,
    ListItemPipe,
    LPadPipe,
    SortPipe,
    ListFilterPipe,
    SafePipe,
    MaskedPipe,
    getNumeralPipe,
    NumInWords,
    StatusBadge,
    LogOutComponent,
    CustomerDetailComponent,
    AutoFocusDirective,
    AddPincodeComponent,
    NPinComponent,
    PaginationComponent,
    AdvanceInputComponent,
    MultiSelectDorpdownComponent,
    AuthorizationComponent,
    NoRecordFoundComponent,
    GMapComponent,
    RTEComponent,
    DocumentViewerComponent,
    ImageGalleryComponent,
    AdministratorPophoverComponent,
    CalendarComponent,
    DatepickerComponent,
    SearchUserComponent,
    ImageCropperComponent,
    CustomeSelectComponent,
    ListPaginationComponent
  ],
  exports: [
    LogOutComponent,
    CustomerDetailComponent,
    RangePipe,
    ReplacePipe,
    CanAccessPipe,
    CanEditPipe,
    getDatePipe,
    getNumFormatPipe,
    PivotDataOpPipe,
    ListItemPipe,
    LPadPipe,
    SortPipe,
    ListFilterPipe,
    SafePipe,
    MaskedPipe,
    getNumeralPipe,
    NumInWords,
    StatusBadge,
    SpinnerComponent,
    AutoFocusDirective,
    AddPincodeComponent,
    NPinComponent,
    PaginationComponent,
    AdvanceInputComponent,
    MultiSelectDorpdownComponent,
    AuthorizationComponent,
    NoRecordFoundComponent,
    GMapComponent,
    RTEComponent,
    DocumentViewerComponent,
    ImageGalleryComponent,
    AdministratorPophoverComponent,
    CalendarComponent,
    DatepickerComponent,
    SearchUserComponent,
    ImageCropperComponent,
    CustomeSelectComponent,
    ListPaginationComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },{
      provide: NgbDateParserFormatter,
      useClass: NgbDateFRParserFormatter
    }
  ]
})
export class SharedComponentModule {
}
