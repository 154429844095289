import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ConstantService} from '../../constant/constant.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private http: HttpClient,
    private constantService: ConstantService
  ) {
  }
  
  private _items: any = {};
  private _access: any = {};
  public loadInitialAccess: boolean = false;
  
  setItem(item: string, value : any) {
    this._items[item] = value;
  }

  getItems(key: string) {
    return this._items[key] || null;
  } 

  setAccess(item: any) {
    this._access = item;
    localStorage.setItem("USER_ACCESS",JSON.stringify(item));
  }
  
  getAccess() {
    return this._access;
  }
  
  getAccessList(body: Object) {
    // if (Object.keys(this._access).length === 0 || this.loadInitialAccess == true) {
    //   this.loadInitialAccess = false;
      return this.http.post(this.constantService.getUrl(`${this.constantService.RBACPERMISSION}/access`), body);
    // } else {
    //   return this.getAccess();
    // }
  }
  
  get(params) {
    return this.http.get(this.constantService.getUrlByQuery(this.constantService.RBACPERMISSION, params));
  }

  getById(id: string) {
    return this.http.get(this.constantService.getUrl(this.constantService.RBACPERMISSION, [id]));
  }

  post(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.RBACPERMISSION), body);
  }

  put(id: string, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.RBACPERMISSION, [id]), body);
  }

  deletePermission(id: number) {
    return this.http.delete(this.constantService.getUrl(`${this.constantService.RBACPERMISSION}/${id}`));
  }

  deleteRolePermission(val: string) {
    return this.http.delete(this.constantService.getUrl(`${this.constantService.RBACPERMISSION}/${val}`));
  }

}
