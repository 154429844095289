import {Injectable} from '@angular/core';

/**
 * Description of Toaster Notification Service
 *
 * @author [RAVI HIRPARA]
 */

@Injectable({
  providedIn: 'root'
})   
export class ToasterAlertService{

  constructor(
  ) {
  }

  pop(type, title, message = "") {
    if(!message) return;
    if(type == "error"){
      type = "danger";
    }
    
    window["notify"].call(window, type || "inverse", message || "", title || "");
  }
}
