import {Component, OnInit, EventEmitter, Output, Input, HostListener, ViewChild, ElementRef, TemplateRef} from '@angular/core';
import {Papa} from 'ngx-papaparse';
import {AccessCodeService} from '../../constant/accessCode.service';
import { ToasterAlertService } from '../../notification/toastAlert.service';
import { LoanService } from '../../service/loan/loan.service';
import { ApplicationService } from '../../service/application/application.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanLODService } from '../../service/loanlod/loanlod.service';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {

  @Input() public header: any = null;
  @Input() public fileSrc: any = "";
  @Input() public filePath: any = "";
  
  @Output() closeSideBarEvent = new EventEmitter();

  public accessCodeList = AccessCodeService;
  private Croppie = window["Croppie"];

  public currentImage: any = { index: 0, url: '', docPath: '', rotation: 0 };
  public s3Path: any;
  public s3PathSecure: any;
  public showFileLoader: any = false;
  public imageURL: any = null;
  public imageData: any = null;
  
  private oCroppr = null;
  
  @ViewChild('modelContent', { read: TemplateRef }) modelContent:TemplateRef<any>;
  @ViewChild('imageRef', { read: TemplateRef }) imageRef:TemplateRef<any>;
 
  constructor(
    private toasterService: ToasterAlertService,
    private papa: Papa,
    private sanitizer: DomSanitizer,
    private loanService: LoanLODService,
    private modalService: NgbModal,
    ) {
    }

    ngOnInit(){
      let oThat = this;
      this.modalService.open(this.modelContent, { backdrop: 'static', centered: true, size : 'lg' });
      setTimeout(function(){ oThat.setupCropper(); },500);
    }

    setupCropper(){
      let oThat = this;
      
      var el = document.getElementById('image-cropper');
      this.oCroppr = new this.Croppie(el, {
        viewport: { width: 233, height: 280 },
        boundary: { width: 750, height: 450 },
        showZoomer: true
      });

      oThat.oCroppr.bind({
        url: this.fileSrc,
        orientation: 4
      });  
    }

    cropImage(){
      let oThat = this;
      if( !oThat.imageURL ){
        this.oCroppr.result('blob').then(function(blob) {
          // do something with cropped blob
          var reader = new FileReader();
          oThat.imageData = blob;
          reader.readAsDataURL(blob); 
          reader.onloadend = function() {
            oThat.imageURL = reader.result;
          }
        });
      } else { 
        oThat.imageURL = null;
        setTimeout(function(){ oThat.setupCropper(); },500);
      }
    }

    processImage(d){
      let oThat = this;
      let formData: any = new FormData();
      formData.append("file", this.imageData, this.filePath.split("/").pop());
      formData.append("filePath", this.filePath);
    
      this.loanService.reUploadDoc(formData).subscribe( async (res: any) => {
        this.imageURL = res.data && res.data.url ? res.data.url.url : null;
        this.closeSideBar(d);
      });
    }
    
    closeSideBar(d){
      d();
      if(this.imageURL){
        this.closeSideBarEvent.emit( { path : this.filePath, url : this.imageURL});
      } else {
        this.closeSideBarEvent.emit();
      }
    }  

    uploadFile(event) {
      let oThat = this;
      let docfileUploader = event.target;
      if (!docfileUploader.files[0]) {
        return false;
      }
  
      this.fileSrc = URL.createObjectURL(docfileUploader.files[0]);
      if ( oThat.imageURL ){
        oThat.imageURL = null;
        setTimeout(function(){ oThat.setupCropper(); },500);
      } else {
        oThat.oCroppr.bind({
          url: this.fileSrc,
          orientation: 4
        }); 
      }
    }
}
