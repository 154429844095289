import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http/src/response';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs';

import {ConstantService} from '../../constant/constant.service';

@Injectable({
  providedIn: 'root'
})
export class CollectionService {

  constructor(
    private constantService: ConstantService,
    private http: HttpClient
  ) {
  }

  getPaidAndUnpaidEmis(loanId){
    return this.http.get(this.constantService.getUrl(this.constantService.COLLECTIONS + `/getPaidAndUnpaidEmis/${loanId}`))
  }

  getDelinquentCount(body = {}){
    return this.http.post(this.constantService.getUrl(this.constantService.COLLECTIONS + `/getdelinquentcounts`),body);
  }

  customerCounts() {
    return this.http.post(this.constantService.getUrl(this.constantService.COLLECTIONS + `/customercount`),{});
  }

  get(params: Array<object>) {
    if (!params.length) return this.http.get(this.constantService.getUrl(this.constantService.COLLECTIONS));
    else return this.http.get(this.constantService.getUrlByQuery(this.constantService.COLLECTIONS, params));
  }

  getCollections(params?: any) {
    return this.http.get(this.constantService.getUrl(this.constantService.COLLECTIONS), {
      params: params,
      observe: 'response'
    }).map((resp: any) => {
      let count = resp.headers.get('X-Total-Count');
      return {count: parseInt(count), data: resp.body.data};
    });
  }

  getDueList(body = {}) {
    return this.http.post(this.constantService.getUrl(this.constantService.COLLECTIONS+`/upcomingdue`),  body );
  }

  getEmiPresentationList(body = {}) {
    return this.http.post(this.constantService.getUrl(this.constantService.COLLECTIONS+`/emipresentationlist`),  body );
  }

  downloadExcel(params?: any): Observable<HttpResponse<Blob>> {
    return this.http.get(this.constantService.getUrl(this.constantService.COLLECTIONS+ '/download/excel'), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }

  bulkPresentationUpload(body) {
    return this.http.post(this.constantService.getUrl(this.constantService.COLLECTIONS + `/upload/presentations`), body);
  }

  offlinePayment(loanId, body) {
    return this.http.post(this.constantService.getUrl(this.constantService.COLLECTIONS + `/${loanId}/payment/offline`), body);
  }

  bulkOfflinePaymentsUpload(body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.COLLECTIONS}/bulk/payment/offline`), body);
  }
}
