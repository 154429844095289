import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/throw';
import { ToasterService } from 'angular2-toaster';
import { map, filter } from 'rxjs/operators';
import { ToasterAlertService } from '../notification/toastAlert.service';
import { ConnectionService } from 'ng-connection-service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private toasterService: ToasterAlertService,
    private connectionService: ConnectionService
    
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = sessionStorage.getItem('token'),
      headers = {};

    if (token) {
      headers = {
        headers: request.headers.set('Authorization', token)
      };
    }
    this.showProgressBar();
    return next.handle(request.clone(headers)).pipe(
      filter(response => response instanceof HttpResponse),
      map(
        (res: HttpResponse<any>) => {
          // try {
          //   if (res && res.body && !res.body.safe) {
          //     const bytes = this.decrypt(res.headers.get('X-Parse-Token') || "techfino-server", res.body.data);
          //     res.body.data = JSON.parse(bytes.replace(/[^\x20-\x7E]/g, ""));
          //   }
          // } catch (err) {
          //   res.body.data = null;
          // }
          try{
            if(JSON.parse(localStorage.getItem('debug') || "false") == true){
              console.log(res.url,"\n",res.body.data);
            }
          }catch(JsonError){}
          this.hideProgressBar();
          return res;
        }
      )).catch((err) => {
        this.hideProgressBar();
        if (err instanceof HttpErrorResponse && err.error.message) {
          if (err.error.logout === true) {
            sessionStorage.removeItem('token');
            this.toasterService.pop('error', 'Alert', err.error.message);
            this.router.navigate(['/authentication']).then();
            return Observable.empty();
          }
          if (err.error.errorType === 'GENERIC') {
            this.toasterService.pop('error', 'Alert', err.error.message);
            return Observable.empty();
          }
          if (err.error.errorType === 'VALIDATION') {
            return Observable.throw(err.error.errorMessages);
          }
        }
        let errorMsg = err.error.message || err.error.detail;
        if(!errorMsg){
          this.connectionService.monitor().subscribe(isConnected => {
            if (!isConnected) {
              if ( localStorage.getItem('nointer') != "0"){
                this.toasterService.pop('error', '', 'No internet connection');
                localStorage.setItem('nointer',"0");
              }
            } else {
              if ( localStorage.getItem('nointer') != "1"){
                this.toasterService.pop('error', 'Alert', 'Oops! something went wrong');
                localStorage.setItem('nointer',"1");
              }
            }
          });
        } else {
          this.toasterService.pop('error', 'Alert', errorMsg);
        }
        return Observable.empty();
      });
  }

  showProgressBar(){
    let dom = document.getElementById("theme-loader");
    dom.classList.add("show");
  }

  hideProgressBar(){
    let dom = document.getElementById("theme-loader");
    dom.classList.remove("show");
  }

  decrypt(salt, encoded) {
    if (!encoded) return null;
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
  };
}