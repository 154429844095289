import { Component, OnInit, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import * as downloadjs from 'downloadjs';
import { Location } from '@angular/common';
import { ColumnDragDrop } from '../../shared-component/helper/columnDragDrop';


import { CustomerService } from '../../shared-component/service/customer/customer.service';
import { environment } from '../../../environments/environment';
import { AccessCodeService } from '../../shared-component/constant/accessCode.service';
import { CanAccessPipe } from '../../shared-component/pipe/pipe/canAccess.pipe';
import { ToasterAlertService } from '../../shared-component/notification/toastAlert.service';
import { AdvanceInputComponent } from '../../shared-component/common-components/advanceInput/advanceInput.component';
import { encodeQueryData } from '../../shared-component/helper/common';
import { APPLICANT_RELATIONSHIP_TYPE, EDUCATION_QUALIFICATION, GENDER, IDPROOF_TYPES, MARITAL_STATUS, PAYMENT_MODE_TYPE, LOAN_TYPE, LENDING_PARTNERS } from '../../shared-component/constant/commonConst.service';
import { BranchService } from '../../../app/shared-component/service/branch/branch.service';
import { PaymentlinkService } from '../../shared-component/service/paymentlink/paymentlink.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-disbursment-report',
  templateUrl: './disbursment-report.component.html',
  styleUrls: ['./disbursment-report.component.css'],
  providers:[CanAccessPipe]
})
export class DisbursmentReportComponent implements OnInit {
  readonly TABLE_IDENTIFIER = 'PAYMENTLINK';
  readonly STORAGE_KEY = 'PAYMENTLINKS_TABLE';
  

  public currentOpenDatePicker: any = null;
  public accessCodeList = AccessCodeService;
  public relationshipTypeList = APPLICANT_RELATIONSHIP_TYPE;
  public genderList = GENDER;
  public loanTypeList = LOAN_TYPE;
  public collenderTypeList = LENDING_PARTNERS;
  public maritalStatusList = MARITAL_STATUS;
  public educatinQualificationList = EDUCATION_QUALIFICATION;
  public idProofTypeList = IDPROOF_TYPES;
  public showDetailSidebar: any = false;
  public selectedCustomer: any = {};

  public branches: any = [];
  public selectedBranches = [];
  public selectedBrancesIds = [];
  public dropdownBranchSettings = {};

  public excelStatementForm: FormGroup;
  public filterForm: FormGroup;

  public oColumnDragDrop;
  public columnUserPreference = [
    { field: 'select', label: 'Select', visible: true },
    { field: 'customerName', label: 'name', visible: true },
    { field: 'applicationNumber', label: 'Application Number', visible: true },
    { field: 'loanNumber', label: 'Loan Number', visible: true },
    { field: 'loanAmount', label: 'Loan Amount', visible: true },
    { field: 'colenderId', label: 'Co-lender Refernce ID', visible: true },
    { field: 'lender', label: 'Lender', visible: true },
    { field: 'branch', label: 'Branch', visible: true },
    { field: 'status', label: 'Loan Status', visible: true },
    { field: 'productType', label: 'Product Type', visible: true },
    { field: 'tranche', label: 'Tranche', visible: true },
    { field: 'disbursementStatus', label: 'Status', visible: true },
    // { field: 'sanctionAmount', label: 'Sanction Amount', visible: true },
    { field: 'tenure', label: 'Tenure', visible: true },
    { field: 'roi', label: 'ROI', visible: true },
    { field: 'insuranceAmount', label: 'Insurance Collected', visible: true },
    { field: 'pfAmount', label: 'PF Collected', visible: true },
    { field: 'mofAmount', label: 'MOF Collected', visible: true },
    { field: 'bpiAmount', label: 'BPI Collected', visible: true },
    { field: 'extraAmount', label: 'Extra Amount Collected', visible: true },
    { field: 'totalAmount', label: 'Total Amount Collected', visible: true },
    { field: 'insuranceAmountDed', label: 'Insurance Deducted', visible: true },
    { field: 'pfAmountDed', label: 'PF Deducted', visible: true },
    { field: 'mofAmountDed', label: 'MOF Deducted', visible: true },
    { field: 'emiStarting', label: 'EMI Starting Date', visible: true },
    { field: 'emiEnding', label: 'EMI Ending Date', visible: true },
    { field: 'bpiAmountDed', label: 'BPI Deducted', visible: true },
    { field: 'extraAmountDed', label: 'Extra Amount Deducted', visible: true },
    { field: 'totalAmountDed', label: 'Total Amount Deducted', visible: true },
    { field: 'disbursementAmount', label: 'Disbursement Amount', visible: true },
    { field: 'tranchAmount', label: 'Tranch Amount', visible: true },
    { field: 'disDate', label: 'Disbursement Date', visible: true },
    { field: 'transactionDate', label: 'Transaction Date', visible: true },
    { field: 'utr', label: 'UTR ID', visible: false }
  ];

  public rowIndex = 0;
  public todayDate: any;
  public itemsCount = 0;
  public itemsPerPage = environment.itemsPerPage;
  public currentPage = 1;
  
  public search = '';
  public queryField: any = "";
  public sortKey = '';
  public sortDirection = 'asc';
  public status = null;
  public searchData = null;
  public paymentModeTypeList = PAYMENT_MODE_TYPE;
  public regularCharge=0;


  public dataSource = new BehaviorSubject<any[]>([]);

  public customerId: any = null;
  public showSidebar: boolean = false;

  @ViewChildren('rowsContainer', { read: ViewContainerRef }) rowsContainer;
  @ViewChildren(AdvanceInputComponent) advanceInputComponent: QueryList<AdvanceInputComponent>;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private toasterService: ToasterAlertService,
    private canAccessPipe: CanAccessPipe,
    private location: Location,
    private branchService: BranchService,
    private paymentLinkService: PaymentlinkService,
    private modalService: NgbModal,

  ) {
    window.scrollTo(0, 0);
    if (!this.canAccessPipe.transform(this.accessCodeList.COLLECT_PAYMENTS)) {
      this.router.navigate(['/accessdenied']);
      return;
    }
    this.activatedRoute.queryParams.subscribe(pageNumber =>
      this.currentPage = Object.keys(pageNumber).length !== 0 ? parseInt(pageNumber.page) : 1);
    this.getPaymentLinks();

    this.excelStatementForm = fb.group({
      excelStartDate: [null],
      excelEndDate: [null]
    });

    let oThat = this;
    window.addEventListener("load", function (event) {
      oThat.resizeTable();
    });
  }

  resizeTable(reduce=0){
    let dom : any = document.querySelector(".table-container-fixed-height");
      if(dom){
        dom.style.maxHeight = `calc(100vh - ${ dom.getBoundingClientRect().y + reduce }px)`;
      }
  }

  get excelStartDate() {
    return this.excelStatementForm.get('excelStartDate');
  }

  get excelEndDate() {
    return this.excelStatementForm.get('excelEndDate');
  }

  get displayedColumns() {
    this.oColumnDragDrop = new ColumnDragDrop(this.columnUserPreference , this.STORAGE_KEY + this.TABLE_IDENTIFIER );
    return this.oColumnDragDrop.columnList.filter((o: any) => o.visible).map(o => o.field);
  }

  ngOnInit() {
    if (localStorage.getItem(this.STORAGE_KEY + this.TABLE_IDENTIFIER)) {
      let preferences = JSON.parse(localStorage.getItem(this.STORAGE_KEY + this.TABLE_IDENTIFIER));
      this.columnUserPreference = this.columnUserPreference.map(o => {
        let preference = preferences.find(p => p.field === o.field);
        if (preference) o.visible = preference.visible;
        return o;
      });
    }
    this.getBranches();
    this.activatedRoute.queryParams.subscribe(params => {
      this.currentPage = (Object.keys(params).length !== 0 && params.page) ? parseInt(params.page) : 1;
      if (params.delinquent) {
        this.status = params.delinquent;
      }
    });

    this.dropdownBranchSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      clearSearchFilter: true,
      searchPlaceholderText: 'Search',
    };

    this.getPaymentLinks();
    this.todayDate = {
      year: parseInt(moment().format('YYYY')),
      month: parseInt(moment().format('MM')),
      day: parseInt(moment().format('DD'))
    };
  }

  onBranchSelect(item: any) {
    this.selectedBrancesIds.push(item.id);
  }
  onBranchSelectAll(items: any[]) {
    this.selectedBrancesIds = items.map(element => element.id);
  }
  onBranchDeSelect(item: any) {
    this.selectedBrancesIds = this.selectedBrancesIds.filter(i => i !== item.id);
  }


  getRowInfo(event, id) {
    if (event.target.checked) {
      this.rowIndex = id;
    } else {
      this.rowIndex = null;
    }
  }

  reset(e: Event) {
    e.preventDefault();
    // this.filterForm.get('isActive').setValue('');
    this.advanceInputComponent.forEach((inputComponent: AdvanceInputComponent) => {
      inputComponent.clearInput();
    });
    this.search = '';
    this.queryField = '';
    this.getPaymentLinks();
  }

  resetExcelStatementForm(e: Event) {
    e.preventDefault();
    this.excelStatementForm.setValue({
      excelStartDate: null,
      excelEndDate: null
    });
  }

  checkExcelStatementFormValidity() {
    return this.excelStatementForm.valid && (this.excelStartDate.value
      && this.excelEndDate.value);
  }

  getPaymentLinks(flag = false) {
    this.currentPage = flag ? 1 : !this.currentPage ? 1 : this.currentPage;
    let params: any = {
      limit: this.itemsPerPage,
      page: this.currentPage
    };

    if (this.status) {
      params.status = this.status;
    }

    if (this.search) params.q = this.search;
    if (this.queryField) params.qf = this.queryField;

    if (this.selectedBrancesIds.length > 0) {
      params.branch = this.selectedBrancesIds.join(',');
    }

    if (this.sortKey) params.sort = `${this.sortKey},${this.sortDirection}`;

    // if (this.filterBranchId && this.filterBranchId.value && this.filterBranchId.value.id) params.branch = this.filterBranchId.value.id;
    this.searchData = params;
    this._getPaymentLinks();
  }

  _getPaymentLinks() {
    this.paymentLinkService.getDisbursement(this.searchData).subscribe((res: any) => {
      this.dataSource.next(res.data);
      // console.log(res.LoanTranch.LoanApplication.PaymentLink);
    });
  }

  onEnterKey(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.getPaymentLinks();
    }
  }

  pageChange(objEvent) {
    let { page, flag, params } = objEvent;

    this.searchData.page = page;
    this._getPaymentLinks();

    this.location.replaceState(encodeQueryData(this.router.url, params));
  }
  name(arrr: any[]): any {
    let result = arrr.find((o) => o.consumerType === 1);
    return result.Customer.fullName;
}


  doSort(field: string) {
    if (field === 'joinDate') {
      field = 'createdAt';
    }

    if (field === this.sortKey) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortKey = field;
      this.sortDirection = 'asc';
    }
    const sortedData = [...this.dataSource.value];
    sortedData.sort((a, b) => {
      const aValue = a[field];
      const bValue = b[field];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return this.sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else {
        return this.sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
      }
    });
    this.dataSource.next(sortedData);
  }

  getCharges(Charges:any, typeOfCharge: string){
    const charge = Charges.find(o => o.note === typeOfCharge)
    if (charge) {
      this.regularCharge += charge.amount || 0;
      return charge.amount;
    } else {
      return 'NA';
    }
  }

  extraCharges(Charges:any,insuranceAmount:number, processingFee:number){
    this.regularCharge=this.regularCharge+insuranceAmount+processingFee
    var extraCharge= this.totalCharges(Charges, insuranceAmount, processingFee)-this.regularCharge
    this.regularCharge=0;
    return extraCharge
  }

  totalCharges(charges: any[], insuranceAmount: number, processingFee: number): number {
    let totalCharge = charges.reduce((sum, charge) => sum + charge.amount, 0);
    return totalCharge + insuranceAmount + processingFee;
}

  persistColumnPreference() {
    localStorage.setItem(this.STORAGE_KEY + this.TABLE_IDENTIFIER, JSON.stringify(this.columnUserPreference));
  }

  openExcelStatementPopUp(content: any, d) {
    const searchInput = this.search && this.search.trim();
    if (searchInput) {
      this.downloadExcel(d);
    } else {
      this.modalService.open(content, { backdrop: 'static', centered: true });
    }
  }

  getDisburseAmount(disburseAmount, loanAmount){
   return disburseAmount<=100 ? loanAmount*(disburseAmount/100) : disburseAmount
  }

  downloadExcel(dismiss) {
    let params: any = {};

    if (this.search) params.q = this.search;
    if (this.queryField) params.qf = this.queryField;
    if (this.sortKey) params.sort = `${this.sortKey},${this.sortDirection}`;
    /*  Start and End Amount Filter | currectly removed, can be retrive in future
      if (this.filterStartAmount.value) params.startAmount = this.filterStartAmount.value;
    if (this.filterEndAmount.value) params.endAmount = this.filterEndAmount.value; */
    // if (this.filterStatus.value) params.status = this.filterStatus.value;
    // if (this.filterPartnerId.value && this.filterPartnerId.value.id) params.partner = this.filterPartnerId.value.id;
    // if (this.filterBranchId.value && this.filterBranchId.value.id) params.branch = this.filterBranchId.value.id;

    let diffDays: any = 0;
    if (this.excelStartDate && this.excelStartDate.value && this.excelEndDate && this.excelEndDate.value) {
      const excelStartDate = moment([this.excelStartDate.value.year, this.excelStartDate.value.month, this.excelStartDate.value.day]),
        excelEndDate = moment([this.excelEndDate.value.year, this.excelEndDate.value.month, this.excelEndDate.value.day]);
      params.startDate = this.excelStartDate.value.year + '-' + this.excelStartDate.value.month + '-' + this.excelStartDate.value.day + ' 00:00:00';
      params.endDate = this.excelEndDate.value.year + '-' + this.excelEndDate.value.month + '-' + this.excelEndDate.value.day + ' 23:59:59';
      diffDays = excelEndDate.diff(excelStartDate, 'days');
    }

    if (diffDays >= 93) {
      this.toasterService.pop('error', '', 'Start Date & End Date should not be more than 90 days');
    }
    else {
      this.paymentLinkService.downloadDisExcel(params).subscribe(res => {
        let fileName = "Disbursment_Dump.xlsx";
        if (res.headers.get('Content-Disposition')) {
          fileName = res.headers.get('Content-Disposition').split('=')[1];
        }
        downloadjs(res.body, fileName, res.body.type);
        this.toasterService.pop('success', '', 'Data exported successfully.')
        this.excelStatementForm.reset();
        dismiss();
      });
    }
  }

  closeFix(event, datePicker) {
    if (event.target.offsetParent == null || event.target.offsetParent.nodeName != 'NGB-DATEPICKER') {
      datePicker.close();
    }
  }
  
  openDatePicker(datePicker) {
    if (this.currentOpenDatePicker && this.currentOpenDatePicker !== datePicker) {
      this.currentOpenDatePicker.close();
    }
    this.currentOpenDatePicker = datePicker;
    datePicker.toggle();
  }

  getBranches() {
    this.branchService.get([]).subscribe((res: any) => {
      this.branches = res.data;
    });
  }

  calculateTotalAmount(paymentLink) {
    return paymentLink.amount + paymentLink.penaltycharges + paymentLink.bouncecharges;
  }

  
  PaymentMode(mode) {
    const key = mode; 
    return this.paymentModeTypeList[key];
}
}

