import {Pipe, PipeTransform} from '@angular/core';
import { getNumFormatPipe } from './getNumFormat.pipe';

@Pipe({
  name: 'pivotDataOp'
})
export class PivotDataOpPipe implements PipeTransform {

  transform(items: any[], datafield: any = "", datafieldOp : any = { Op: "count" }, formatNum = true): any {
    let result : any = "";
    let getNumFormat = new getNumFormatPipe();
    
    if( items.length == 0) return result;
    let data = [];
    let isNum = datafieldOp.Op!='countNum'?true:false;
    for(let row of items) {
        if (row[datafield]) {
            if(isNaN(row[datafield]))
                isNum = false;
            data.push(row[datafield]);
        } 
    }

    switch(datafieldOp.Op){
        case "none":
            result = "";
            break;
        case "count":
            result = data.length;
            break;
        default:
            result = data.reduce((a, b) => {
                if(isNum)
                    a = a + b;
                else{
                    if(!a[b]) a[b] = 0;
                    a[b] != 1;
                }
                return a;
            }, isNum?0:{});
            switch(datafieldOp.Op){
                case "sum":
                    break;
                case "average":
                    if(isNum){
                        result = (result / data.length).toFixed(2);
                    } else {
                        let data = [];
                        for( let item in result){
                            data.push(item+ " ("+result[item]+ ")");
                        }
                        result = data.join(", ");
                    }
                    break;
                case "ratio":
                    if(isNum){
                        result = (result / data.length).toFixed(2);
                    } else {
                        let data = [];
                        for( let item in result){
                            data.push(item+ " ("+result[item]+ ")");
                        }
                        result = data.join(", ");
                    }
                    break;
            }
            break;
    }
        
    return !isNaN(result) && formatNum? getNumFormat.transform(result) : result;
  }

}
