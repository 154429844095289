import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToasterService } from 'angular2-toaster';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToasterAlertService } from '../../notification/toastAlert.service';

@Component({
  selector: 'app-multiselectdorpdown',
  templateUrl: './multiselectdorpdown.component.html',
  styleUrls: ['./multiselectdorpdown.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectDorpdownComponent ),
      multi: true
    }
  ]
})
export class MultiSelectDorpdownComponent implements OnInit, ControlValueAccessor {

  @Input() keyAttr: any = null;
  @Input() valueAttr: any = null;
  @Input() arrayVal: any = false;
  @Input() mutiSelect: any = true;
  @Input() options: any;
  @Input() placeholder: any;
  @Input() defaultStyleClass: any;
  
  @Output() triggerchange = new EventEmitter();

  public _inputvalue: any = "";
  public inputText: any = "";
  public disabled = false;
  
  public checkbox: any = [];
  public timer = null;
  public searchOption = "";
  public showExpand = false;

  value: any = "";
  
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private toasterService: ToasterAlertService
  ) {
  }

  ngOnInit() {
  
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  processList( closeMenu = null, close = true) {
    let oThat = this;
    clearTimeout(this.timer);
    
    if(!this.mutiSelect) oThat.checkbox = []; 
    setTimeout( function(){
      let result : any = [];
      let displayText : any = [];
      for(let item in oThat.checkbox){
        if(oThat.checkbox[item]){
          result.push(item);
        }
      }
      if(result.length ){
        if(oThat.arrayVal == false && oThat.keyAttr != null && oThat.valueAttr != null ){
          oThat.options.map( o => {
            if(result.indexOf(o[oThat.keyAttr]) != -1){
              displayText.push(o[oThat.valueAttr]);
            }
          });
        } else if( oThat.keyAttr == 0 && oThat.valueAttr == 1){
          oThat.options.map( o => {
            displayText = o[1]; 
          }); 
        } else {
          displayText = result;  
        }
      }
      oThat.inputText = displayText.join(", ");
      oThat._inputvalue = oThat.inputText ? result : ""; 
      oThat.onChange(oThat._inputvalue);
      if(oThat.triggerchange ) oThat.triggerchange.emit();
      if(close && closeMenu) oThat.closeDropdown(closeMenu);
    },300);
  }

  clearCheckbox(){
    this.checkbox = [];
    this.processList();
  }

  expandPopup(content, optionMenu){
    optionMenu.classList.remove("show");
    this.modalService.open(content, { backdrop: 'static', centered: true, size: "lg" });
  }

  popupProcessList(close){
    this.processList();
    close();
    this.showExpand = false;
  }

  _close(close){
    close();
    this.showExpand = false;
  }

  onBlur(value: any): void {
  }

  updateCallback($event) {
    this.onChange(this._inputvalue || "");
  }

  writeValue(value: any): void {
    //console.log(this.options);
    this.value = value == undefined ? "" : value;
    let oThat = this;
    try{
      if(value){
        oThat._inputvalue = value;
      }
    }catch(e){
      oThat.disabled = false;
      oThat._inputvalue = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  
  toggleDropdown(optionMenu){
    optionMenu.classList.toggle("show");
    if(optionMenu.classList.contains("show")){
      this.showExpand = true;
    } else {
      this.showExpand = false;
    }
  }

  closeDropdown(optionMenu){
    optionMenu.classList.remove("show");
    this.showExpand = false;
  }
}
