import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {ConstantService} from '../../constant/constant.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private constantService: ConstantService
  ) {
  }

  login(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.LOGIN), body);
  }

  validateOTP(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.VALIDATEOTP), body);
  }

  forgetPassword(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.FORGETPASSWORD), body);
  }

  resetPassword(body: Object, token) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.RESETPASSWORD}/resetpassword`, [token]), body);
  }

  newPassword(body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.USERS}/me/resetPassword`), body);
}

changePassword(body) {
  return this.http.post(this.constantService.getUrl(`${this.constantService.USERS}/me/changePassword`), body);
}
}
