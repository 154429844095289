import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-no-record-found',
  templateUrl: './no-record-found.component.html',
  styleUrls: ['./no-record-found.component.scss']
})

export class NoRecordFoundComponent implements OnInit {
  @Input() public onlyMsg = false;
  @Input() public title = "Data Unavailable";
  @Input() public message = "";
  @Input() public loading = false;
  
  public loadingMsg = "Please wait for a while";
  constructor(
    private sanitizer: DomSanitizer,
  ) {
  
  }

  ngOnInit(){
    let oThat = this;
    oThat.loadingMsg = "Please wait for a while";
    oThat.loading = false;
    setTimeout(function(){
      if(oThat.loading)
      oThat.loadingMsg ="Please wait, it's taking more time than usual";
    },10000);
    setTimeout(function(){
      if(oThat.loading)
      oThat.loadingMsg ="Looks like someting went wrong.";
    },30000);
    setTimeout(function(){
      oThat.loading = false;
    },40000);
  }
}