import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule, LocationStrategy, HashLocationStrategy} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {ToasterModule} from 'angular2-toaster';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {CdkTableModule} from '@angular/cdk/table';
import {NgHttpLoaderModule} from 'ng-http-loader';
import {UserIdleModule} from 'angular-user-idle';

import {AppRoutingModule} from './app-routing.module';
import {SharedComponentModule} from './shared-component/shared-component.module';

import {HttpInterceptorService} from './shared-component/http-interceptor/http-interceptor.service';

import {FullComponent} from './layouts/full/full.component';
import {BlankComponent} from './layouts/blank/blank.component';
import {NavigationComponent} from './shared/header-navigation/navigation.component';
import {SidebarComponent} from './shared/sidebar/sidebar.component';
import {AppComponent} from './app.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ReportsModule } from './reports/reports.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    SidebarComponent
  ],
  imports: [
    ReportsModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModule.forRoot(),
    PerfectScrollbarModule,
    AppRoutingModule,
    SharedComponentModule,
    ReactiveFormsModule,
    ToasterModule.forRoot(),
    InfiniteScrollModule,
    CdkTableModule,
    NgHttpLoaderModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    UserIdleModule.forRoot({idle: 600, timeout: 2, ping: 60})
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }, {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
