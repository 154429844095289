import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren, ViewContainerRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common'

import {CustomerService} from '../../../shared-component/service/customer/customer.service';
import {AccessCodeService} from '../../../shared-component/constant/accessCode.service';
import {CanAccessPipe} from '../../../shared-component/pipe/pipe/canAccess.pipe';
import { ToasterAlertService } from '../../../shared-component/notification/toastAlert.service';
import { APPLICANT_RELATIONSHIP_TYPE, CONSTITUTION_TYPE, EDUCATION_QUALIFICATION, GENDER, IDPROOF_TYPES, MARITAL_STATUS, PROFILE_CATEGORY, PROPERTY_OWNED } from '../../../shared-component/constant/commonConst.service';
import { LoanLODService } from '../../../shared-component/service/loanlod/loanlod.service';
import { getExtention } from '../../../shared-component/helper/common';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {

  @Input() customerId: any;

  @Output() reloadCustomerList = new EventEmitter();
  @Output() closeSideBarEvent = new EventEmitter();
  
  public relationshipTypeList = APPLICANT_RELATIONSHIP_TYPE;
  public genderList = GENDER;
  public maritalStatusList = MARITAL_STATUS;
  public educatinQualificationList = EDUCATION_QUALIFICATION;
  public idProofTypeList = IDPROOF_TYPES;
  public constitutionTypeList = CONSTITUTION_TYPE;
  public profileCategoryList = PROFILE_CATEGORY;
  public propertyOwnedList = PROPERTY_OWNED;

  public dataForm: any = {};
  public customerData: any = {};
  public uploadMeta: any = {};
  public showDocViewerSidebar: any = false;
  public filePath: any = "";

  
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private toasterService: ToasterAlertService,
    private canAccessPipe: CanAccessPipe,
    private location: Location,
    private loanLODService: LoanLODService
  ) {
  }

  ngOnInit() {
    this.getApplicant({});
    this.customerService.getCustomerDetails(this.customerId).subscribe((res: any) => {
      this.getApplicant(res.data);
      this.customerData = res.data;
    });
  }

  getApplicant(data: any = {}) {
    let dob: any = {};
    if (data && data.dob) {
      let dt = new Date(data.dob);
      dob = { "year": dt.getFullYear(), "month": dt.getMonth() + 1, "day": dt.getDate() };
    }
    let pan: any = {};
    if (data && data.Pan) {
      pan = data.Pan || {};
    }
    let address: any = {};
    if (data && data.Addresses) {
      address = data.Addresses[0] || {};
    }
    let currentaddress: any = {};
    if (data && data.Addresses) {
      currentaddress = data.Addresses[1] || {};
    }
    // let address= data.Addresses || {};
    this.dataForm = this.fb.group({
      id: [data.id || ""],
      customerId: [data.customerId || ""],
      salutation: [data.salutation || "Mr."],
      fullName: [data.fullName || "", Validators.compose([Validators.required, Validators.pattern(/^[0-9a-zA-Z ]{3,50}$/)])],
      firstName: [data.firstName || "", Validators.compose([Validators.required, Validators.pattern(/^[A-Za-z0-9 ]+$/)])],
      lastName: [data.lastName || "", Validators.compose([Validators.required, Validators.pattern(/^[A-Za-z0-9 ]+$/)])],
      fatherName: [data.fatherName || "", Validators.compose([Validators.required, Validators.pattern(/^[A-Za-z0-9 ]+$/)])],
      motherName: [data.motherName || "", Validators.compose([Validators.required, Validators.pattern(/^[A-Za-z0-9 ]+$/)])],
      gender: [data.gender || 0, Validators.compose([Validators.required])],
      email: [data.email || "", Validators.compose([Validators.required])],
      mobile: [data.mobile || "", Validators.compose([Validators.required])],
      otp: ["", Validators.compose([])],
      isVerifield: [data.isVerifield || false, Validators.compose([])],
      dob: [dob || {}, Validators.compose([Validators.required])],
      maritalStatus: [data.maritalStatus || 0, Validators.compose([Validators.required])],
      noOfDepedencies: [data.noOfDepedencies || 0, Validators.compose([Validators.required])],
      qualification: [data.qualification || "", Validators.compose([Validators.required])],
      constitution: [data.constitution || "", Validators.compose([])],
      profileType: [data.profileType || "", Validators.compose([])],
      propertyOwned: [data.propertyOwned || "", Validators.compose([])],
      alternateMobile: [data.alternateMobile || "", Validators.compose([Validators.required])],
      panNumber: [data.panNumber || "", Validators.compose([Validators.required])],
      aadharNumber: [data.aadharNumber || "", Validators.compose([Validators.required])],
      idProofType: [data.idProofType || 0, Validators.compose([Validators.required])],
      idProofPath: [data.idProofPath || "", Validators.compose([Validators.required])],
      addressProofType: [data.addressProofType || 0, Validators.compose([Validators.required])],
      addressProofPath: [data.addressProofPath || "", Validators.compose([Validators.required])],
      applicantPicture: [data.applicantPicture || "", Validators.compose([Validators.required])],
      employmentType: [data.employmentType || 0, Validators.compose([Validators.required])],
      monthlyIncome: [data.monthlyIncome || 0, Validators.compose([Validators.required])],
      consumerType: [data.consumerType || 0],
      isCurrentAddressSame: [data.isCurrentAddressSame || 0],
      cibilScore: [data.cibilScore || 0],
      cibilLastChecked: [data.cibilLastChecked || 0],
      Pan: this.fb.group({
        id: [pan.id || ""],
        panNumber: [pan.panNumber || "", Validators.compose([Validators.required])],
        fullName: [pan.fullName || ""],
        status: [pan.status || 0],
        score: [pan.score || 0],
        cibilLastChecked: [pan.cibilLastChecked || 0]
      }),
      Address: this.fb.group({
        id: [address.id || 0],
        addressType: [address.addressType || 0],
        houseNo: [address.houseNo],
        houseName: [address.houseName || ""],
        address1: [address.address1 || ""],
        address2: [address.address2 || ""],
        landmark: [address.landmark || ""],
        city: [address.city || ""],
        district: [address.district || ""],
        state: [address.state || ""],
        pincode: [address.pincode || 0],
        geoLocationLat: [address.geoLocationLat || 0],
        geoLocationLong: [address.geoLocationLong || 0],
        distance: [address.distance || 0]

      }),
      AddressCu: this.fb.group({
        id: [currentaddress.id || 0],
        addressType: [currentaddress.addressType || 0],
        houseNo: [currentaddress.houseNo],
        houseName: [currentaddress.houseName || ""],
        address1: [currentaddress.address1 || ""],
        address2: [currentaddress.address2 || ""],
        landmark: [currentaddress.landmark || ""],
        city: [currentaddress.city || ""],
        district: [currentaddress.district || ""],
        state: [currentaddress.state || ""],
        pincode: [currentaddress.pincode || 0],
        geoLocationLat: [currentaddress.geoLocationLat || 0],
        geoLocationLong: [currentaddress.geoLocationLong || 0],
        distance: [currentaddress.distance || 0]

      })
    });
    console.log(address);
    console.log(pan);
    

    if (data && data.applicantPicture) {
      this.loanLODService.signedurl({ filename: data.applicantPicture }).subscribe((res: any) => {
        this.dataForm.profilePic = res.data.url;
      });
    }
  }

  closeFix(event, datePicker) {
    if (event.target.offsetParent == null)
      datePicker.close();
    else if (event.target.offsetParent.nodeName != 'NGB-DATEPICKER')
      datePicker.close();
  }

  submitInfo() {
    let formData = this.dataForm.value;
    this.customerService.updateCustomer(formData.id, formData).subscribe((res: any) => {
      this.toasterService.pop('success', '', res.message);
      this.reloadCustomerList.emit();  
    }, error => {
      this.toasterService.pop('error', '', error.message);
    });
  }
  

  viewIDProof(type) {
    let filename = this.dataForm.get(type).value;
    this.loanLODService.signedurl({ filename: filename }).subscribe((res: any) => {
      window.open(res.data.url);
    });
  }

  closeSideBar(){
    this.closeSideBarEvent.emit();    
  }

  uploadPhotos(applicant, fileUploader) {
    let oThat = this;
    fileUploader.click();
    this.uploadMeta = {
      "type": "applicantPicture",
      "applicant": applicant.get("id").value,
      "form": applicant,
      "isAadhar" : false,
      "noWatermark" : true
    };
  }

  uploadFile(event) {
    let docfileUploader = event.target;
    let formData : any = new FormData();
    if (!docfileUploader.files[0]) {
      return false;
    }

    const file = docfileUploader.files[0];
    formData.append("file", file, `${this.uploadMeta.applicant}-${this.uploadMeta.type}${getExtention(file.name)}`);
    docfileUploader.value = '';

    formData.append("isAadhar" ,0);
    if(this.uploadMeta.noWatermark)
      formData.append("noWatermark" , "1");
    
    this.loanLODService.uploadDoc( formData).subscribe((res: any) => {
      this.toasterService.pop('success', '', res.message);
      this.uploadMeta.form.patchValue({
        applicantPicture: res.data.filename
      });
      this.uploadMeta.form.profilePic = res.data.url;
    }, error => {
      this.toasterService.pop('error', '', error.message);
    });
  }
}
