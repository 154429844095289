import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {SessionExpiryService} from '../../session-expiry-service/session-expiry.service';

@Component({
  selector: 'app-log-out',
  templateUrl: './log-out.component.html',
  styleUrls: ['./log-out.component.css']
})
export class LogOutComponent implements OnInit {

  constructor(
    private router: Router,
    private sessionExpiryService: SessionExpiryService
  ) {
  }

  ngOnInit() {
  }

  logout() {
    sessionStorage.removeItem('token');
    this.sessionExpiryService.clear();
    this.router.navigate(['/authentication']).then();
  }
  
  profile() {
    this.router.navigate(['/profile']).then();
  }

}
