import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numInWords'
})
export class NumInWords implements PipeTransform {
  transform(num) : any{
    var p = ['Zero', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine '];
    var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    if(!num){
        return "";
    }
    let word = "";
    let decimal = num.toString().split(".");
    let len = decimal[0].toString().length;
    if (len > 9) {
      let postnum = (decimal[0] + "").substr(0, len - 7);
      word = this.transform(postnum);
      decimal[0] = (decimal[0] + "").substr(-7);
    }
    let wordNum = "";
    let n: any = ('000000000' + decimal[0]).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (n) {
      var str = '';
      str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
      str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
      str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
      str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
      str += (n[5] != 0) ? ((str != '') ? 'And ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : '';
  
      wordNum = str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
      }).trim();
    }
    if(decimal[1]){
        wordNum += " Point"
        for( let i = 0; i < decimal[1].length ; i++){
            wordNum += " " + p[decimal[1][i]];
        }    
    }
    if (word && wordNum)
      return [word, wordNum].join(" Crore And ");
    if (word)
      return word + " Crore";
    return wordNum;
  }
}