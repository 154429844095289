import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  @Output() setPageChange = new EventEmitter();
  
  @Input() dataLength: number;
  @Input() itemsCount: number;
  @Input() itemsPerPage: number;
  @Input() currentPage: any;

  private page : any = 0;
  private start : any = 0;
  private end : any = 0;

  constructor() { }

  ngOnInit() {
    this.page = this.currentPage;
    this._getStartEnd();
  }

  pageChange(num: number, flag: boolean = false) {
    let currentPage = this.currentPage;
    if(this.currentPage == null){
      num = this.page;
    }
    
    if(num > 0 && this.dataLength < this.itemsPerPage && !flag){
      return;
    }
    if (!flag) {
      this.currentPage = this.currentPage + num;
    } else {
      this.currentPage = num;
    }
    this.page = this.currentPage;
    
    if (this.currentPage < 1) {
      this.currentPage = 1;
      num = 1;
      return;
    }
    
    if (currentPage == this.currentPage ) return;
    
    this._getStartEnd();
    const params = {
      start: this.start,
      end: this.end,
      limit : this.itemsPerPage
    };

    if (this.currentPage !== 1 && !isNaN(this.currentPage)) {
      params['page'] = this.currentPage;
    }

    this.setPageChange.emit({ page: this.currentPage , flag: flag , params : params });
  }

  _getStartEnd(){
    let end = this.currentPage  * this.itemsPerPage ;
    this.start =  ( end - this.itemsPerPage ) + 1;
    this.end = end;
  }

}
