import { Component, Input, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { AccessCodeService } from '../../../shared-component/constant/accessCode.service';
import { CanAccessPipe } from '../../../shared-component/pipe/pipe/canAccess.pipe';

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.css'],
  providers: [CanAccessPipe]
})
export class AuthorizationComponent implements OnInit {

  @Input() accessType: any = null;
  
  public accessCodeList = AccessCodeService;
  
  constructor(
    private router: Router,
    private canAccessPipe: CanAccessPipe
  ) { 
    
  }

  ngOnInit() {
    if (this.accessType && !this.canAccessPipe.transform(this.accessCodeList[this.accessType])) {
      this.router.navigate(['/accessdenied']);
      return;
    }
  }
}
