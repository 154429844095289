import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ConstantService} from '../../constant/constant.service';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(
    private http: HttpClient,
    private constantService: ConstantService
  ) {
  }

  get(param: Array<object>) {
    if (!param.length) return this.http.get(this.constantService.getUrl(this.constantService.BRANCHES));
    else return this.http.get(this.constantService.getUrlByQuery(this.constantService.BRANCHES, param));
  }

  getById(id: string) {
    return this.http.get(this.constantService.getUrl(this.constantService.BRANCHES, [id]));
  }

  getBranches() {
    return this.http.post(this.constantService.getUrl(this.constantService.BRANCHES+'/list'),{});
  }

  modify(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.BRANCHES), body);
  }

  put(id, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.BRANCHES, [id]), body);
  }

  patch(id) {
    return this.http.patch(this.constantService.getUrl(`${this.constantService.BRANCHES}/${id}/activate`), {});
  }

  getEmployees(id) {
    return this.http.get(this.constantService.getUrl(`${this.constantService.BRANCHES}/${id}/employee`));
  }

  addEmployee(id, params) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.BRANCHES}/${id}/employee`), params);
  }

  deleteEmployee(id, branchEmployeeId) {
    return this.http.put(this.constantService.getUrl(`${this.constantService.BRANCHES}/${id}/delete/employee`), {branchEmployeeId: branchEmployeeId})
  }

  qrcode(body?: Object) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.BRANCHES}/qrcode`),body);
  }
}
