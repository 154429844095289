import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import {Papa} from 'ngx-papaparse';
import {AccessCodeService} from '../../constant/accessCode.service';
import { ToasterAlertService } from '../../notification/toastAlert.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SafePipe } from '../../pipe/pipe/safe.pipe';
import { LoanLODService } from '../../service/loanlod/loanlod.service';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.css']
})
export class DocumentViewerComponent implements OnInit {

  @Input() public context: any = null;
  @Input() public title: any = null;
  @Input() public filePath: any;
  @Input() public filePathList: any = [];
  @Input() public downloadable: any = false;
  @Input() public docUrlSource: any = null;
  @Input() public fileName: any = null;
  @Input() public fileType: any = null;
  @Input() public getExtension: any = null;
  @Input() public isAadhar: any = false;

  @Output() closeSideBarEvent = new EventEmitter();

  public accessCodeList = AccessCodeService;

  public s3Path: any;
  public s3PathSecure: any;
  public showFileLoader: any = false;
  public safePipe = null;

  constructor(
    private toasterService: ToasterAlertService,
    private papa: Papa,
    private sanitizer: DomSanitizer,
    private lodService: LoanLODService
  ) {
  }

  ngOnInit() {
    let oThat = this;
    this.showFileLoader = true;
    this.safePipe = new SafePipe(this.sanitizer);

    if(this.filePath){
      this.getExtension = this.filePath.split('.').pop().split('?')[0];
      this.openDocument();
    } else {
      this.s3Path = this.safePipe.transform(this.docUrlSource, "url");
      let tmpName = this.s3Path.changingThisBreaksApplicationSecurity.split(";base64,")
      tmpName = tmpName[0].split("/");
      this.getExtension = tmpName.pop();
      this.showFileLoader = false;
      this.s3PathSecure = this.s3Path.changingThisBreaksApplicationSecurity;
    }

    document.onkeydown = function(evt : any) {
      evt = evt || window.event;
      var isEscape = false;
      if ("key" in evt) {
          isEscape = (evt.key === "Escape" || evt.key === "Esc");
      } else {
          isEscape = (evt.keyCode === 27);
      }
      if (isEscape) {
        oThat.closeSideBarEvent.emit();
      }
    };

  }

  openDocument() {
    this.showFileLoader = true;
    this.lodService.signedurl({ filename: this.filePath }).subscribe((res: any) => {
      this.s3Path = res.data.url;
      this.paserFileUrl();
      this.showFileLoader = false;
    });
  }

  maskAadhar() {
    this.showFileLoader = true;
    this.lodService.maskAadhar({ filename: this.filePath }).subscribe((res: any) => {
      this.s3Path = res.data.url;
      this.paserFileUrl();
      this.showFileLoader = false;
    });
  }

  downloadDocument(s3Path) {
    if(s3Path){
      this.lodService.signedurl({ filename: s3Path }).subscribe((res: any) => {
        window.location = res.data.url;
      });
    } else if(this.docUrlSource){
      const link = document.createElement('a');
      link.href = this.docUrlSource;
      link.target = '_blank';
      link.download = this.fileName + '.' + this.getExtension.toLowerCase();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  closeSideBar(){
    this.closeSideBarEvent.emit();
  }

  paserFileUrl(){
    if (['jpg', 'jpeg', 'png', 'wav', 'mp3', 'mp4', 'mid', 'mpeg', 'mpga', 'webm'].indexOf(this.getExtension) !== -1) {
      
    } else if (['pdf', 'csv' ,'doc', 'docs', 'docx', 'xls', 'xlsx', 'wav'].indexOf(this.getExtension) !== -1) {
      this.s3PathSecure = this.safePipe.transform(`https://docs.google.com/viewer?url=${encodeURIComponent(this.s3Path)}&embedded=true`, "url");
    }
  }

}
