import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http/src/response';
import {Observable} from 'rxjs';

import {ConstantService} from '../../constant/constant.service';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  constructor(
    private http: HttpClient,
    private constantService: ConstantService
  ) {
  }

  getPaymentToPartners(params?: any) {
    return this.http.get(this.constantService.getUrl(`${this.constantService.ACTION}/payments/partner`), {
      params: params,
      observe: 'response'
    }).map((resp: any) => {
      let count = resp.headers.get('X-Total-Count');
      return {count: parseInt(count), data: resp.body.data};
    });
  }

  search(q,body = {}) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.SEARCH}?q=${q}`), body);
  }  
  
}
