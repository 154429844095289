import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http/src/response';
import {BehaviorSubject, Observable} from 'rxjs';
import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';

import {ConstantService} from '../../constant/constant.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  applicationState;
  formState;
  private changeCount = new BehaviorSubject({});
  currentCount = this.changeCount.asObservable();

  constructor(
    private http: HttpClient,
    private constantService: ConstantService
  ) {
  }

  updateCount(params) {
    this.changeCount.next(params);
  }

  get(param: Array<object>) {
    if (!param.length) return this.http.get(this.constantService.getUrl(this.constantService.APPLICATIONS));
    else return this.http.get(this.constantService.getUrlByQuery(this.constantService.APPLICATIONS, param));
  }

  setApplicationState(data) {
    this.applicationState = data;
  }

  saveFormState(data) {
    this.formState = data;
  }

  getFormState(): any {
    return this.formState;
  }

  getApplicationState() {
    return this.applicationState;
  }

  getApplications(params?: any) {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATIONS), {
      params: params,
      observe: 'response'
    }).map((resp: any) => {
      let count = resp.headers.get('X-Total-Count');
      return {count: parseInt(count), data: resp.body.data};
    });
  }

  getAllApplications(page: number = 0, limit: number = 0): Observable<any> {
    const url = `${this.constantService.getUrl(this.constantService.APPLICATION)}?page=${page}&limit=${limit}`;
    return this.http.get<any>(url, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        return { data: resp.body.data };
      })
    );
  }
  

  save(body){
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+ `/save`), body);
  }

  draf(body){
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+ `/draf`), body);
  }

  update(id, body){
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION + `/update/${id}`), body);
  }

  downloadExcel(params?: any): Observable<HttpResponse<Blob>> {
    params.export = true;
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION + '/download/credit/excel'), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }
  
  download(id, params?: any): Observable<HttpResponse<Blob>> {
    params.export = true;
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/download`), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }

  generateVPDLinks(id){
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+ `/${id}/generateVPD`),{});
  }

  getVPDDetails(id){
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION+ `/${id}/getVPDDetails`),{});
  }

  deleteVPDLinks(id){
    return this.http.delete(this.constantService.getUrl(this.constantService.APPLICATION+ `/${id}/deleteVPDLinks`),{});
  }

  getById(id, params) {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}`),{
      params: params,
      observe: 'response'
    }).map((resp: any) => {
      return { data: resp.body.data };
    });
  }

  getByLoanApplicationId(id) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}`),{});
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/repayschedule`),{});
  }

  markAsApplicant(params) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.APPLICATION}/markAsApplicant`),params);
  }
  
  statusoverview(id) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/statusoverview`),{});
  }

  repaymentSchedule(id) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/repayschedule`),{});
  }

  getBankDetailByIFSC(IFSCCode) {
    return this.http.get(`https://ifsc.razorpay.com/${IFSCCode}`,{}).map((resp: any) => {
      return { data: resp.body.data };
    });
  }

  getKyc(id,customerid,kyctype) {
    let params = { "customerId": customerid , "kycType" : kyctype };
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/kyc`), params);
  }

  getKycDetail(id,customerid,kyctype) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/kycdetail`),{ "customerId": customerid , "kycType" : kyctype });
  }

  uploadPropertyDoc(id, body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.APPLICATION}/upload`), body);
  }

  getDocuments(id) {
    return this.http.get(this.constantService.getUrl(`${this.constantService.APPLICATION}/${id}/document`));
  }

  saveDocument(id, body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.APPLICATION}/${id}/document`), body);
  }

  removeDocument(id,body = {}) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.APPLICATION}/${id}/deleteDoc`), body);
  }

  verifyDocument(id, body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.APPLICATION}/${id}/verifydocument`), body);
  }

  approveReject(id, body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.APPLICATION}/${id}/approveReject`), body);
  }

  getSignedUrl(body) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + '/signedurl'), body);
  }

  verifyPan(body){
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + '/verifyPan'), body);
  }

  sendOTP(body){
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + '/sendotp'), body);
  }
  
  verifyOTP(body){
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + '/verifyotp'), body);
  }

  post(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.TINYURL), body);
  }

  modifyInfo(id, block, body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +`/${id}/${block}/update`), body);
  }
  
  removeInfo(id, block, body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +`/${id}/${block}/remove`), body);
  }
  
  getInfo(id, block, body: Object = {}) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +`/${id}/${block}/info`), body);
  }
  
  updateBrokenPeriod(id) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +`/${id}/updateBrokenPeriod`), {});
  }
  
  processtLoan(id, block, body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +`/${id}/${block}`), body);
  }
  
  cancelCloseLoan(id, block, body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +`/${id}/${block}`), body);
  }
  
  genreateLoan(id) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +`/${id}/generate`),{});
  }

  verifyReferences(id, body) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +`/${id}/verifyReferences`), body);
  }
  
  initiateBSA(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +'/initiateBSA'), body);
  }

  getBSAScore(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +'/bsascorecard'), body);
  }

  initiateCreditCheck(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +'/initiatecreditCheck'), body);
  }

  initiateCibilCreditCheck(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +'/initiateCibilCreditCheck'), body);
  }

  tcplscore(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +'/tcplscore'), body);
  }

  initiateEsign(id, body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +`/${id}/esign`), body);
  }

  getSignedDoc(id, body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +`/${id}/signedDoc`), body);
  }

  uploadeSignDoc(id, body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +`/${id}/esigndoc`), body);
  }

  regenesigndoc(id) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +`/${id}/regenesigndoc`), {});
  }

  esignhistory(id) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +`/${id}/esignhistory`), {});
  }

  geteSigndoc(id, params) {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION +`/${id}/esigndoc`), { params: params });
  }

  downloadHardReport(id, body) : Observable<HttpResponse<Blob>> {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/downloadhardreport`), {
      params: body,
      observe: 'response',
      responseType: 'blob'
    });
  }
  
  getRecommendation(id) {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/recommendation`));
  }

  getDescrepancy(id) {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/descrepancy`));
  }

  creditRecommendation(body){
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + `/recommendation`),  body);
  }

  initiateDescrepancy(body){
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + `/descrepancy`),  body);
  }

  getObligation(id) {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/obligation`));
  }

  customerObligation( body){
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + `/obligation`),  body);
  }

  approveRejectRecommendation(id, body){
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/recommendation`),  body);
  }

  actVerifyDiscrepancy(id, body){
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/discrepancy`),  body);
  }

  approveRejectObligation(id, body){
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/obligation`),  body);
  }
  
  initiatecKyc(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION +'/initiatecKyc'), body);
  }
  
  initiatemandate(params : any ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+"/initateMandate"), params);
  }

  mandateHistory(id, params : any = {} ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/mandatehistory`), params);
  }

  changeMandateStatus(id, params : any = {} ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/mandatestatus`), params);
  }
  
  approveRejectMandate(id, params : any = {} ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/verifymandate`), params);
  }

  resendMandateLink(id, params : any = {} ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/resendmandatelink`), params);
  }

  mandatestatus(params : any = {} ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+`/mandatestatus`), params );
  }

  getCheckList(id : any ) {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/checklist`));
  }

  updateChecklist(params : any ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+"/checklist"), params);
  }

  comment(params : any ) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION+"/checklist"), params);
  }

  getDeviationList(id : any ) {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/deviation`));
  }

  updateDeviationList(params : any ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+"/deviation"), params);
  }

  deviationComment(params : any ) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION+"/deviation"), params);
  }

  removeDeviation(params : any ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+"/deviation/delete"), params);
  }

  getCharges(id, params) {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/charges`), params);
  }

  getChargesQr(id, params) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/paymentqr`), params);
  }

  addCharges(params : any ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+"/charges"), params);
  }
  
  assignCharges(params : any ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+"/charges/assign"), params);
  }

  verifiyCharges(params : any ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+"/charges/verify"), params);
  }

  cancelCharges(params : any ) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION+"/charges"), params);
  }

  getInsurances(id : any ) {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/insurance`));
  }

  updateInsurance(params : any ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+"/insurance"), params);
  }

  cancelInsurance(params : any ) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION+"/insurance"), params);
  }

  removeInsurance(params : any ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+"/insurance/remove"), params);
  }

  removeNominees(params : any ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+"/nominees/remove"), params);
  }

  getTranches(id : any ) {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/tranches`));
  }

  getPaymentCharges(id : any ) {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/payments`));
  }

  getLoan(id : any ) {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/loan`));
  }

  updateTranches(params : any ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+"/tranches"), params);
  }

  updateTranchDetail(params : any ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+"/tranchdetail"), params);
  }

  getFoirData(params : any ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+"/foir"), params);
  }

  updateFoirData(params : any ) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION+"/foir"), params);
  }

  auditlog(id, params : any = {} ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/audit`), params);
  }

  getVeriProcesses(id) {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/getverificationprocesses`));
  }
  
  startVerification(id, params : any = {} ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/startverification`), params);
  }
  
  processMailContent(id, params : any = {} ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/processmailcontent`), params);
  }

  assingManger(id, params : any = {} ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/assingManger`), params);
  }

  loadPSL(id, params : any = {} ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/psl`), params);
  }

  modifyPSL(id, params : any = {} ) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/psl`), params);
  }

  checkdeviation(id, params : any = {} ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+`/${id}/checkdeviation`), params);
  }
  getPropertyPrice(params : any = {} ) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION+`/propertyheatmap`), params);
  }

  /************************************************************/
  waiverProcessingFee(id, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/waiver/processingFee`), body);
  }

  approveLoan(id, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/approve`), body);
  }

  syncBre(body) {
    let id = body.loanApplicationId;
    return this.http.post(this.constantService.getUrl(this.constantService.SYNCBCPARTNER + `/${id}/syncBre`), body);
  }

  upgradeLoan(id, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/override`), body);
  }

  getBranchClassification(id) {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/branchClassifications`));
  }

  getTenureList(id, loanAmount) {
    return this.http.get(this.constantService.getUrlByQuery(`${this.constantService.BRANCHES}/tenures/${id}`, [{
      name: 'amount',
      value: loanAmount
    }]));
  }

  waiveEmandate(id, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/skipEmandate`), body);
  }

  waiverAdvanceToTcpl(id, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/waiver/advanceToTCPL`), body);
  }

  holdApplication(id, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/holdApplication`), body);
  }

  unholdApplication(id, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/unholdApplication`), body);
  }

  enableOfflineKyc(id) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/enableOfflineKyc`), null);
  }

  paymentSchedule(id) {
    return this.http.get(this.constantService.getUrl(`${this.constantService.APPLICATION}/${id}/schedules`), {});
  }

  review(id,body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.APPLICATION}/${id}/review`), body);
  }

  updateReviewBucket(id,body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.APPLICATION}/${id}/changereview`), body);
  }

  assignReviewer(id, body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.APPLICATION}/${id}/assignreviewer`), body);
  }

  reviewComment(id,body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.APPLICATION}/${id}/reviewcomment`), body);
  }

  reviewList(id) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.APPLICATION}/${id}/reviewlist`), {});
  }

  eMandateChange(id) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/eMandateChange`), null);
  }
  
  getPartners(params) {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION + '/partnerList'), {params});
  }

  getBranch(params) {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION + '/branchList'), {params});
  }

  getApplicationCount(body) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + '/counts'), body);
  }
  
  getInApprovalCount(body) {
    return this.http.post(this.constantService.getUrl(this.constantService.APPLICATION + '/inapprovalcounts'), body);
  }

  updateCreditRecommendationLog(id, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/creditRecommendationLog`), body);
  }

  updateDiscrepancyLog(id, body:any) {
    return this.http.put(this.constantService.getUrl(this.constantService.APPLICATION + `/${id}/discrepancyLog`), body);
  }

  downloadEmiExcel(id): Observable<HttpResponse<Blob>> {
    return this.http.get(this.constantService.getUrl(this.constantService.APPLICATION +`/${id}`+'/downloadEmi/excel'), {
      observe: 'response',
      responseType: 'blob'
    });
  }
}
