import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http/src/response';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs';

import {ConstantService} from '../../constant/constant.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private constantService: ConstantService,
    private http: HttpClient
  ) {
  }

  customerCounts() {
    return this.http.post(this.constantService.getUrl(this.constantService.CUSTOMERS + `/customercount`),{});
  }

  get(params: Array<object>) {
    if (!params.length) return this.http.get(this.constantService.getUrl(this.constantService.CUSTOMERS));
    else return this.http.get(this.constantService.getUrlByQuery(this.constantService.CUSTOMERS, params));
  }

  getUsers(params?: any) {
    return this.http.get(this.constantService.getUrl(this.constantService.CUSTOMERS), {
      params: params,
      observe: 'response'
    }).map((resp: any) => {
      let count = resp.headers.get('X-Total-Count');
      return {count: parseInt(count), data: resp.body.data};
    });
  }

  downloadExcel(params?: any): Observable<HttpResponse<Blob>> {
    params.export = true;
    return this.http.get(this.constantService.getUrl(this.constantService.CUSTOMERS+ '/download/excel'), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }
  
  
  existing(body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.CUSTOMERS}/existing`),body);
  }
  
  getExisting(id) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.CUSTOMERS}/${id}/get`),{});
  }

  getAddressProofPathS3Customer(id) {
    return this.http.get(this.constantService.getUrl(this.constantService.CUSTOMERS + `/${id}/addressProof/signed`));
  }

  getIdProofPathS3Customer(id) {
    return this.http.get(this.constantService.getUrl(this.constantService.CUSTOMERS + `/${id}/idProof/signed`));
  }

  getByCustomerId(data) {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + `?customer=${data}`));
  }

  getCustomerLoans(data) {
    return this.http.get(this.constantService.getUrl(this.constantService.LOANS + `/consumerloans?customer=${data}`));
  }

  downloadReport(id) {
    return this.http.get(this.constantService.getUrl(`${this.constantService.CUSTOMERS}/${id}/creditReport`), {
      responseType: 'blob', observe: 'response'
    });
  }

  downloadReportXml(id) {
    return this.http.get(this.constantService.getUrl(`${this.constantService.CUSTOMERS}/${id}/creditReportXml`), {
      responseType: 'blob', observe: 'response'
    });
  }

  updateCustomer(custId, body) {
    return this.http.put(this.constantService.getUrl(`${this.constantService.CUSTOMERS}/${custId}`), body);
  }

  updateCustomerAndServiceUserDetails(custId, loanApplicationId, body) {
    return this.http.put(this.constantService.getUrl(`${this.constantService.CUSTOMERS}/${custId}/loanApplication/${loanApplicationId}`), body);
  }

  getCustomerDetails(id: number) {
    return this.http.get(this.constantService.getUrl(`${this.constantService.CUSTOMERS}/${id}`));
  }

  getCityNameByPincode(pincode) {
    return this.http.get(this.constantService.getUrl(`${this.constantService.PINCODE}/lookup`, [pincode]));
  }

  getPanInfo(id) {
    return this.http.get(this.constantService.getUrl(`${this.constantService.CUSTOMERS}/${id}/pan`));
  }

  updateKyc(id,body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.CUSTOMERS}/${id}/kyc`),body);
  }
}
