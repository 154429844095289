import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToasterService } from 'angular2-toaster';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToasterAlertService } from '../../notification/toastAlert.service';
import { UserService } from '../../service/user/user.service';

@Component({
    selector: 'app-search-user',
    templateUrl: './search-user.component.html',
    styleUrls: ['./search-user.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchUserComponent),
            multi: true
        }
    ]
})
export class SearchUserComponent implements OnInit, OnChanges, ControlValueAccessor {
    @Input() label: any = 'Search User';
    @Input() placeholder: any = '';
    @Input() display: any = true;
    @Input() initialDisplay: any = "";
    @Input() returnValue: any = null;
    @Input() styleClass: any = "";
    @Input() filter: any = {};
    @Input() returnRawInput: any = false;
    
    @Output() processUser = new EventEmitter();

    public _inputvalue: any = "";
    public disabled = false;
    public required = false;
    public showSearchList = false;
    public searchTimeout = null;
    public selectedUser : any = {};
    public userList : any = [];

    public value: any = "";

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private toasterService: ToasterAlertService,
        private userService: UserService
    ) {
    }

    ngOnInit() {
        if ( this.initialDisplay && this.initialDisplay.subscribe){
            this.initialDisplay.subscribe( o => {
                this._inputvalue = this.initialDisplay;
            });
        }
    }

    ngOnChanges() {
        this._inputvalue = this.initialDisplay;
    }

    searchUser(event) {
        let oThat = this;
        this.selectedUser = null;
        let search = event.target.value;
        if (search) {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(function () {
                oThat.userService.getRelevantUsers({ ...oThat.filter, q: search }).subscribe((res: any) => {
                    oThat.userList = res.data;
                });
            }, 1000);
        }
    }

    selectUser(user) {
        this._inputvalue = this.display.map( o => user[o] ).join(" ");
        this.selectedUser = user;
        this.onChange( this.returnValue?(this.selectedUser[this.returnValue] || "") : user);
        this.processUser.emit(this.selectedUser);
        this.showSearchList = false;
    }

    hideSearch() {
        let oThat = this;
        setTimeout(function () { 
            oThat.showSearchList = false; 
        },1000);
    }

    onChange: any = () => { };
    onTouched: any = () => { };

    updateCallback($event = null) {
        this.onChange( this.returnValue?(this.selectedUser[this.returnValue] || "") : "");
    }

    processRawInput() {
        this.onChange(this._inputvalue);
        this.processUser.emit(this._inputvalue);                    
    }

    writeValue(value: any): void {
        this._inputvalue = this.initialDisplay;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }
}