import { APPLICATION_APPROVAL_ACTION } from "./commonConst.service";

export const AccessCodeService = {

  ANYMODULE: ["ANYMODULE"],

  DASHBOARD: ["DASHBOARD"], //COMP

  /** submodules **/
  DASHBOARD_ALL: ["DASHBOARD", "ALL"], //COMP
  DASHBOARD_ENQUIRY: ["DASHBOARD", "ENQUIRY"], // COMP
  DASHBOARD_POS_BTN_EXPORT: ["DASHBOARD", "DASHBOARD-POS", "BTN-POS-EXPORT"],

  APPLICATION: ["APPLICATION"], // COMP

  /** submodules **/
  APPLICATION_DETAILS: ["APPLICATION", "DETAILS"], // COMP
  APPLICATION_STAGE: ["APPLICATION", "STAGE"], // COMP
  APP_IN_BOOKING: ["APPLICATION", "IN-BOOKING"],  // COMP
  APP_NEW_APPLICATION: ["APPLICATION", "NEW-APPLICATION"], // COMP
  APP_CANCEL_REFUSED: ["APPLICATION", "CANCEL-REFUSED"], // COMP
  APP_DOCUMENT_PENDING: ["APPLICATION", "DOCUMENT-PENDING"], // COMP
  APP_CREDIT_REVIEW: ["APPLICATION", "CREDIT-REVIEW"], // COMP
  APP_FINANCIAL_APPROVAL: ["APPLICATION", "FINANCIAL-APPROVAL"], // COMP
  APP_TECHNICAL_APPROVAL: ["APPLICATION", "TECHNICAL-APPROVAL"], // COMP
  APP_LEGAL_APPROVAL: ["APPLICATION", "LEGAL-APPROVAL"], // COMP
  APP_IN_VERIFICATION: ["APPLICATION", "IN-VERIFICATION"], // COMP

  APP_ALL: ["APPLICATION", "ALL"], // COMP
  APP_NEW: ["APPLICATION", "NEW"], // COMP
  APP_NEW_INITIATE_BSA: ["APPLICATION", "NEW", "INITIATE-BSA"],
  APP_ALL_BTN_ENABLE_DELETE_VPDLINK: ["APPLICATION", "ALL", "BTN-ENABLE-VPDLINK"],
  APP_NEW_SAVE_AS_DRAFT: ["APPLICATION", "NEW", "SAVE-AS-DRAFT"], // COMP
  APP_NEW_SUBMIT: ["APPLICATION", "NEW", "SUBMIT"], // COMP
  APP_NEW_CANCEL: ["APPLICATION", "NEW", "CANCEL"], // COMP
  APP_NEW_DOWNLOAD_AA_REPORT: ["APPLICATION", "NEW", "DOWNLOAD_AA_REPORT"],

  APP_WIP_PARTNER_STAGE: ["APPLICATION", "WIP-PARTNER-STAGE"],
  APP_WIP_CUST_STAGE: ["APPLICATION", "WIP-CUST-STAGE"],
  APP_WIP_LAPS_REJECT: ["APPLICATION", "WIP-LAPS-REJECT"],
  APP_UNDER_CREDIT_REVIEW: ["APPLICATION", "UNDER-CREDIT-REVIEW"],
  APP_UNDER_SALES_REVIEW: ["APPLICATION", "UNDER-SALES-REVIEW"],

  /** Control **/
  APP_ALL_BTN_WAIVE_PROCESS_FEES: ["APPLICATION", "ALL", "BTN-WAIVE-PROCESS-FEE"],
  APP_ALL_BTN_WAIVE_ADVANCE_TO_TCPL: ["APPLICATION", "ALL", "BTN-WAIVE-ADVANCE-TO-TCPL"],
  APP_ALL_BTN_APPROVE: ["APPLICATION", "ALL", "BTN-APPROVE"],
  APP_ALL_BTN_UPGRADE: ["APPLICATION", "ALL", "BTN-UPGRADE"],
  APP_ALL_BTN_WAIVE_EMANDATE: ["APPLICATION", "ALL", "BTN-WAIVE-E-MANDATE"],
  APP_ALL_BTN_CANCEL_APPLICATION: ["APPLICATION", "ALL", "BTN-CANCEL-APPLICATION"],
  APP_ALL_BTN_REFUSE_APPLICATION: ["APPLICATION", "ALL", "BTN-REFUSE-APPLICATION"],
  APP_ALL_BTN_SYNC_BRE: ["APPLICATION", "ALL", "BTN-SYNC-BRE"],
  APP_ALL_BTN_REJECT_APPLICATION: ["APPLICATION", "ALL", "BTN-REJECT-APPLICATION"],
  APP_ALL_BTN_OFFLINE_KYC_APPLICATION: ["APPLICATION", "ALL", "BTN-OFFLINE-KYC-APPLICATION"],
  APP_ALL_BTN_ENABLE_RAZORPAY: ["APPLICATION", "ALL", "BTN-ENABLE-RAZORPAY"],
  APP_ALL_BTN_ENABLE_DIGIO: ["APPLICATION", "ALL", "BTN-ENABLE-DIGIO"],
  APP_ALL_PRINT_DOCUMENT: ["APPLICATION", "PRINT-DOCUMENT"], // COMP
  APP_ALL_BTN_EXP_TO_EXCEL: ["APPLICATION", "ALL", "BTN-EXPORT-TO-EXCEL"], // COMP
  RPS_PDF_BTN_EXP_TO_EXCEL: ["APPLICATION", "ALL", "RPS-PDF-BTN-EXP-TO-EXCEL"],// COMP

  /**************************************************/

  LOAN: ["LOAN"], // COMP
  LOAN_ALL: ["LOAN", "ALL"],  // COMP
  LOAN_BUCKET: ["LOAN", "LOAN-BUCKET"], // COMP
  LOAN_IN_VERIFICATION: ["LOAN", "IN-VERIFICATION"], // COMP
  LOAN_VERIFIED: ["LOAN", "VERIFIED"], //COMP
  LOAN_ACTIVE: ["LOAN", "ACTIVE"], // COMP
  LOAN_CLOSED: ["LOAN", "CLOSED"], // COMP
  LOAN_CANCEL_REJECT: ["LOAN", "CANCELED-REJECTED"], // COMP
  LOAN_RUNNING: ["LOAN", "RUNNING"],
  LOAN_DETAIL: ["LOAN", "LOAN-DETAIL"], // COMP

  /** Control **/
  LOAN_ALL_BTN_EXP_TO_EXCEL: ["LOAN", "ALL", "BTN-EXPORT-TO-EXCEL"], // COMP
  CREDIT_DETAIL: ["LOAN", "LOAN-DETAIL", "CREDIT-DETAIL"], // COMP
  DISCREPANCY_DETAIL: ["LOAN", "LOAN-DETAIL", "DISCREPANCY-DETAIL"], // COMP

  LOAN_VERIFY_BTN_VERIFY: ["LOAN", "VERIFY", "BTN-VERIFY-LOAN"],
  LOAN_VERIFY_BTN_DOCS_PENDING: ["LOAN", "VERIFY", "BTN-DOCS-PENDING"],
  LOAN_VERIFY_BTN_OFFLINE_PAYMENT: ["LOAN", "VERIFY", "BTN-OFFLINE_PAYMENT"],
  LOAN_ALL_BTN_ENABLE_ENACH: ["LOAN", "ALL", "BTN-ENABLE_ENACH"],
  LOAN_ALL_BTN_CLOSELOAN: ["LOAN", "ALL", "BTN-CLOSE-LOAN"],
  LOAN_ALL_BTN_CANCEL_INSURANCE: ["LOAN", "ALL", "BTN-CANCEL-INSURANCE"],
  LOAN_ALL_BTN_REBOOKING: ["LOAN", "ALL", "BTN-REBOOKING"],
  LOAN_ALL_BTN_CANCELLOAN: ["LOAN", "ALL", "BTN-CANCEL-LOAN"],
  LOAN_ALL_BTN_OFFLINE_TANDC: ["LOAN", "ALL", "BTN-OFFLINE-TANDC"],
  LOAN_ALL_BTN_SYNC_LOAN: ["LOAN", "ALL", "BTN-SYNC-LOAN"],
  LOAN_ALL_SUBVENTION_AMOUNT: ["LOAN", "ALL", "BTN-SUBV-PAYMENT"],
  LOAN_ALL_BTN_EDITCUST: ["LOAN", "ALL", "BTN-EDIT-CUSTOMER"],
  LOAN_ALL_DOWNLOAD_PRECLOSER: ["LOAN", "ALL", "BTN-PRECLOSER-STATEMENT-EXCEL"],
  LOAN_ALL_EXTEND_BITYL_EXPIRY: ["LOAN", "ALL", "BTN-EXTEND-BITYL-EXPIRY"],
  LOAN_ALL_PRINT_DOCUMENT: ["LOAN", "ALL", "PRINT-DOCUMENT"],

  LOAN_DETAIL_OFFLINE_PAYMENT: ["LOAN", "LOAN-DETAIL", "BTN-OFFLINE-PAYMENT"],
  LOAN_DETAIL_MANDATE_LINK: ["LOAN", "LOAN-DETAIL", "BTN-MANDATE-LINK"],
  LOAN_DETAIL_UPDATE_NEW_TOKEN: ["LOAN", "LOAN-DETAIL", "UPDATE_NEW_TOKEN"],
  LOAN_DETAIL_DOCUMENTS_BC_PARTNER: ["LOAN", "LOAN-DETAIL", "UPLOAD_BC_DOCUMENTS"],
  LOAN_DETAIL_FETCH_UTR_BC_PARTNER: ["LOAN", "LOAN-DETAIL", "FETCH_UTR_BC_PARTNER"],
  LOAN_DETAIL_BTN_CANCEL_PAYMENT: ["LOAN", "LOAN-DETAIL", "BTN-CANCEL-PAYMENT"],
  LOAN_DETAIL_BTN_ADD_BOUNCE_CHARGE: ["LOAN", "LOAN-DETAIL", "BTN-ADD-BOUNCE-CHARGES"],
  LOAN_DETAIL_ADJUST_EMI: ["LOAN", "LOAN-DETAIL", "BTN-ADJUST-EMI"],
  LOAN_DETAIL_CREATE_PAYMENT_INFO: ["LOAN", "LOAN-DETAIL", "BTN-CREATE-PAYMENT-INFO"],
  LOAN_DETAIL_CAPTURE_PAYMENT_INFO: ["LOAN", "LOAN-DETAIL", "BTN-CAPTURE-PAYMENT-INFO"],
  LOAN_DETAIL_PAYMENT_REMAINING: ["LOAN", "LOAN-DETAIL", "BTN-PAYMENT-REMAINING"],
  LOAN_DETAIL_EMIS_PAID: ["LOAN", "LOAN-DETAIL", "BTN-EMIS-PAID"],
  LOAN_DETAIL_TRANSACTIONS: ["LOAN", "LOAN-DETAIL", "BTN-TRANSACTIONS"],
  LOAN_DETAIL_PENALTY: ["LOAN", "LOAN-DETAIL", "BTN-PENALTY"],
  LOAN_DETAIL_BOUNCE_CHARGES: ["LOAN", "LOAN-DETAIL", "BTN-BOUNCE-CHARGES"],
  LOAN_DETAIL_TECHFINO_SCORECARD: ["LOAN", "LOAN-DETAIL", "BTN-TECHFINO-SCORECARD"],
  LOAN_DETAIL_EMI_ADJUSTMENT: ["LOAN", "LOAN-DETAIL", "BTN-EMI-ADJUSTMENT"],
  LOAN_DETAIL_MANDATE_LINK_HISTORY: ["LOAN", "LOAN-DETAIL", "BTN-MANDATE-LINK-HISTORY"],
  LOAN_DETAIL_SOA: ["LOAN", "LOAN-DETAIL", "BTN-SOA"],
  LOAN_DETAIL_WEBHOOK_LOGS: ["LOAN", "LOAN-DETAIL", "BTN-WEBHOOK-LOGS"],
  LOAN_DETAIL_EMI_SCHEDULES: ["LOAN", "LOAN-DETAIL", "BTN-EMI-SCHEDULES"],
  LOAN_OFFLINE_PAYMENT: ["LOAN", "LOAN-DETAIL", "OFFLINE-PAYMENT"],

  /**************************************************/

  COLLECTION: ["COLLECTION"], // COMP
  COLLECTION_ALL: ["COLLECTION", "ALL"], // COMP
  COLLECTION_ALL_EXPORT_EXCEL: ["COLLECTION", "ALL", "EXPORT-TO-EXCEL"], // COMP
  COLLECT_PAYMENTS: ["COLLECTION", "PAYMENTS"], // COMP
  COLLECT_DUELIST: ["COLLECTION", "DUELIST"], // COMP
  COLLECT_PRESENTATION: ["COLLECTION", "PRESENTATION"], // COMP
  COLLECT_DELINQUENT: ["COLLECTION", "DELINQUENT"], // COMP
  COLLECT_DELINQUENT_BTN_PAYMENT_LINK: ["COLLECTION", "DELINQUENT", "BTN-CREATE-PAYMENT-LINK"],
  COLLECT_BULK_OFFLINE_PAYMENT: ["COLLECTION", "ALL", "BULK-OFFLINE-PAYMENT"],

  /**************************************************/

  EMPLOYEE: ["EMPLOYEE"], // COMP
  EMPLOYEE_ALL: ["EMPLOYEE", "ALL"], // COMP
  EMPLOYEE_PROFILE: ["EMPLOYEE", "PROFILE"], // COMP
  EMPLOYEE_ROLE: ["EMPLOYEE", "ROLE"],
  EMPLOYEE_MODULE: ["EMPLOYEE", "MODULE"],
  EMPLOYEE_PERMISSION: ["EMPLOYEE", "PERMISSION"],

  /** Control **/
  EMPLOYEE_ALL_ADD: ["EMPLOYEE", "ALL", "BTN-ADD"], // comp
  EMPLOYEE_PROFILE_EDIT: ["EMPLOYEE", "PROFILE", "BTN-EDIT"], // comp
  EMPLOYEE_PROFILE_STATUS: ["EMPLOYEE", "PROFILE", "CHANGE-STATUS"], // COMP
  EMPLOYEE_PROFILE_RESET_PASSWORD: ["EMPLOYEE", "PROFILE", "BTN-RESET-PASSWORD"], // COMP
  EMPLOYEE_PROFILE_EDIT_ACCESS: ["EMPLOYEE", "PROFILE", "BTN-EDIT-ACCESS"], // COMP
  EMPLOYEE_PROFILE_DELETE_ACCESS: ["EMPLOYEE", "PROFILE", "BTN-DELETE-ACCESS"], // COMP
  EMPLOYEE_SWITCH_PROFILE: ["EMPLOYEE", "PROFILE", "BTN-SWITCH-PROFILE"], // COMP
  EMPLOYEE_MODULE_ADD: ["EMPLOYEE", "MODULE", "BTN-ADD"],
  EMPLOYEE_MODULE_EDIT: ["EMPLOYEE", "MODULE", "BTN-EDIT"],

  EMPLOYEE_PERMISSION_ADD: ["EMPLOYEE", "PERMISSION", "BTN-ADD"],
  EMPLOYEE_PERMISSION_UPDATE: ["EMPLOYEE", "PERMISSION", "BTN-UPDATE"],
  EMPLOYEE_PERMISSION_DELETE: ["EMPLOYEE", "PERMISSION", "BTN-DELETE"],
  EMPLOYEE_MODULE_UPDATE: ["EMPLOYEE", "MODULE", "BTN-UPDATE"],
  EMPLOYEE_MODULE_DELETE: ["EMPLOYEE", "MODULE", "BTN-DELETE"],
  EMPLOYEE_ROLE_ADD: ["EMPLOYEE", "ROLE", "BTN-ADD"],
  EMPLOYEE_ROLE_UPDATE: ["EMPLOYEE", "ROLE", "BTN-UPDATE"],
  EMPLOYEE_ROLE_DELETE: ["EMPLOYEE", "ROLE", "BTN-DELETE"],


  THEME: ["THEME"],

  ORGANIZATION: ["ORGANIZATION"],
  ORGANIZATION_VIEW_LIST: ["ORGANIZATION", "ALL"],
  ORGANIZATION_VIEW_DETAIL: ["ORGANIZATION", "ORGANIZATION-VIEW-DETAILS"],
  ORGANIZATION_BTN_ADD: ["ORGANIZATION", "ORGANIZATION-VIEW-DETAILS", "BTN-ADD"],
  ORGANIZATION_BTN_UPDATE: ["ORGANIZATION", "ORGANIZATION-VIEW-DETAILS", "BTN-UPDATE"],
  ORGANIZATION_BTN_DELETE: ["ORGANIZATION", "ORGANIZATION-VIEW-DETAILS", "BTN-DELETE"],
  ORGANIZATION_BTN_DEACTIVATE: ["ORGANIZATION", "ORGANIZATION-VIEW-DETAILS", "BTN-DEACTIVATE"],
  ORGANIZATION_BTN_CUST_JOURNEY: ["ORGANIZATION", "ORGANIZATION-VIEW-DETAILS", "BTN-CUST-JOURNEY"],
  ORGANIZATION_UPDATE_TNC: ["ORGANIZATION", "ORGANIZATION-VIEW-DETAILS", "BTN-UPDATE-TNC"],
  ORGANIZATION_UPDATE_WELCOME_LETTER: ["ORGANIZATION", "ORGANIZATION-VIEW-DETAILS", "BTN-UPDATE-WELCOME-LETTER"],
  ORGANIZATION_BTN_EXP_TO_EXCEL: ["ORGANIZATION", "ALL", "BTN-EXP-TO-EXCEL"],

  LEADS: ["LEADS"], // COMP
  LEADS_CONNECTOR: ["LEADS", "CONNECTOR"], // COMP
  LEADS_ENQUIRY: ["LEADS", "ENQUIRY"], // COMP
  LEADS_ENQUIRY_CREATE: ["LEADS", "ENQUIRY", "CREATE"], // COMP
  LEADS_ENQUIRY_BTN_EXP_TO_EXCEL: ["LEADS", "ENQUIRY", "EXPORT-TO-EXPORT"], // COMP

  LEADS_VIEW_LIST: ["LEADS", "ALL"], // COMP
  LEADS_PROSPECT: ["LEADS", "PROSPECT"], // COMP
  PROSPECT_EXPORT_EXCEL: ["LEADS", "PROSPECT", "EXPORT-TO-EXCEL"], // COMP
  LEAD_VIEW_DETAIL: ["LEADS", "LEAD-DETAILS"],
  LEAD_BTN_RESEND_LINK: ["LEADS", "LEAD-DETAILS", "LEAD_RESEND_BITYL"],

  MANUAL_ACTIVITY: ["MANUAL_ACTIVITY"], // COMP
  RBAC: ["RBAC"], // COMP
  RBAC_ROLE: ["RBAC", "RBAC_ROLE"], // COMP
  RBAC_MODULE: ["RBAC", "RBAC_MODULE"], // COMP
  RBAC_ACCESS: ["RBAC", "RBAC_ACCESS"], // COMP

  TICKETS: ["TICKETS"],
  TICKETS_CUSTOMER: ["TICKETS", "CUSTOMER"],

  REPORTS: ["REPORTS"], // COMP
  REPORTS_DUE: ["REPORTS", "DUE"], // COMP
  REPORTS_COLLECTION: ["REPORTS", "COLLECTION"], // COMP

  CUSTOMERS: ["CUSTOMERS"], // COMP
  CUSTOMERS_ALL: ["CUSTOMERS", "ALL"], // COMP
  CUSTOMERS_ALL_EXPORT_EXCEL: ["CUSTOMERS", "ALL", "EXPORT-TO-EXCEL"], // COMP
  CONNECTORS: ["LEAD_CONNECTORS"], // COMP
  LEAD_CONNECTORS: ["LEAD_CONNECTORS"], // COMP
  LEAD_CONNECTORS_ALL: ["LEAD_CONNECTORS", "ALL"], // COMP
  LEAD_CONNECTORS_ADD: ["LEAD_CONNECTORS", "ALL", "ADD"], // COMP

  VENDORS: ["VENDORS"], // COMP
  VENDORS_ALL: ["VENDORS", "ALL"], // COMP
  VENDORS_ADD: ["VENDORS", "ALL", "ADD"], // comp
  VENDORS_SPOC_ADD: ["VENDORS", "ALL", "SPOC-ADD"], //COMP
  VENDORS_BANK_ADD: ["VENDORS", "ALL", "BANK-ADD"], //COMP 
  VENDORS_ALL_EXPORT_TO_EXCEL: ["VENDORS", "ALL", "EXPORT-TO-EXCEL"], //COMP 

  MORE: ["MORE"],
  MORE_INSURANCE: ["MORE", "INSURANCE"],
  MORE_BUREAUS: ["MORE", "BUREAUS"],

  ADMIN: ["ADMIN"], // COMP
  ADM_MASTERDATA: ["ADMIN", "MASTER-DATA"], // COMP
  ADM_MASTERDATA_PAYMENTMASTER: ["ADMIN", "MASTERDATA-PAYMENTMASTER"], // COMP
  ADM_CHECKLIST: ["ADMIN", "MASTERDATA-CHECKLIST"], // COMP
  ADM_APPROVAL_MATRIX: ["ADMIN", "MASTERDATA-APPROVAL-MATRIX"], // COMP
  ADM_DEVIATION_MATRIX: ["ADMIN", "MASTERDATA-DEVIATION-MATRIX"], // COMP
  ADM_TEMPLATE: ["ADMIN", "MASTERDATA-TEMPLATE"], // COMP
  ADM_LOD_MASTER: ["ADMIN", "MASTERDATA-LOD"], // COMP
  ADM_COLENDING_SCHEMA: ["ADMIN", "MASTERDATA-COLENDING-SCHEMA"], // COMP
  ADM_PINCODE: ["ADMIN", "MASTERDATA-PINCODE"], // COMP
  ADM_IFSCCODE: ["ADMIN", "MASTERDATA-IFSCCODE"], // COMP
  ADM_BRANCHES: ["ADMIN", "BRANCHES"], // COMP
  ADM_SETTINGS: ["ADMIN", "SETTINGS"], // COMP
  ADM_NOTIFICATION: ["ADMIN", "NOTIFICATION"], // COMP
  ADM_API_USERS: ["ADMIN", "APIUSERS"], // COMP
  ADM_CO_LENDER: ["ADMIN", "CO_LENDER"], // COMP

  ADM_SETTINGS_VALUES: ["ADMIN", "SETTINGS", "VALUES"], // COMP
  ADM_SETTINGS_WEIGHTMATRIX: ["ADMIN", "SETTINGS", "WEIGHT-MATRIX"], // COMP
  ADM_SETTINGS_AGGREGATOR_WEIGHTMATRIX: ["ADMIN", "SETTINGS", "AGGREGATOR-WEIGHT-MATRIX"], // COMP

  // LOAN-APP-DETAILS
  LOAN_APPLICATION: ["LOAN-APPLICATION"],
  LOAN_APP_OVERVIEW: ["LOAN-APPLICATION", "OVERVIEW"],
  LOAN_APP_SUMMARY: ["LOAN-APPLICATION", "SUMMARY"],
  LOAN_APP_INFO: ["LOAN-APPLICATION", "APP-INFO"],
  LOAN_APP_APPLICANT: ["LOAN-APPLICATION", "APPLICANT"],
  LOAN_APP_INSURANCE: ["LOAN-APPLICATION", "INSURANCE"],
  LOAN_APP_MANDATE: ["LOAN-APPLICATION", "MANDATE"],
  LOAN_APP_LOD: ["LOAN-APPLICATION", "LOD"],
  LOAN_APP_ESIGN: ["LOAN-APPLICATION", "ESIGN"],
  LOAN_APP_DOCUMENT: ["LOAN-APPLICATION", "APPDOCUMENT"],
  LOAN_APP_CREDIT: ["LOAN-APPLICATION", "CREDIT"],
  LOAN_APP_BANKING: ["LOAN-APPLICATION", "BANKING"],
  LOAN_APP_COLLECTION: ["LOAN-APPLICATION", "COLLECTION"],
  LOAN_APP_COLLECTION_OFFLINE_PAYMENT: ["LOAN-APPLICATION", "COLLECTION", "OFFLINE-PAYMENT"], 
  LOAN_APP_DISBURSEMENT_CHECKLIST: ["LOAN-APPLICATION", "DISBURSEMENT-CHECKLIST"],
  LOAN_APP_LEDGER: ["LOAN-APPLICATION", "LEDGER"],
  LOAN_APP_SERVICEREQUEST: ["LOAN-APPLICATION", "SERVICEREQUEST"],
  LOAN_APP_DISCREPANCY: ["LOAN-APPLICATION", "LOAN_APP_DISCREPANCY"],
  LOAN_APP_AUDITCHANGELOGS: ["LOAN-APPLICATION", "AUDITCHANGELOGS"],

  // control
  LOAN_APP_PRINTDOC_BTN: ["LOAN-APPLICATION", "SUMMARY", "PRINTDOC-BTN"],
  LOAN_APP_INFO_PROCESS_BTN: ["LOAN-APPLICATION", "APP-INFO", "PROCESS-BTN"],
  LOAN_APP_INFO_UPGRADE_BTN: ["LOAN-APPLICATION", "APP-INFO", "UPGRADE-BTN"],
  LOAN_APP_INFO_REFUSED_BTN: ["LOAN-APPLICATION", "APP-INFO", "REFUSED-BTN"],
  LOAN_APP_INFO_APPROVE_BTN: ["LOAN-APPLICATION", "APP-INFO", "APPROVE-BTN"],
  LOAN_APP_INFO_CANCEL_BTN: ["LOAN-APPLICATION", "APP-INFO", "CANCEL-BTN"],
  LOAN_APP_INFO_CLOSE_BTN: ["LOAN-APPLICATION", "APP-INFO", "CLOSE-BTN"],
  LOAN_APP_INFO_VERIFY_BTN: ["LOAN-APPLICATION", "APP-INFO", "VERIFY-BTN"],
  LOAN_APP_INFO_ASSIGN_SALES_MANAGER: ["LOAN-APPLICATION", "APP-INFO", "SALES-MANAGER-ASSIGN"],
  LOAN_APP_INFO_ASSIGN_CREDIT_MANAGER: ["LOAN-APPLICATION", "APP-INFO", "CREDIT-MANAGER-ASSIGN"],
  LOAN_APP_INFO_ASSIGN_AREA_SALES_MANAGER: ["LOAN-APPLICATION", "APP-INFO", "AREA_SALES_MANAGER_ASSIGN"],
  LOAN_APP_INFO_ASSIGN_BRANCH_SALES_MANAGER: ["LOAN-APPLICATION", "APP-INFO", "BRANCH_SALES_MANAGER_ASSIGN"],
  LOAN_APP_INFO_ASSIGN_CLUSTER_SALES_MANAGER: ["LOAN-APPLICATION", "APP-INFO", "CLUSTER_SALES_MANAGER_ASSIGN"],

  LOAN_APP_APPLICANT_EDIT_DETAILS: ["LOAN-APPLICATION", "APPLICANT", "EDIT-DETAILS"],
  LOAN_APP_APPLICANT_VIEW_ID_PROOF: ["LOAN-APPLICATION", "APPLICANT", "VIEW-ID-PROOF"],
  LOAN_APP_APPLICANT_VIEW_ADDRESS_PROOF: ["LOAN-APPLICATION", "APPLICANT", "VIEW-ADDRESS-PROOF"],
  LOAN_APP_APPLICANT_EDIT_CORRESPONDENCE_ADDRESS: ["LOAN-APPLICATION", "APPLICANT", "EDIT-CORRESPONDENCE-ADDRESS"],
  LOAN_APP_APPLICANT_EDIT_OFFICIAL_ADDRESS: ["LOAN-APPLICATION", "APPLICANT", "EDIT-OFFICIAL-ADDRESS"],
  LOAN_APP_APPLICANT_EDIT_EMP_DETAILS: ["LOAN-APPLICATION", "APPLICANT", "EDIT-EMP-DETAIL"],
  LOAN_APP_APPLICANT_EDIT_BUSINESS_DETAILS: ["LOAN-APPLICATION", "APPLICANT", "EDIT-BUSINESS-DETAIL"],
  LOAN_APP_APPLICANT_ADD_BUSINESS_DETAILS: ["LOAN-APPLICATION", "APPLICANT", "ADD-BUSINESS-DETAIL"],
  LOAN_APP_APPLICANT_INITIATE_CKYC: ["LOAN-APPLICATION", "APPLICANT", "INITIATE-CKYC"],
  LOAN_APP_APPLICANT_INITIATE_KYC: ["LOAN-APPLICATION", "APPLICANT", "INITIATE-KYC"],
  LOAN_APP_APPLICANT_LOAD_DOC: ["LOAN-APPLICATION", "APPLICANT", "LOAD-DOC"],
  LOAN_APP_APPLICANT_DOWNLOAD_DOC: ["LOAN-APPLICATION", "APPLICANT", "DOWNLOAD-DOC"],
  LOAN_APP_APPLICANT_ADD_OSV_DOC: ["LOAN-APPLICATION", "APPLICANT", "ADD-OSV-DOC"],
  LOAN_APP_APPLICANT_REPLACE_OSV_DOC: ["LOAN-APPLICATION", "APPLICANT", "REPLACE-OSV-DOC"],
  LOAN_APP_APPLICANT_SHOW_OSV_DOC: ["LOAN-APPLICATION", "APPLICANT", "SHOW-OSV-DOC"],

  LOAN_APP_INSURANCE_ADD_BTN: ["LOAN-APPLICATION", "INSURANCE", "ADD-INSURANCE-BTN"], // COMP
  LOAN_APP_REMOVE_NOMINEE_BTN: ["LOAN-APPLICATION", "APPLICANT", "REMOVE-NOMINEE-BTN"],

  LOAN_APP_DISCREPANCY_ADD_BTN: ["LOAN-APPLICATION", "LOAN_APP_DISCREPANCY","LOAN_APP_DISCREPANCY_CREATE-BTN"],


  LOAN_APP_CREDIT_BUREAU_REPORT_CIBIL_BTN: ["LOAN-APPLICATION", "CREDIT", "BUREAU-REPORT-CIBIL-BTN"],
  LOAN_APP_CREDIT_BUREAU_REPORT_CRIF_BTN: ["LOAN-APPLICATION", "CREDIT", "BUREAU-REPORT-CRIF-BTN"],
  LOAN_APP_CREDIT_BUREAU_REPORT_TCPLSCORE_BTN: ["LOAN-APPLICATION", "CREDIT", "BUREAU-REPORT-TCPLSCORE-BTN"],

  LOAN_APP_CREDIT_ADD_BANKDETAIL_BTN: ["LOAN-APPLICATION", "CREDIT", "ADD-BANKDETAIL-BTN"],
  LOAN_APP_CREDIT_EDIT_BANKDETAIL_BTN: ["LOAN-APPLICATION", "CREDIT", "EDIT-BANKDETAIL-BTN"],
  LOAN_APP_CREDIT_INITIATE_BSA_BTN: ["LOAN-APPLICATION", "CREDIT", "INITIATE-BSA-BTN"],
  LOAN_APP_CREDIT_DOWNLOAD_AA_REPORT: ["LOAN-APPLICATION", "CREDIT", "DOWNLOAD-AA-REPORT"],
  LOAN_APP_CREDIT_BSA_ANALYSED_EXCEL: ["LOAN-APPLICATION", "CREDIT", "BSA-ANALYSED-EXCEL"],
  LOAN_APP_CREDIT_FOIR_REMOVE_LOAN: ["LOAN-APPLICATION", "CREDIT", "FOIR-REMOVE-LOAN"],
  LOAN_APP_CREDIT_FOIR_LOAN_LIST: ["LOAN-APPLICATION", "CREDIT", "FOIR-LOAN-LIST"],
  LOAN_APP_CREDIT_FOIR_ADD_LOAN: ["LOAN-APPLICATION", "CREDIT", "FOIR-ADD-LOAN"],
  LOAN_APP_CREDIT_FOIR_UPDATE_BTN: ["LOAN-APPLICATION", "CREDIT", "FOIR-UPDATE-BTN"],
  LOAN_APP_CREDIT_FOIR_EDIT_INCOME: ["LOAN-APPLICATION", "CREDIT", "FOIR-EDIT-INCOME"],
  LOAN_APP_CREDIT_FOIR_REMOVE_INCOME: ["LOAN-APPLICATION", "CREDIT", "FOIR-REMOVE-INCOME"],
  LOAN_APP_CREDIT_EDIT_COLLATERAL: ["LOAN-APPLICATION", "CREDIT", "EDIT-COLLATERAL"],
  LOAN_APP_CREDIT_CREATE_CERSAI_REPORT_BTN: ["LOAN-APPLICATION", "CREDIT", "CREATE-CERSAI-REPORT-BTN"],
  LOAN_APP_CREDIT_CERSAI_REPORT_VIEW: ["LOAN-APPLICATION", "CREDIT", "CERSAI-REPORT-VIEW"],
  LOAN_APP_CREDIT_CERSAI_DIGITAL_PROPERTY_DOWNLOAD: ["LOAN-APPLICATION", "CREDIT", "CERSAI-DIGITAL-PROPERTY-DOWNLOAD"],
  LOAN_APP_CREDIT_CERSAI_DILIGENCE_REPORT_DOWNLOAD: ["LOAN-APPLICATION", "CREDIT", "CERSAI-DILIGENCE-REPORT-DOWNLOAD"],

  LOAN_APP_CREDIT_PSL_ADD_DETAIL: ["LOAN-APPLICATION", "CREDIT", "PSL-ADD-DETAIL"],
  LOAN_APP_CREDIT_PSL_EDIT_DETAIL: ["LOAN-APPLICATION", "CREDIT", "PSL-EDIT-DETAIL"],
  LOAN_APP_CREDIT_PSL_ADD_DOC: ["LOAN-APPLICATION", "CREDIT", "PSL-ADD-DOC"],
  LOAN_APP_CREDIT_PSL_UPLOAD_DOC: ["LOAN-APPLICATION", "CREDIT", "PSL-UPLOAD-DOC"],
  LOAN_APP_CREDIT_PSL_DOC_VIEW: ["LOAN-APPLICATION", "CREDIT", "PSL-DOC-VIEW"],
  LOAN_APP_CREDIT_PSL_EDIT_OWNERSHIP: ["LOAN-APPLICATION", "CREDIT", "PSL-EDIT-OWNERSHIP"],


  LOAN_APP_CREDIT_CXPD_NOTE_DOWNLOAD: ["LOAN-APPLICATION", "CREDIT", "CXPD-NOTE-DOWNLOAD"],
  LOAN_APP_CREDIT_CXPD_EDIT_OTHER: ["LOAN-APPLICATION", "CREDIT", "CXPD-EDIT-OTHER"],
  LOAN_APP_CREDIT_DEVIATION_DETAIL_ADD: ["LOAN-APPLICATION", "CREDIT", "DEVIATION-DETAIL-ADD"],

  LOAN_APP_CREDIT_RECOMMENDATION_LOG_ADD: ["LOAN-APPLICATION", "CREDIT", "RECOMMENDATION-LOG-ADD"],
  LOAN_APP_CREDIT_APPROVAL_INITIATE_BTN: ["LOAN-APPLICATION", "CREDIT", "APPROVAL-INITIATE-BTN"],
  LOAN_APP_CREDIT_APPROVAL_APPROVE_BTN: ["LOAN-APPLICATION", "CREDIT", "APPROVAL-APPROVE-BTN"],
  LOAN_APP_CREDIT_APPROVAL_ADD_DOC: ["LOAN-APPLICATION", "CREDIT", "APPROVAL-ADD-DOC"],
  LOAN_APP_CREDIT_APPROVAL_REPLACE_DOC: ["LOAN-APPLICATION", "CREDIT", "APPROVAL-REPLACE-DOC"],
  LOAN_APP_CREDIT_APPROVAL_VIEW_DOC: ["LOAN-APPLICATION", "CREDIT", "APPROVAL-VIEW-DOC"],

  LOAN_APP_MANDATE_INITATE: ["LOAN-APPLICATION", "MANDATE", "INITIATE"],
  LOAN_APP_MANDATE_REVIEW_SHOW: ["LOAN-APPLICATION", "MANDATE", "SHOW-REVIEW"],
  LOAN_APP_MANDATE_RESEND_LINK: ["LOAN-APPLICATION", "MANDATE", "RESEND-LINK"],
  LOAN_APP_MANDATE_PHYSICAL_ADD: ["LOAN-APPLICATION", "MANDATE", "MANDATE-PHYSICAL-ADD"],

  LOAN_APP_ESIGN_VIEW_BTN: ["LOAN-APPLICATION", "ESIGN", "VIEW-BTN"],
  LOAN_APP_ESIGN_SIGNED_DOC: ["LOAN-APPLICATION", "ESIGN", "SIGNED-DOC"],
  LOAN_APP_ESIGN_INITIATE: ["LOAN-APPLICATION", "ESIGN", "INITIATE"],
  LOAN_APP_ESIGN_UPLOAD_PHOTOS: ["LOAN-APPLICATION", "ESIGN", "UPLOAD-PHOTOS"],
  LOAN_APP_ESIGN_CHANGE_PHOTOS: ["LOAN-APPLICATION", "ESIGN", "CHANGE-PHOTOS"],
  LOAN_APP_ESIGN_REMOVE_PHOTOS: ["LOAN-APPLICATION", "ESIGN", "REMOVE-PHOTOS"],
  LOAN_APP_ESIGN_DOC: ["LOAN-APPLICATION", "ESIGN", "ESIGN-DOC"],

  LOAN_APP_DOC_VAULT_ADD: ["LOAN-APPLICATION", "APPDOCUMENT", "ADD"],
  LOAN_APP_DOC_UPLOAD_PHOTOS: ["LOAN-APPLICATION", "APPDOCUMENT", "UPLOAD-PHOTOS"],
  LOAN_APP_DOC_VERIFY: ["LOAN-APPLICATION", "APPDOCUMENT", "VERIFY-DOC"],
  LOAN_APP_DOC_VIEW: ["LOAN-APPLICATION", "APPDOCUMENT", "VIEW-DOC"],

  LOAN_APP_COLLECTION_REGENERATE_BTN: ["LOAN-APPLICATION", "COLLECTION", "REGENERATE-BTN"],
  LOAN_APP_COLLECTION_EXPORT_TO_PDF: ["LOAN-APPLICATION", "COLLECTION", "EXPORT-TO-PDF"],
  LOAN_APP_COLLECTION_EXPORT_TO_EXCEL: ["LOAN-APPLICATION", "COLLECTION", "EXPORT-TO-EXCEL"],
  LOAN_APP_COLLECTION_PAYMENT_CHARGES_CREATE: ["LOAN-APPLICATION", "COLLECTION", "PAYMENT-CHARGES-CREATE"],
  LOAN_APP_COLLECTION_CHARGES_COLLECTION_CREATE: ["LOAN-APPLICATION", "COLLECTION", "CHARGES-COLLECTION-CREATE"],

  LOAN_APP_DISBURSEMENT_CHECKLIST_OPEN_COMMENT_POPUP: ["LOAN-APPLICATION", "DISBURSEMENT-CHECKLIST", "OPEN-COMMENT-POPUP"],
  LOAN_APP_DISBURSEMENT_CHECKLIST_UPDATE_COMMENT_POPUP: ["LOAN-APPLICATION", "DISBURSEMENT-CHECKLIST", "UPDATE-COMMENT"],
  LOAN_APP_DISBURSEMENT_CHECKLIST_TRANCH_ADD: ["LOAN-APPLICATION", "DISBURSEMENT-CHECKLIST", "TRANCH-ADD"],

  APIUSERS                            : ["APIUSERS"],
  APIUSERS_VIEW_LIST                  : ["APIUSERS","ALL"],
  APIUSERS_VIEW_DETAILS               : ["APIUSERS","APIUSER-VIEW-DETAILS"],
  APIUSERS_BTN_ADD                    : ["APIUSERS","APIUSER-VIEW-DETAILS","BTN-ADD"],
  APIUSERS_BTN_EDIT                   : ["APIUSERS","APIUSER-VIEW-DETAILS","BTN-EDIT"],
  APIUSERS_BTN_UPDATE                 : ["APIUSERS","APIUSER-VIEW-DETAILS","BTN-UPDATE"],
  APIUSERS_BTN_DELETE                 : ["APIUSERS","APIUSER-VIEW-DETAILS","BTN-DELETE"],
  APIUSERS_BTN_EXP_TO_EXCEL           : ["APIUSERS","ALL","BTN-EXP-TO-EXCEL"],
};