import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ROUTES } from './menu-items';
import { LoanService } from '../../shared-component/service/loan/loan.service';
import { PermissionService } from '../../shared-component/service/permission/permission.service';
import { ReportschemasService } from '../../shared-component/service/reportschema/service';
import { AccessCodeService } from '../../shared-component/constant/accessCode.service';
import { ApplicationService } from '../../shared-component/service/application/application.service';
import { reduce } from 'lodash';
import { CanAccessPipe } from '../../shared-component/pipe/pipe/canAccess.pipe';
import { CollectionService } from '../../shared-component/service/collection/collection.service';
import { EmployeeService } from '../../shared-component/service/employee/employee.service';

@Component({
  selector: 'ap-sidebar',
  styleUrls: ['./sidebar.component.scss'],
  templateUrl: './sidebar.component.html',
  providers: [CanAccessPipe]
})
export class SidebarComponent implements OnInit {

  public accessCodeList = AccessCodeService;
  public menu: any = [];
  public searchNavItems: any = [];
  public searchMenu: string = '';
  public timer: any = null;
  public showMenu: string = '';
  public showSubMenu: string = '';
  public sidebarnavItems: any = [];
  public permissionItems: any[];
  public collectionOrg: any = 0;
  public dateRange: any = 0;
  public profile: any = {};

  @Output() myEvent = new EventEmitter();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loanService: LoanService,
    private applicationProvider: ApplicationService,
    private permissionProvider: PermissionService,
    private reportschemasProvicer: ReportschemasService,
    private loannProvider : LoanService,
    private collectionProvider :CollectionService,
    private employeeService : EmployeeService
  ) {
  }

  ngOnInit() {
    this.menu = ROUTES;
    this.getPermissionList();
    //this.searchMenuItem();
    this.getProfileDetail();
  }

  getProfileDetail(){
    let oThat = this;
    if( Object.keys(this.profile).length == 0 ){
      setTimeout(function(){
        oThat.profile = oThat.employeeService.getItems();
        oThat.getProfileDetail();
      },1000);
    }
  }
  getDelinquentCount(menuItem : any = {}) {
    let params = this.dateRange ? this.dateRange : {};
    this.collectionProvider.getDelinquentCount(params).subscribe((res:any) => {
      let total = res.data.slice(2,9).reduce((a, i) => {return a + i}, 0);
      menuItem.count = total;
      let stageCount = res.data;
      
      for (let menu of menuItem.submenu) {
        switch(menu.id){
          case "paymentFemi":
            menu.count = stageCount[0] || 0;
            break;
          case "paymentSemi":
            menu.count = stageCount[1] || 0;
            break;
          case "delinquent30only":
            menu.count = stageCount[2] || 0;
            break;
          case "delinquent30":
            menu.count = stageCount[3] || 0;
            break;
          case "delinquent60":
            menu.count = stageCount[4] || 0;
            break;
          case "delinquent90":
            menu.count = stageCount[5] || 0;
            break;
          case "delinquent120":
            menu.count = stageCount[6] || 0;
            break;
          case "delinquent150":
            menu.count = stageCount[7] || 0;
            break;
          case "delinquent180":
            menu.count = stageCount[8] || 0;
            break; 
        
        }
      }
    });
  }

  navigateToPath(menu) {
    if(menu.path)
      this.router.navigateByUrl(`${menu.path}`).then();
    
    if(menu.id == "applicationnAll"){
      this.getApplicationCount(menu);
    }else if(menu.id == "loanAll"){
      this.getLoanCount(menu);
    }else if(menu.id == "applicationStageInApproval"){
      this.getInApprovalCount(menu);
    }else if(menu.id == "delinquent"){
      this.getDelinquentCount(menu);
    }
  }

  loadMenu(){
    let oThat = this;
    oThat.permissionProvider.setAccess(oThat.permissionItems);
    let submenu = {
      "dashboard": {},
      "reports": {}
    };

    const mergerMenu = function (menu1, menu2) {
      let finalmenu = [];
      let menu1Items = menu1.reduce((acc, item) => {
        acc[item.title] = item;
        return acc;
      }, {});

      let menu2Items = menu2.reduce((acc, item) => {
        acc[item.title] = item;
        return acc;
      }, {});

      for (let item in menu1Items) {
        if (menu2Items[item]) {
          menu1Items[item].submenu = mergerMenu(menu1Items[item].submenu, menu2Items[item].submenu)
        }
        finalmenu.push(menu1Items[item]);
      }
      for (let item in menu2Items) {
        if (!menu1Items[item]) {
          finalmenu.push(menu2Items[item]);
        }
      }
      
      return finalmenu;
    };

    return oThat.menu.map(o => {
      if (submenu[o.id]) {
        o.submenu = mergerMenu(o.submenu, Object.values(submenu[o.id]));
      }
      if (o.id == "dashboard" || o.id == "reports") {
        o.submenu = o.submenu.filter(item => {
          return item.submenu.length;
        })
      }
      return o;
    });
  }

  getPermissionList(){
    try {
        this.permissionProvider.getAccessList({
          name: 'accessType',
          value: 1
        }).subscribe((res: any) => {
          this.permissionItems = res.data;
          this.searchNavItems = this.loadMenu();
          this.searchMenuItem(); 
        });
    } catch (e) {
      this.sidebarnavItems = [];
      this.permissionItems = this.permissionProvider.getAccess();
    }
  }

  searchMenuItem() {
    let oThat = this;
    let search = this.searchMenu.toLowerCase();
    if(!search){
      this.searchNavItems = [...this.menu];
      this.loadMenuStyle();
      return;  
    }

    let filterMenu = function(search, item){
      let final = {...item};
      if(item.title.toLowerCase().indexOf(search) >= 0){
        return item;
      }
      let submenu = [];
      if (final.submenu.length){
        [...final.submenu].filter(o => {
          let result = filterMenu(search,o);
          if( result ){
            submenu.push(result);
          }
        });
        final.submenu = submenu;
      }
      return final.submenu.length? final : false;
    };

    clearTimeout(this.timer);
    this.timer = setTimeout( function(){
      oThat.searchNavItems = [];
      [...oThat.menu].map( o => {
          let result = filterMenu(search, o);
          if( result )
          oThat.searchNavItems.push(result);
      });
      oThat.loadMenuStyle();
    },500);
  }

  loadMenuStyle(){
    setTimeout( function(){
      window["pcodedmenu"].call();
    },500);
  }

  getApplicationCount(menuItem : any = {}) {

    let params = this.dateRange ? this.dateRange : {};
    this.applicationProvider.getApplicationCount(params).subscribe((res: any) => {
      let total = res.data.reduce((a, i) => { return a + i.count }, 0);
      menuItem.count = total;
      let stageCount = res.data.reduce( ( a, o) => {
        a[o.status] = o.count;
        return a;
      },{});
      for (let menu of menuItem.submenu) { 
        switch(menu.id){
          case "applicationStageBooking":
            menu.count =  stageCount[0] || 0;
            break;
          case "applicationStageNewApplication":
            menu.count =  stageCount[1] || 0;
            break;
          case "applicationStageCreditReview":
            menu.count =  stageCount[2] || 0;
            break;
          case "applicationStageSoftApproval":
            menu.count =  stageCount[3] || 0;
            break;
          case "applicationStageFinApproval":
            menu.count =  stageCount[4] || 0;
            break;
          case "applicationStageInApproval":
            menu.count =  stageCount[5] || 0;
            break;
          case "applicationStageFinalApproval":
            menu.count =  stageCount[6] || 0;
            break;
          case "applicationStageVerification":
            menu.count =  stageCount[7] || 0;
            break;
          case "applicationStageRefused":
            menu.count =  (stageCount[10] || 0);
            break;
          case "applicationStageRejected":
            menu.count =  (stageCount[9] || 0);
            break;
          case "applicationStageCancelled":
            menu.count =  (stageCount[11] || 0);
            break;
          case "applicationStageWithdraw":
            menu.count =  (stageCount[12] || 0);
            break;
          case "applicationStageHold":
            menu.count =  (stageCount[13] || 0);
            break;
        }
      }
    });
  }

  getInApprovalCount(menuItem : any = {}) {
    let params = this.dateRange ? this.dateRange : {};
    this.applicationProvider.getInApprovalCount(params).subscribe((res: any) => {
      let stageCount = res.data.reduce( ( a, o) => {
        a[o.actionType]++;
        return a;
      },{ "5" : 0 , "6" : 0, "19" : 0, "20" : 0 });
      for (let menu of menuItem.submenu) { 
        switch(menu.id){
          case "applicationStageInApprovalTech":
            menu.count = menuItem.count - ( stageCount[5] || 0 );
            break;
          case "applicationStageInApprovalLegal":
            menu.count = menuItem.count - ( stageCount[6] || 0 );
            break;
          case "applicationStageInApprovalRCU":
            menu.count = menuItem.count - (stageCount[19] || 0);
            break;
          case "applicationStageInApprovalComm":
            menu.count = menuItem.count - ( stageCount[20] || 0 );
            break;
        }
      }
    });
  }

  getLoanCount(menuItem : any = {}) {

    let params = this.dateRange ? this.dateRange : {};
    this.loannProvider.getLoanCount(params).subscribe((res: any) => {
      let total = res.data.reduce((a, i) => { return a + i.count }, 0);
      menuItem.count = total;
      let stageCount = res.data.reduce( ( a, o) => {
        a[o.status] = o.count;
        return a;
      },{});
      for (let menu of menuItem.submenu) { 
        switch(menu.id){
          case "loanVerificationBucket":
            menu.count =  stageCount[0] || 0;
            break;
          case "loanVerifiedBucket":
            menu.count =  stageCount[1] || 0;
            break;
          case "loanActiveBucket":
            menu.count =  stageCount[2] || 0;
            break;
          case "loanCloseBucket":
            menu.count =  ( stageCount[3] || 0 ) +  ( stageCount[4] || 0 );
            break;
          case "loanRejectBucket":
            menu.count =    ( stageCount[5] || 0 ) + (stageCount[6] || 0);
            break;
          case "loanonholdBucket":
            menu.count =  stageCount[7] || 0;
            break;
            
        }
      }
    });
  }
}
