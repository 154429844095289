import { Component,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewContainerRef,
  ViewChild,
  ElementRef } from '@angular/core';
import * as moment from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessCodeService } from '../../shared-component/constant/accessCode.service';
import { CanAccessPipe } from '../../shared-component/pipe/pipe/canAccess.pipe';
import { LoanService } from '../../shared-component/service/loan/loan.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ColumnDragDrop } from '../../../app/shared-component/helper/columnDragDrop';
import { Location } from '@angular/common';
import { encodeQueryData } from '../../shared-component/helper/common';
import { AdvanceInputComponent } from '../../shared-component/common-components/advanceInput/advanceInput.component';
import { ToasterAlertService } from '../../shared-component/notification/toastAlert.service';
import * as downloadjs from 'downloadjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-collection-report',
  templateUrl: './collection-report.component.html',
  styleUrls: ['./collection-report.component.css'],
  providers: [CanAccessPipe]
})
export class CollectionReportComponent implements OnInit {

  readonly TABLE_IDENTIFIER = 'COLLECTION_REPORT';
  readonly STORAGE_KEY = 'COLLECTION_REPORT_TABLE';

  public filterForm: FormGroup;
  public excelStatementForm: FormGroup;
  public accessCodeList = AccessCodeService;

  public searchData: any = {};
  public dataSource = new BehaviorSubject<any[]>([]);

  public itemsCount = 0;
  public itemsPerPage = environment.itemsPerPage;
  public currentPage = 1;

  public todayDate: any;
  public search = '';
  public queryField: string = "";
  public branches = [];
  public byloanId = '';
  public byfullName = '';
  public bymobile = '';
  public byemail = '';
  public sortKey = '';
  public sortDirection = 'asc';

  public oColumnDragDrop;


  public columnUserPreference = [
    {field: 'loanNumber', label: 'Loan Number', visible: true},
    {field: 'status', label: 'Status', visible: true},
    {field: 'disbursedAt', label: 'Disbursement Date', visible: true},
    {field: 'closedAt', label: 'Close Date', visible: true},

    {field: 'totalAmountPaid', label: 'Amount Received', visible: true},
    {field: 'principalAmountPaid', label: 'Principal Paid', visible: true},
    {field: 'interestAmountPaid', label: 'Interest Paid', visible: true},
    {field: 'bounceAmountPaid', label: 'Bounce Paid', visible: true},
    {field: 'penaltyAmountPaid', label: 'Penalty Paid', visible: true},
  ];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private canAccessPipe: CanAccessPipe,
    private loanService: LoanService,
    private activatedRoute: ActivatedRoute,
    private toasterService: ToasterAlertService,
    private modalService: NgbModal

  ) {
    window.scrollTo(0, 0);
    if (!this.canAccessPipe.transform(this.accessCodeList.REPORTS_DUE)) {
      this.router.navigate(['/accessdenied']);
      return;
    }

    this.excelStatementForm = fb.group({
      excelStartDate: [null],
      excelEndDate: [null]
    });

    this.filterForm = fb.group({
      startDate: [null],
      endDate: [null],
      branch: [null]
    });

  }

  ngOnInit() {

    if (localStorage.getItem(this.STORAGE_KEY + this.TABLE_IDENTIFIER)) {
      try{
        if (localStorage.getItem(this.STORAGE_KEY + this.TABLE_IDENTIFIER )) {
          let preferences = JSON.parse(localStorage.getItem(this.STORAGE_KEY + this.TABLE_IDENTIFIER));
          this.columnUserPreference = this.columnUserPreference.map(o => {
            let preference = preferences.find(p => p.field === o.field);
            if (preference) {
            o.visible = preference.visible;
            }
            return o;
          });
      }
      }catch(e){}
    }

    this.getCollectionReport();

    this.todayDate = {
      year: parseInt(moment().format('YYYY')),
      month: parseInt(moment().format('MM')),
      day: parseInt(moment().format('DD'))
    };

  }

  get filterStartDate() {
    return this.filterForm && this.filterForm.get('startDate');
  }

  get filterEndDate() {
    return this.filterForm && this.filterForm.get('endDate');
  }

  get filterBranchId() {
    return this.filterForm && this.filterForm.get('branch');
  }

  get filterAmount() {
    return this.filterForm && this.filterForm.get('loanAmount');
  }

  get filterStatus() {
    return this.filterForm && this.filterForm.get('status');
  }


  get excelStartDate() {
    return this.excelStatementForm && this.excelStatementForm.get('excelStartDate');
  }

  get excelEndDate() {
    return this.excelStatementForm && this.excelStatementForm.get('excelEndDate');
  }

  getCollectionReport(flag = false){

    this.currentPage = flag ? 1 : !this.currentPage ? 1 : this.currentPage;
    let params: any = {
      limit: this.itemsPerPage,
      page: this.currentPage,
      qf: this.queryField,
      q: this.search
    };
    if (this.search) params.q = this.search;
    if (this.search) params.qf = this.queryField;
    if (this.byfullName) params.fullName = this.byfullName;
    if (this.bymobile) params.mobile = this.bymobile;
    if (this.byemail) params.email = this.byemail;
    if (this.filterStartDate.value && Object.keys(this.filterStartDate.value).length) params.startDate
      = this.filterStartDate.value.year + '-' + this.filterStartDate.value.month + '-' + this.filterStartDate.value.day + ' 00:00:00';
    if (this.filterEndDate.value && Object.keys(this.filterEndDate.value).length) params.endDate
      = this.filterEndDate.value.year + '-' + this.filterEndDate.value.month + '-' + this.filterEndDate.value.day + ' 23:59:59';
    if (this.filterAmount && this.filterAmount.value) params.loanAmount = this.filterAmount.value;
    if (this.filterBranchId.value && this.filterBranchId.value.id) params.branch = this.filterBranchId.value.id;
    this.searchData = params;

    this._getCollectionReport()

  }

  _getCollectionReport(){

    this.loanService.getCollectionReport(this.searchData).subscribe((res: any) => {
      this.dataSource.next(res.data);
      console.log("--get--", res);
      console.log("---get--", res.data);
    })
  }


  doSort(field: string) {
    if (field === 'createdAt') {
      field = 'createdAt';
    }

    if (field === this.sortKey) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortKey = field;
      this.sortDirection = 'asc';
    }
    const sortedData = [...this.dataSource.value];
    sortedData.sort((a, b) => {
      const aValue = a[field];
      const bValue = b[field];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return this.sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else {
        return this.sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
      }
    });
    this.dataSource.next(sortedData);
  }


  onEnterKey(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.getCollectionReport();
    }
  }


  persistColumnPreference() {
    localStorage.setItem(this.STORAGE_KEY + this.TABLE_IDENTIFIER, JSON.stringify(this.columnUserPreference));
  }


  onLoanClick() {
    const formData = {
      filterForm: this.filterForm.value,
      search: this.search,
      byloanId: this.byloanId,
      byfullName: this.byfullName,
      bymobile: this.bymobile,
      byemail: this.byemail
    }
    this.loanService.saveFormState(formData);
    this.loanService.setLoanState(this.dataSource);
  }

  openExcelStatementPopUp(content: any,d) {
    const searchInput = this.search && this.search.trim();
    if (searchInput) {
      this.downloadExcel(d);
    } else {
      this.modalService.open(content, { backdrop: 'static', centered: true });
    }
  }

  resetExcelStatementForm(e: Event) {
    e.preventDefault();
    this.excelStatementForm.setValue({
      excelStartDate: null,
      excelEndDate: null
    });
  }



  pageChange(objEvent) {
    let { page, flag, params } = objEvent;
    this.searchData.page = page;
    this._getCollectionReport();
  }


  closeFix(event, datePicker) {
    if (event.target.offsetParent == null)
      datePicker.close();
    else if (event.target.offsetParent.nodeName != 'NGB-DATEPICKER')
      datePicker.close();
  }


  get displayedColumns() {
    this.oColumnDragDrop = new ColumnDragDrop(this.columnUserPreference , this.STORAGE_KEY + this.TABLE_IDENTIFIER );
    return this.oColumnDragDrop.columnList.filter((o: any) => o.visible).map(o => o.field);
  }


  downloadExcel(dismiss) {
    let params: any = {};

    if (this.search) params.q = this.search;
    if (this.search) params.qf = this.queryField;
    if (this.filterStartDate.value && Object.keys(this.filterStartDate.value).length) params.startDate
      = this.filterStartDate.value.year + '-' + this.filterStartDate.value.month + '-' + this.filterStartDate.value.day + ' 00:00:00';
    if (this.filterEndDate.value && Object.keys(this.filterEndDate.value).length) params.endDate
      = this.filterEndDate.value.year + '-' + this.filterEndDate.value.month + '-' + this.filterEndDate.value.day + ' 23:59:59';

    // let diffDays: any = 0;
    if (this.excelStartDate && this.excelStartDate.value && this.excelEndDate && this.excelEndDate.value) {
      const excelStartDate = moment([this.excelStartDate.value.year, this.excelStartDate.value.month, this.excelStartDate.value.day]),
        excelEndDate = moment([this.excelEndDate.value.year, this.excelEndDate.value.month, this.excelEndDate.value.day]);
      params.startDate = this.excelStartDate.value.year + '-' + this.excelStartDate.value.month + '-' + this.excelStartDate.value.day + ' 00:00:00';
      params.endDate = this.excelEndDate.value.year + '-' + this.excelEndDate.value.month + '-' + this.excelEndDate.value.day + ' 23:59:59';
    }

    this.loanService.downloadCollectionReportExcel(params).subscribe(res => {
      let fileName = "Collection Report.xlsx";
      if (res.headers.get('Content-Disposition')) {
        fileName = res.headers.get('Content-Disposition').split('=')[1];
      }
      downloadjs(res.body, fileName, res.body.type);
      this.toasterService.pop('success','', 'Data exported successfully.')
      this.excelStatementForm.reset();
      dismiss();
    });
  }


}
