import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { ToasterAlertService } from '../../shared-component/notification/toastAlert.service';
import { AuthService } from '../../shared-component/service/auth/auth.service';
import { LoanService } from '../../shared-component/service/loan/loan.service';

import {SessionExpiryService} from '../../shared-component/session-expiry-service/session-expiry.service';
import { EmployeeService } from '../../shared-component/service/employee/employee.service';
import { ActionService } from '../../shared-component/service/action/action.service';

@Component({
  selector: 'ap-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
  public profilePic: any = null;
  public profileData: any = {};
  public passwordForm: FormGroup;
  public changePasswordMessage: boolean = false;
  public systemType : any = environment.production;
  public hasUnseenNotification: boolean = false;
  public inputValue : string = '';
  public searchBy : string = '';
  public patternErrorShown: boolean = false;
  public searchTimeout: any = null;
  public searchList: any = [];

  @Output() myEvent = new EventEmitter();
  @ViewChild('popup') popUp: any;

  constructor(
    public router: Router,
    public auth: AuthService,
    public loanService: LoanService,
    public fb: FormBuilder,
    public toasterService: ToasterAlertService,
    private modalService: NgbModal,
    private sessionExpiryService: SessionExpiryService,
    private employeeService: EmployeeService,
    private actionService: ActionService
  ) {
    this.passwordForm = this.fb.group({
      password: ['', Validators.compose([Validators.required,Validators.minLength(8),Validators.maxLength(32), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$#*@&%])\S{8,32}$/)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(8),Validators.maxLength(32), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$#*@&%])\S{8,32}$/)])],
    },{ validator: this.passwordMatchValidator() }
    );
  }


  ngOnInit() {
    this.loanService.profile().subscribe((res: any) => {
      this.profileData = res.data;
      this.employeeService.setItem(res.data);
      if (this.profileData.passwordChangeRequired) {
        this.openPopup();
      }
    });
    this.employeeService.loadProfilePic( ).subscribe( (res : any) =>{
      if( res.data){
        this.employeeService.setProfilePic(res.data.url);
        this.profilePic = res.data.url;
      }  
    });
    
  }

  globalSearch(event){
    let oThat = this;
    let search = event?event.target.value:this.searchBy;
    if (search) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(function () {
            oThat.actionService.search(search).subscribe( (res : any) =>{
              oThat.searchList = [];
              if( res.data.loanApplications){
                oThat.searchList.push({ path: "application", key : "LoanApplications", "value" : res.data.loanApplications, q : search})
              }
              if( res.data.loans){
                oThat.searchList.push({ path: "loan", key : "Loans", "value" : res.data.loans, q : search})
              }  
              if( res.data.customers){
                oThat.searchList.push({ path: "customers", key : "Customers", "value" : res.data.customers, q : search})
              }  
            });
        }, 1000);
    }
  }

  gotToSearch(path, q){
    this.router.navigateByUrl(`${path}?q=${q}`).then();
    this.closeSearchBar(0);
  }
  openSearchBar(){
    clearTimeout(this.searchTimeout);  
  }

  closeSearchBar(time = 3000){
    let oThat = this;
    clearTimeout(this.searchTimeout);  
    this.searchTimeout = setTimeout(function(){
      oThat.searchList = [];
      window["closeSearchBar"].call();
    },time);
  }

  get password() {
    return this.passwordForm.get('password');
  }

  get confirmPassword() {
    return this.passwordForm.get('confirmPassword');
  }

  changePassword() {
    if (this.passwordForm.valid && this.passwordForm.get('password').value === this.passwordForm.get('confirmPassword').value) {
      this.auth.changePassword(this.passwordForm.value).subscribe((res:any) => {
        this.changePasswordMessage = true;
        this.toasterService.pop('success', '', res.message);
      }, (errors) => {
        for (let error of errors) {
          if (!this.passwordForm.get(error.field)) continue;
          this.passwordForm.get(error.field).setErrors({ server: error.message });
        }
      });
    } else {
      this.toasterService.pop('error', '', 'Passwords does not match');
    }
  }
  
  toggleSidebar() {
    this.myEvent.emit();
  }

  open(content) {
    this.modalService.open(content, {
      backdrop: 'static',
      beforeDismiss: () => {
        return !this.profileData.passwordChangeRequired;
      }
    });
  }

  openPopup() {
    this.open(this.popUp);
  }

  logout() {
    sessionStorage.removeItem('token');
    this.sessionExpiryService.clear();
    this.router.navigate(['/authentication']).then();
  }
  
  profile() {
    this.router.navigate(['/profile']).then();
  }

  navigateToPath(path) {
    if(path)
      this.router.navigateByUrl(`${path}`).then();
  }

  passwordMatchValidator(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const passwordControl = formGroup.get('password');
      const confirmPasswordControl = formGroup.get('confirmPassword');
  
      if (passwordControl && confirmPasswordControl && passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ matchPassword: true });
        return { matchPassword: true };
      } else {
        confirmPasswordControl.setErrors(null);
        return null;
      }
    };
  }

  onInputChange(event: any) {
    this.inputValue = event.target.value;
    if (this.passwordForm.get('confirmPassword').hasError('pattern')) {
        this.patternErrorShown = true;
    } else {
        this.patternErrorShown = false;
    }
    return null;
  }

}



