import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Spinkit} from 'ng-http-loader';
import {InternetService} from './shared-component/service/internet/internet.service';
import {SessionExpiryService} from './shared-component/session-expiry-service/session-expiry.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  public spinkit = Spinkit;

  @ViewChild('popup') popup: any;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private internetService: InternetService,
    private sessionExpiryService: SessionExpiryService
  ) {
    this.internetService.internetConnection();
  }

  ngOnInit(): void {
    let token = sessionStorage.getItem('token');
    if (token) {
      this.sessionExpiryService.register();
    } else {
      if(!(window["location"]["href"]+"").endsWith("/login")){
        window["requestedURL"] = window["location"]["href"];
      }
    }

    this.sessionExpiryService.tokenExpireEvent.subscribe(() => {
      sessionStorage.removeItem('token');
      this.sessionExpiryService.clear();
      this.modalService.open(this.popup, {backdrop: 'static', centered: true});
    });
  }

  navigateToLogin(): void {
    this.router.navigateByUrl('/authentication/login').then();
  }
}
