import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http/src/response';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs';

import {ConstantService} from '../../constant/constant.service';

/**
 * Description of Reportschemas Service
 *
 * @author [DEVELOPER NAME]
 */

@Injectable({
  providedIn: 'root'
})   
export class ReportschemasService {

  constructor(
    private constantService: ConstantService,
    private http: HttpClient
  ) {
  }

  private _items: any = {};
  
  setItem(item: any) {
    this._items = item;
  }

  getItems() {
    return this._items;
  }
    
  get(params) {
    return this.http.get(this.constantService.getUrlByQuery(this.constantService.REPORTSCHEMAS, params));
  }

  getReportschemas(params?: any) {
    return this.http.get(this.constantService.getUrl(this.constantService.REPORTSCHEMAS), {
      params: params,
      observe: 'response'
    }).map((resp: any) => {
      let count = resp.headers.get('X-Total-Count');
      return {count: parseInt(count), data: resp.body.data};
    });
  }
  
  getById(id: string) {
    return this.http.get(this.constantService.getUrl(this.constantService.REPORTSCHEMAS, [id]));
  }

  getReportById(body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.REPORTSCHEMAS}/report`), body);
  }
  
  getReportData(body) {
    return this.http.post(this.constantService.getUrl(`${this.constantService.REPORTSCHEMAS}/data`), body);
  }
  
  exportReportById(params?: any): Observable<HttpResponse<Blob>> {
    return this.http.get(this.constantService.getUrl(this.constantService.REPORTSCHEMAS + '/download/excel'), {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }

  getMenuItems() {
    return this.http.post(this.constantService.getUrl(`${this.constantService.REPORTSCHEMAS}/menuItems`),{});
  }

  post(body: Object) {
    return this.http.post(this.constantService.getUrl(this.constantService.REPORTSCHEMAS), body);
  }

  put(id: string, body) {
    return this.http.put(this.constantService.getUrl(this.constantService.REPORTSCHEMAS, [id]), body);
  }

  deleteReportSchemas(id: number) {
    return this.http.delete(this.constantService.getUrl(`${this.constantService.REPORTSCHEMAS}/${id}`));
  }

}