import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterGuardService} from './shared-component/guard/router-guard.service';
import {FullComponent} from './layouts/full/full.component';
import {BlankComponent} from './layouts/blank/blank.component';

export const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [RouterGuardService],
    children: [
      {path: 'reports', loadChildren: './reports/reports.module#ReportsModule'},
      {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
      {path: 'home', loadChildren: './dashboard/dashboard.module#DashboardModule'},
      {path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule'},
      {path: 'settings', loadChildren: './settings/settings.module#SettingsModule'},
      {path: 'employees', loadChildren: './employees/employees.module#EmployeesModule'},
      {path: 'profile', loadChildren: './profile/profile.module#ProfileModule'},
      
      {path: 'customers', loadChildren: './customers/customers.module#CustomersModule'},
      {path: 'application', loadChildren: './application/application.module#ApplicationModule'},
      {path: 'loan', loadChildren: './loan/loan.module#LoanModule'},
      {path: 'collection', loadChildren: './collection/collection.module#CollectionModule'},
      {path: 'lead', loadChildren: './lead/lead.module#LeadModule'},
      {path: 'masterdata', loadChildren: './masterdata/masterdata.module#MasterDataModule'},
      {path: 'branches', loadChildren: './branches/branch.module#BranchModule'},
      {path: 'rbac', loadChildren: './rbac/rbac.module#RBACModule'},
      {path: 'leadconnectors', loadChildren: './leadconnectors/leadConnectors.module#LeadConnectorModule'},
      {path: 'vendors', loadChildren: './vendor/vendors.module#VendorModule'},
      {path: 'colender', loadChildren: './colender/colender.module#ColenderModule'},
      {path: 'apiuser', loadChildren: './apiuser/apiuser.module#ApiUserModule'},
      
      {path: 'accessdenied', loadChildren: './accessdenied/accessdenied.module#AccessDeniedModule'},
      {path: 'error', loadChildren: './error/error.module#ErrorModule'},
      {path: 'manual-activity', loadChildren: './manual-activity/manual-activity.module#ManualActivityModule'}
    ]
  },{
    path: 'error',
    loadChildren: './error/error.module#ErrorModule'
  }, {
    path: '',
    component: BlankComponent,
    children: [
      {path: '', redirectTo: '/authentication', pathMatch: 'full'},
      {path: 'authentication', loadChildren: './authentication/authentication.module#AuthenticationModule'}
    ]
  },
  {
    path: '**',
    redirectTo: 'error'
  }];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
    RouterModule.forRoot(routes),
    NgbModule.forRoot()
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}

