export class ColumnDragDrop {
    constructor(
        public columnList : any = [],
        public storageKey : any = ""
    ) { }

    public allowDrop(ev) {
        ev.preventDefault();
        return false;// ev.target.classList.contains("hover-color-gray");
    }

    public drag(ev) {
        ev.preventDefault();
        window["CDDdragableId"] = ev.target.getAttribute("dragid");
    }

    public drop(ev) {
        ev.preventDefault();
        var source = window["CDDdragableId"];
        var target = ev.target.getAttribute("dragid") || ev.target.parentNode.getAttribute("dragid") || ev.target.parentNode.parentNode.getAttribute("dragid")
        if (source && target) {
            let currrentPos = -1;
            this.columnList.find((o, i) => {
                if (o.field == source) currrentPos = i;
            });

            let targetPos = -1;
            this.columnList.find((o, i) => {
                if (o.field == target) targetPos = i;
            });
            if (currrentPos == targetPos) return true;
            let data = this.columnList.splice(currrentPos, 1);
            this.columnList.splice(targetPos, 0, ...data);
            if(this.storageKey){
                localStorage.setItem(this.storageKey, JSON.stringify(this.columnList));
            }  
        }
        window["CDDdragableId"] = null;
    }
}
