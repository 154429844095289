import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: 'StatusBadge' })
export class StatusBadge implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    transform(status, type = "success", pulse = true) {
        let mapper= {
            success : "",
            danger : "pulse-yellow",
            warning : "pulse-yellow",
            primary : "pulse-white",
            default : "pulse-black",
            black   : "pulse-white"
        }
        let text = `<label class="badge badge-md bg-${type} m-0 ${ pulse ?'pl-4':''}">
                        ${ pulse ?`<span class="pulse ${ mapper[type]}"></span> `:''}${status}
                    </label>`
        return this.sanitizer.bypassSecurityTrustHtml(text);
    }
}