import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../service/user/user.service';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'app-administrator-pophover',
  templateUrl: './administrator-pophover.component.html',
  styleUrls: ['./administrator-pophover.component.scss']
})

export class AdministratorPophoverComponent implements OnInit {
  @Input() public user : any = {};
  @Input() changing: Subject<boolean>;

  private userData : any = {};
  private show : any = false;
  private timer : any = null;
  constructor(
    private sanitizer: DomSanitizer,
    private userService: UserService,

  ) {
  
  }

  ngOnInit(){
  }

  getData( flag = false){
    let oThat = this;
    this.clearCall();
    this.timer = setTimeout( function(){ 
      if( flag || (!this.timer && !oThat.userData.id ) ){
        oThat.userService.getById(oThat.user.id).subscribe((res: any) => {
          oThat.userData = res.data;
          oThat.timer = null;
          oThat.show = true;
        }, error => {
          oThat.timer = null; 
        });
      }
    },500);

  }

  clearCall(flag = false){
    clearTimeout(this.timer);
    if( flag ){
      this.show = false;
    }
  }
  
}