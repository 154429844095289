import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef } from '@angular/core';
import {Router} from '@angular/router';

import { environment } from '../../../../environments/environment';
import { ToasterAlertService } from '../../notification/toastAlert.service';
import * as moment from 'moment';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnChanges {
  @Input() public dataSource: any = []; 
  @Input() public selectionDate: any = 'expectedDate'; 
  @Input() templateContent: TemplateRef<any>;

  @Output() closeCalendar = new EventEmitter();
  @Output() searchCalendarData = new EventEmitter();

  public calendarMonth : any = [];
  public calendarDay : any = ["Sun","Mon","Tue","Wed","Thr","Fri","Sat"];
  public calendarDate : any = null;
  public calendarSelecteds : any = [];
  public templateToRender: any; // Type can be NgTemplateRef or undefined
  public calendarContext : any = {};
  public oThat : any = {};
  constructor(
    private router: Router,
    private toasterService: ToasterAlertService
    ) {
  }

  ngOnInit() {
    this.templateToRender = this.templateContent;
    this.calendarDate = new Date();
    this.loadCalendar();
    this.calendarContext = { calendarSelecteds : this.calendarSelecteds };
    this.oThat = this;
  }

  ngOnChanges(){
    this.loadCalendar();
  }

  setCalendarMonth(value){
    var date = this.calendarDate;
    this.calendarDate = new Date(date.getFullYear(), date.getMonth() + value, 1);
    this.loadCalendar();
    this.searchCalendarData.emit({ startDate : this.calendarDate , endDate : new Date(date.getFullYear(), date.getMonth() + value + 1 , 0) });
  
  }

  loadCalendar(data : any = []){
    let dom : any= document.getElementsByClassName("selected-CDBlock");
    if( dom.length ){
      dom[0].classList.remove("selected-CDBlock");
      this.calendarSelecteds = [];
    }
    if( data.length == 0){
      data = this.dataSource;
    }
    var date = this.calendarDate || new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth() , 1).getDay();
    var lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    var cMonth =  date.getMonth() + 1;
    let day = 1; 
    let count = 0;
    lastDate = lastDate + firstDay;
    this.calendarMonth = [];
    let dataCount = {};
    data.map( (o : any ) => {
      let oDay = moment(o[this.selectionDate]).format("D");
      let month = moment(o[this.selectionDate]).format("M");
      if(!dataCount[oDay]){
        dataCount[oDay] = [];
      }
      if(month == cMonth)
        dataCount[oDay].push(o);
    });
    for(let i = 0; (i < 6 && count < lastDate); i++){
      this.calendarMonth[i] = [];  
      for(let j = 0; (j < 7 && count < lastDate); j++){
        count++;
        if( count > firstDay){
          this.calendarMonth[i][j] = { "day" : day , "date" : new Date(date.getFullYear(), date.getMonth(), day),  "events" : (dataCount[day]? dataCount[day] : []) };
          day++;
        }
      }   
    }
  }

  selectCalanderBlock(event, calBlock){
    let dom : any= document.getElementsByClassName("selected-CDBlock");
    if( dom.length ){
      dom[0].classList.remove("selected-CDBlock");
    }
    let target = event.target;
    let looptill = 5
    while(!(target.classList.contains("flex-even")) && looptill){
      target = target.parentElement;
      looptill--;
    }
    if(looptill){
      target.classList.add("selected-CDBlock");
    }
    this.calendarSelecteds = calBlock.events;
  }

}