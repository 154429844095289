import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToasterService } from 'angular2-toaster';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToasterAlertService } from '../../notification/toastAlert.service';

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true
        }
    ]
})
export class DatepickerComponent implements OnInit, OnChanges, ControlValueAccessor {
    @Input() label: any = '';
    @Input() placeholder: any = '';
    @Input() format: any = "dd/mm/yyyy";
    @Input() minDate: any = null;
    @Input() maxDate: any = null;
    @Input() pickLevel: any = 0;
    @Input() maxView: any = 3;
    @Input() title: any = "";
    @Input() dateObject: any = false;
    
    public _inputvalue: any = "";
    public disabled = false;
    
    public value: any = "";
    
    @ViewChild('calendarInput') calendarInput: ElementRef;

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private toasterService: ToasterAlertService
    ) {
    }

    ngOnInit() {
        let options : any = {
            format : this.format,
            pickLevel : this.pickLevel,
            maxView : this.maxView,
            title : this.title
        };
        if(this.minDate){
            options.minDate = this.minDate;
        }
        if(this.maxDate){
            options.maxDate = this.maxDate;
        }
        let oThat = this;
        setTimeout( function(){
            oThat.calendarInput.nativeElement.datepicker = new window["Datepicker"](oThat.calendarInput.nativeElement,options);
            oThat.updateCallback();
        },10);
    }

    ngOnChanges() {
        if( this.calendarInput.nativeElement.datepicker ){
            if( this.minDate && this.minDate.getDay ){
                this.minDate = moment(this.minDate).format("DD/MM/YYYY");
                this.calendarInput.nativeElement.datepicker.setOptions({"minDate": this.minDate});
            }else if( this.minDate && this.minDate.year ){
                this.minDate = this.minDate.day + "/" + this.minDate.month + "/" + this.minDate.year;
                this.calendarInput.nativeElement.datepicker.setOptions({"minDate": this.minDate});
            }

            if( this.maxDate && this.maxDate.getDay ){
                this.maxDate = moment(this.maxDate).format("DD/MM/YYYY");
                this.calendarInput.nativeElement.datepicker.setOptions({"maxDate": this.maxDate});
            }else if( this.maxDate && this.maxDate.year ){
                this.maxDate = this.maxDate.day + "/" + this.maxDate.month + "/" + this.maxDate.year;
                this.calendarInput.nativeElement.datepicker.setOptions({"maxDate": this.maxDate});
            }
        }
    }   

    showDatePicker(){
        this.calendarInput.nativeElement.datepicker.show();
    }
    onChange: any = () => { };
    onTouched: any = () => { };

    updateCallback($event= null) {
        this.onTouched();
        let dt = this.calendarInput.nativeElement.datepicker.getDate();
        if ( dt ){
            let oDate = dt;
            if ( this.dateObject ){
                if ( dt.getFullYear() ){
                    oDate = { "year": dt.getFullYear(), "month": dt.getMonth() + 1, "day": dt.getDate() };
                } else {
                    oDate = {};
                }
            } else {
                if ( dt.getFullYear() ){
                    oDate = `${ dt.getFullYear() }-${ dt.getMonth() + 1 }-${ dt.getDate() }`;
                } else {
                    oDate = "";
                }
            }
            this.onChange( oDate );
        } else {
            this.onChange("");
        }
    }
    
    writeValue(value: any): void {
        let oThat = this;
        if(!value || value == "0000-00-00 00:00:00" || value == "0000-00-00") {
            oThat._inputvalue = "";
            return;
        }
        this.value = value == undefined ? "" : value;
        let oDate = new Date(value);
        this.value = `${ oDate.getDate() }/${ oDate.getMonth() + 1 }/${ oDate.getFullYear() }`;
        try {
            if (value) {
                oThat._inputvalue = this.value;
            }
        } catch (e) {
            oThat._inputvalue = this.value;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }
}