import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EmbeddedViewRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewContainerRef,
    NgZone,
    forwardRef,
    SimpleChanges
} from '@angular/core';
import Popper from 'popper.js';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
    selector: 'app-select',
    templateUrl: './custome-select.component.html',
    styleUrls: ['./custome-select.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CustomeSelectComponent),
        multi: true
    }]
})
export class CustomeSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() formClass = 'materilize-form';
    @Input() origin : any = null;
    @Input() placeholder = '';
    @Input() labelPlaceholder = '';
    @Input() labelKey : any = 'label';
    @Input() idKey = 'id';
    @Input() multiple = false;
    @Input() lableGrouping = true;
    @Input() values = [];
    @Input() labels = [];
    @Input() options = [];
    @Input() optionTpl: TemplateRef<any> = null;
   
    @Output() selectChange = new EventEmitter();
    @Output() closed = new EventEmitter();
    @Output() searchChanged = new EventEmitter();

    visibleOptions = 4;
    searchControl = new FormControl();

    private model : any;
    private view: EmbeddedViewRef<any>;
    private popperRef: Popper;
    private originalOptions = [];

    constructor(private vcr: ViewContainerRef, private zone: NgZone, private cdr: ChangeDetectorRef) {
        this.originalOptions = [...this.options];
    }

    onChange: any = () => { };
    onTouched: any = () => { };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(value: any): void {
        if (value !== undefined) {
            this.setSelection(value);
        }

        this.searchControl.valueChanges
            .pipe(
                debounceTime(300),
                untilDestroyed(this)
            )
            .subscribe((term: any) => this.search(term));
    }

    get isOpen() {
        return !!this.popperRef;
    }

    ngOnInit() {

    }

    get label() {
        return this.model ? this.model["_label"] : this.placeholder;
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.options){
            if ( this.model )
                this.setSelection(this.multiple?[...this.model[this.idKey]]:this.model[this.idKey]);
        }
    }

    open(event, dropdownTpl: TemplateRef<any>, origin: HTMLElement) {
        event.stopPropagation();
        this.originalOptions = [...this.options];
    
        if (this.popperRef)
            this.destroy();
        this.view = this.vcr.createEmbeddedView(dropdownTpl);
        const dropdown = this.view.rootNodes[0];

        if (this.origin)
            this.origin.appendChild(dropdown);
        else
            origin.appendChild(dropdown);
        dropdown.style.width = `100%`;
        dropdown.style.minWidth = `200px`;

        this.zone.runOutsideAngular(() => {
            this.popperRef = new Popper(this.origin || origin, dropdown, {
                removeOnDestroy: true
            });
        });

        this.handleClickOutside();
    }

    close(event = null) {
        this.closed.emit();
        this.searchControl.patchValue('');
        this.destroy();
        if(event){
            event.stopPropagation();
        }
    }

    destroy() {
        if( this.popperRef ){
            this.popperRef.destroy();
            this.view.destroy();
            this.view = null;
            this.popperRef = null;
        }
    }

    select(option) {
        let key = option[this.idKey];
        let label = this.getValueText(option);
        if ( this.multiple){
            if(this.values.indexOf(key) >= 0){
                this.values.splice(this.values.indexOf(key),1);
                this.labels.splice(this.labels.indexOf(label),1);
            } else {
                this.values.push(key);
            }
            this.setSelection([...this.values]);
            this.onChange(this.values);
        } else {
            this.model = {
                [this.idKey] : key,
                "_label" : label
            }
            this.onChange(key);
            this.selectChange.emit(key);
            this.destroy();
        }
        // the handleClickOutside function will close the dropdown
    }

    selectAll(flag){
        if(flag){
            this.values = [];

            this.options.map( o =>{
                let key = o[this.idKey];
                this.values.push(key);
            });
            this.setSelection([...this.values]);
        } else {
            this.model = null;
            this.values = [];
            this.labels = [];
        }
        this.onChange(this.values);
    }

    setSelection(value){
        let oThat = this;
        if (value){  
            if ( this.multiple){
                this.values = [];
                this.labels = [];
                if(this.options.length){
                    this.options.map(currentOption => {
                        if(value.indexOf(currentOption[this.idKey]) >= 0){
                            this.values.push(currentOption[this.idKey]);
                            this.labels.push(this.getValueText(currentOption) );
                        }
                    });
                } else {
                    this.values = value;
                }
                this.model = {
                    [this.idKey] : this.values,
                    "_label" : this.getLabelText()
                }
            } else {
                let currentOption = this.options.find(currentOption => currentOption[this.idKey] == value);
                let label = "";
                if(currentOption){
                    label = this.getValueText(currentOption);
                }
                this.model = {
                    [this.idKey] : value,
                    "_label" : label
                }
            }
        }
        setTimeout( function(){ 
            oThat.onChange(value);
        },100);
    }

    getValueText(option){
        if(typeof(this.labelKey) == "string"){
            return option[this.labelKey] || "";
        } else{
            return this.labelKey.map( o =>{
                return option[o] || o;
            }).join(" ");
        }
    }

    getLabelText(){
        if (this.lableGrouping ){
            if(this.labels.length > 1 ){
                return ( this.labels[0] + (" + (" + (this.labels.length -1) + ")") );
            } else {
                return this.labels.join(",");
            }
        } else {
            return this.labels.join(",");
        }
    }

    isActive(option) {
        if (!this.model) {
            return false;
        }
        if ( this.multiple){
            return this.values.indexOf(option[this.idKey]) >= 0;
        }else{
            return option[this.idKey] == this.model[this.idKey];
        }
    }

    search(value: string) {
        if(value != ""){
            let patter = eval("/"+value+"/gi");
            this.options = this.originalOptions.filter(option => option[this.labelKey].match(patter));
        } else {
            this.options = [...this.originalOptions];
        }
        requestAnimationFrame(() => (this.visibleOptions = this.options.length || 1));
        this.searchChanged.emit(value);
    }

    private handleClickOutside() {
        fromEvent(document, 'click')
            .pipe(
                filter(({ target }) => {
                    if( this.popperRef ){
                        const origin = this.popperRef.reference as HTMLElement;
                        return origin.contains(target as HTMLElement) === false;
                    }
                    return true;
                }),
                takeUntil(this.closed)
            )
            .subscribe(() => {
                this.close();
                this.cdr.detectChanges();
            });
    }

    ngOnDestroy() { }
}