import {Injectable} from '@angular/core';
import {ConnectionService} from 'ng-connection-service';
import {ToasterService} from 'angular2-toaster';
import { ToasterAlertService } from '../../notification/toastAlert.service';

@Injectable({
  providedIn: 'root'
})
export class InternetService {

  constructor(
    private connectionService: ConnectionService,
    private toasterService: ToasterAlertService
  ) {
    this.internetConnection();
  }

  internetConnection() {
    let oThat = this;
    this.connectionService.monitor().subscribe(isConnected => {
      if (!isConnected) {
        if ( localStorage.getItem('nointer') != "0"){
          this.toasterService.pop('error', '', 'No internet connection');
          localStorage.setItem('nointer',"0");
        }
        setTimeout( function() { oThat.internetConnection(); }, 500 );
      } else {
        if ( localStorage.getItem('nointer') != "1"){
          this.toasterService.pop('success', '', 'Internet connection restored');
          localStorage.setItem('nointer',"1");
        }  
      }
    });
  }

}
