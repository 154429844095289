import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Router} from '@angular/router';

import { environment } from '../../../../environments/environment';
import { ToasterAlertService } from '../../notification/toastAlert.service';

@Component({
  selector: 'app-gmap',
  templateUrl: './gmap.component.html',
  styleUrls: ['./gmap.component.scss']
})
export class GMapComponent implements OnInit {
  @Input() public context: any = null; 

  @Input() public sourceTitle: any = ""; 
  @Input() public sourceGeoLocationLat: any = 0; 
  @Input() public sourceGeoLocationLong: any = 0; 

  @Input() public geoLocationLat: any = 0; 
  @Input() public geoLocationLong: any = 0; 
  @Input() public movable: any = false; 
  @Input() public checkDistance: any = false; 
  @Input() public title: any = "Property Location"; 
  
  @Output() locate = new EventEmitter();
  @Output() closeMap = new EventEmitter();

  public gMap = null;
  public mapMarker = null;
  public gMapKey = environment.gMapKey;
  public drivingMode = 'DRIVING';
  public markerDistance = null;
  public drivingDistance = null;
  public distance = null;
  public minimized = false;

  constructor(
    private router: Router,
    private toasterService: ToasterAlertService,
    ) {
  }

  ngOnInit() {
    this.googleMap();
  }

  googleMap() {
    /*markers map*/
    let oThat = this;
    var gmapScript = document.getElementsByClassName('gmapScript');
    if(gmapScript.length == 0){
      var script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key='+this.gMapKey+'&callback=initMap';
      script.async = true;
      script.classList.add("gmapScript");
    }
    
    window["initMap"] = function() {
        let gmap = window["google"];
        var mapOptions = {
          center: new gmap.maps.LatLng(oThat.geoLocationLat, oThat.geoLocationLong),
          zoom:15
        }
        oThat.gMap = new gmap.maps.Map(document.getElementById("markers-map"),mapOptions);
        oThat.setgMapPin(oThat.gMap.getCenter(), oThat.title, oThat.movable);
        gmap.maps.event.addListener(oThat.gMap , 'click', function(event) {
          oThat.setgMapPin(event.latLng);
        });

        if(oThat.checkDistance){
          oThat.setgMapPin(new gmap.maps.LatLng(oThat.sourceGeoLocationLat, oThat.sourceGeoLocationLong), oThat.sourceTitle, false);
        }
    };

    if(gmapScript.length == 0){
      // Append the 'script' element to 'head'
      document.head.appendChild(script);
    } else {
      window["initMap"].call(this);
    }
  }

  // setgMapPin(oLatLng){
  //   let oThat = this;
  //   let gmap = window["google"];
  //   if ( !this.mapMarker ){
  //     this.mapMarker = new gmap.maps.Marker({
  //       position: oLatLng,
  //       map : oThat.gMap,
  //       draggable: oThat.movable,
  //       title: oThat.title
  //     });
  //     gmap.maps.event.addListener(this.mapMarker, "dragend", function(event) { 
  //       var lat = event.latLng.lat(); 
  //       var lng = event.latLng.lng();
  //     });
  //   } else {
  //     this.mapMarker.setPosition(oLatLng);
  //   }     
  // }

  setgMapPin(oLatLng, title = "",dragable = true) {
    let oThat = this;
    let gmap = window["google"];

    let options : any = {
      position: oLatLng,
      map: this.gMap,
      title: title || "Property Location",
      icon : "/assets/images/propertyMarker.png"
    };

    if(!dragable){
      options.icon = "/assets/images/branchMarker.png";
      new gmap.maps.Marker(options);
    }else if (!this.mapMarker) {
      options.draggable = true;
      this.mapMarker = new gmap.maps.Marker(options);
      this.mapMarker.setAnimation(gmap.maps.Animation.BOUNCE);
      if(this.movable){
        gmap.maps.event.addListener(this.mapMarker, "dragend", function (event) {
          if(oThat.checkDistance){
            oThat.reCalculateDistance();
          }
        });
      }
    } else {
      this.mapMarker.setPosition(oLatLng);
    }
    if(this.checkDistance && this.sourceGeoLocationLat && this.sourceGeoLocationLong && this.mapMarker){
      let oLatLng = this.mapMarker.getPosition();
      this.haversine_distance([this.sourceGeoLocationLat, this.sourceGeoLocationLong], [oLatLng.lat(),oLatLng.lng()]);
    }
  }


  getAddressOnMap(address){
    let oThat = this;
    let geocoder = new window["google"].maps.Geocoder();
    geocoder.geocode( { 'address': address}, function(results, status) {
      if (status == window["google"].maps.GeocoderStatus.OK) {
        oThat.gMap.setCenter(results[0].geometry.location);
        oThat.setgMapPin(results[0].geometry.location);
      } else {
        alert('Geocode was not successful for the following reason: ' + status);
      }
    });
  }

  updateLocation(){
    let oLatLng = this.mapMarker.getPosition();
    this.locate.emit({
      geoLocationLat : oLatLng.lat(),
      geoLocationLong : oLatLng.lng(),
      distance : this.distance,
      context : this.context
    });
  }

  reCalculateDistance(){
    if(this.sourceGeoLocationLat && this.sourceGeoLocationLong && this.mapMarker){
      let oLatLng = this.mapMarker.getPosition();
      this.calculateDistance([this.sourceGeoLocationLat, this.sourceGeoLocationLong], [oLatLng.lat(),oLatLng.lng()]);
    }
  }

  haversine_distance(mk1, mk2){
    var R = 3958.8; // Radius of the Earth in miles
    R = 6378.14; // // Radius of the Earth in Km
    var rlat1 = mk1[0] * (Math.PI/180); // Convert degrees to radians
    var rlat2 = mk2[0] * (Math.PI/180); // Convert degrees to radians
    var difflat = rlat2-rlat1; // Radian difference (latitudes)
    var difflon = (mk2[1]-mk1[1]) * (Math.PI/180); // Radian difference (longitudes)

    var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
    this.markerDistance = "Straight Distance between markers: <strong>" + d.toFixed(2) + " Km.</strong>";
    this.calculateDistance(mk1, mk2);
    return d;
  }

  calculateDistance(source,destination) {
    let oThat = this;
    let gmap = window["google"];
    if( !window["directionsService"] )
      window["directionsService"] = new gmap.maps.DirectionsService();
    if( !window["directionsRenderer"] ){
      window["directionsRenderer"] = new gmap.maps.DirectionsRenderer();
      window["directionsRenderer"].setMap(this.gMap); // Existing map object displays directions
    }

    // Create route from existing points used for markers
    const route = {
      origin: new gmap.maps.LatLng(...source),
      destination: new gmap.maps.LatLng(...destination),
      travelMode: this.drivingMode || "DRIVING"
    }

    // gmap.directionsRenderer.set('directions', null);  
    window["directionsService"].route(route, function (response, status) { // anonymous function to capture directions
      oThat.distance = 0;
      if (status !== 'OK') {
        oThat.toasterService.pop('error', '', 'Driving Directions request failed due to ' + status);
        return;
      } else {
        if(window["directionsRenderer"]){
          window["directionsRenderer"].setDirections(response); // Add route to the map
          var directionsData = response.routes[0].legs[0]; // Get data about the mapped route
          if (!directionsData) {
            oThat.toasterService.pop('error', '','Directions request failed');
            return;
          }
          else {
            oThat.distance = directionsData.distance.text;
            oThat.drivingDistance = " Driving distance is <strong>" + directionsData.distance.text + " (" + directionsData.duration.text + ")</strong>.";
          }
        }
      }
    });
  }

  closeMapPopup(){
    this.closeMap.emit({});
  }
}
