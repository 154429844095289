import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'masked'
})
export class MaskedPipe implements PipeTransform {
  transform(text,size = 0, till : '',char = 'X') : any{
    text = text.toString();
    let len = text.length;
    if(till){
      let arr = text.split(till);
      size = arr.pop().length + 1;
    }
    return text.slice(len-size).padStart(len, char);
  }

}
