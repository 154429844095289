import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToasterService } from 'angular2-toaster';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToasterAlertService } from '../../notification/toastAlert.service';

@Component({
  selector: 'app-advanceInput',
  templateUrl: './advanceInput.component.html',
  styleUrls: ['./advanceInput.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdvanceInputComponent),
      multi: true
    }
  ]
})
export class AdvanceInputComponent implements OnInit, ControlValueAccessor {

  @Input() placeholder: any;
  @Input() defaultStyleClass: any = 'mb-2';
  
  public _inputvalue: any = "";
  public inputText: any = "";
  public inputList: any = [];
  public disabled = false;
  public disabledText = "";

  value: any = "";
  
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private toasterService: ToasterAlertService
  ) {
  }

  ngOnInit() {
    
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  openAdvanceInputPopup(content) {
    this.inputText = "";
    this.inputList = [];
    if (this.value) {
      try {
        this.inputList = JSON.parse(this.value);
      } catch (e) {
        this.inputList = [];
      }
    } else {
      this.inputText = this._inputvalue || ""; 
    }
    this.modalService.open(content, { backdrop: 'static', centered: true, size: "lg" });
  }

  updateInput(close) {
    let oThat = this;
    if (this.inputList.length) {
      this.value = JSON.stringify(this.inputList);
      this.disabledText = this.processList(this.inputList);
      this._inputvalue = this.value; 
      this.disabled = true;
    } else {
      this.value = "";
      this.disabled = false;
    }
    this.onChange(this.value);
    this._close(close);
  }

  processList(list) {
    let result : any = [];
    result = list.reduce( (a, o) => {
      if(o.type == "isNull") a.push("(IS NULL)");
      else if(o.type == "notNull") a.push("(IS NOT NULL)");
      else if(o.type == "bt") a.push(`(Between ${o.low} AND ${o.high})`);
      else if(o.type == "notbt") a.push(`(NOT BETWEEN ${o.low} AND ${o.high})`);
      else if(o.type == "gt") a.push(`( > ${o.low} )`);
      else if(o.type == "gte") a.push(`( >= ${o.low} )`);
      else if(o.type == "lt") a.push(`( < ${o.low} )`);
      else if(o.type == "lte") a.push(`( <= ${o.low} )`);
      else if(o.type == "ne") a.push(`( NOT EQUAL TO ${o.low} )`);
      else if(o.type == "like") a.push(`( LIKE ${o.low} )`);
      else if(o.type == "notLike") a.push(`( NOT LIKE ${o.low} )`);
      else if(o.type == "in") a.push(`( IN ${o.low} )`);
      else if(o.type == "notin") a.push(`( NOT IN ${o.low} )`);
      else if(o.low) a.push(o.low);
      return a;
    },[]);

    return result.join(", ");
  }

  parseInput() {
    if (this.inputText) {
      if(this.inputList.length){
        let last = this.inputList[this.inputList.length-1];
        if(last.low == "" && ( last.type == "in" || last.type == "notin" ) ){
          last.low = this.inputText.replace(/\n/ig, ",").split(",").filter( o => o != "").join(",");
          this.inputText = "";
          return;
        }
      } 
      let items = this.inputText.replace(/\n/ig, ",").split(",");
      items.map(o => {
        if(o != ""){
          this.inputList.push({ low: o, high: "", type: "eq", join: "or" });
        }
      });
    }
    this.inputText = "";
  }

  clearInput() {
    this.inputList = [];
    this.disabled = false;
    this.value = "";
    this.disabledText = ""; 
    this._inputvalue = ""; 
  }

  addInputItem() {
    this.inputList.push({ low: "", high: "", type: "eq", join: "or" });
  }

  removeInputItem(index) {
    this.inputList.splice(index, 4);
  }

  _close(close) {
    if (this.inputList.length) {
      this.value = JSON.stringify(this.inputList);
      this.onChange(this.value);
    } else {
      this.disabled = false;
      this.onChange(this.value || "");
    }
    close();
  }

  onBlur(value: any): void {
  }

  updateCallback($event) {
    this.onChange(this._inputvalue || "");
  }

  writeValue(value: any): void {
    this.value = value == undefined ? "" : value;
    let oThat = this;
    try{
      if(value){
        oThat.disabled = true;
        oThat.inputList = JSON.parse(value);
        oThat.disabledText = oThat.processList(oThat.inputList);
        oThat._inputvalue = value;
      }
    }catch(e){
      oThat.disabled = false;
      oThat.inputList = [];
      oThat._inputvalue = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validateOptions(item){
    if(item.type != "bt" && item.type != "notbt" ){
      item.high = "";
    }
    if(item.type == "is" || item.type == "not" ){
      item.low = "";
      item.high = "";
    }
  }
}
