import { Pipe, PipeTransform } from '@angular/core';

import {PermissionService} from '../../service/permission/permission.service';

@Pipe({
    name: 'canEdit'
})
export class CanEditPipe implements PipeTransform {
    constructor(private permissionService: PermissionService) {}

    transform(permissionCode: any[]): boolean {
        if(permissionCode && permissionCode.length == 0){
            return false;
        }
        let accessList = this.permissionService.getAccess();
        if(!Object.keys(accessList).length && localStorage.getItem("USER_ACCESS")){
            accessList = JSON.parse(localStorage.getItem("USER_ACCESS"));
        }
        let returnVal = true;
        if(accessList && permissionCode){
            for (let i = 0; i < permissionCode.length && returnVal == true; i++) {
                let accessItem = accessList[permissionCode[i]];
                if(accessItem ){
                    if( accessItem.allowAccess && accessItem.allowEdit){
                        returnVal = returnVal && true;
                    } else {
                        returnVal = false;
                    }
                    accessList = accessItem;
                } else {
                    returnVal = false;
                }
            }
        } else {
            returnVal = false;
        }
        
        // return true;
        return returnVal;
    }
}